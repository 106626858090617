<header class="header">
    <div class="header-container">
      <!-- Left Section -->
      <div class="header-left">
        <img src="assets/CGL/images/whitelogo.png" alt="Genpact Logo" class="logo" />
        <span class="title">
          Cora<span class="highlight">Code</span>
          <span class="highlight1">GenY</span>
        </span>
      </div>
  
      <!-- Right Section -->
      <div class="header-right">
        <!-- Links Section -->
        <nav class="nav-links">
            <a class="nav-link" href="https://forms.office.com/Pages/ResponsePage.aspx?id=IIrvvayqgE-zoNmjL5n9M90e9cRW2ZJNt3MEN5UWXmJUNDBYN1lPTzI4R1VYNUQ4RDVVMk9UMFBMNS4u" target="_blank">  
                Feedback  
              </a>
              <a  class="nav-link" href="https://genpactonline.sharepoint.com/:f:/s/IT/En9OkLY6kuFOowWGJj-pGnoBh_9YDVA55_NWG_recIAuag" target="_blank">  
                Templates  
              </a>  
              <a class="nav-link" href="https://genpactonline.sharepoint.com/sites/IT/Documents/Code%20GenY%20Templates/CodeGenY%20user%20manual.pdf" target="_blank">  
                SOP  
              </a> 
        </nav>
  
        <!-- Profile Section -->
        <div class="profile-section">
          <div class="profile-dropdown" [matMenuTriggerFor]="menu">
            <span class="profile-initials">{{ username }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
          </div>
          <mat-menu #menu="matMenu" class="background-color">
            <!-- <li class="list-item item-first" (click)="openSetting()"><i class="fa-solid fa-gear"></i> Settings</li> -->
            <li (click)="homerouter()"  class="list-item item-first">
              <svg
                    xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-house-door-fill" viewBox="0 0 16 16" style="margin-right: 10px;">
                    <path
                      d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5" />
                  </svg> Home</li>
            <!-- <li class="list-item item-first"><a class="nav-link" routerLink="/codeGenYLite" routerLinkActive="active" >codeGenYLite</a></li> -->
            <li class="list-item item-first" (click)="openDialog()"><img class="img-icon" src="../../../../../assets/privacyicon.png">Privacy Policy</li>
            <!-- <li class="list-item" (click)="exitApp1()"><i class="fa-solid fa-right-from-bracket"></i>Exit application</li> -->
        </mat-menu>
          <button class="logout-button"  (click)="exitApp()">
            Logout <i class="fas fa-sign-out-alt"></i>
          </button>
        </div>
      </div>
    </div>
  </header>
  