<div class="code-box">
  <div class="custom-container">
    <p class="code-enhance-header">SDD module generates solution design documents and provide step-by-step automation strategies based on BRD. Accelerate project timelines and reduce development costs with a clear roadmap and optimized automation strategies.</p>
    <p class="code-enhance-desc"></p>
      <div class="form-box">
            <form>
                <div class="row gx-2 gy-2">
                    <div class="csol">
                        <p class="upload-label" class="astreik">Upload BRD file:</p>
                        <div class="file-upload form-control">
                            <label for="fileInput">
                                <span class="upload-icon">
                                    <img src="../../../../../assets/images/uploadBlack.png" alt="">
                                </span>
                                <span class="upload-text">Click to upload file</span>
                            </label>
                            <input type="file" id="fileInput" (change)="onFileSelected($event)" accept=".docx" />
                        </div>
                        <small class="text-danger" *ngIf="invalidSize">File size too large! Max size: 1MB.</small>
                            <small class="text-danger" *ngIf="invalidType">Invalid file type! Only .doc and .docx allowed.</small>

                        <div class="uploaded-file">
                            <div *ngIf="selectedFile">
                                <p class="uploaded-header">Uploaded file</p>
                                <div class="uploaded-file-section">
                                    <div class="upload-file-name">
                                        <img src="../../../../../assets/images/codeDocument.png" alt="">
                                    <span class="file-font">{{ selectedFile.name }}</span>
                                    </div>
                                <span class="action-buttons">
                                    <img src="../../../../../assets/images/refresh.png" alt="" (click)="refreshSelectedFile()">
                                    <img src="../../../../../assets/images/delete.png" alt="" (click)="removeSelectedFile()">

                                  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="btn-submit">
                    <app-button [buttonClass]="'BRD-btn'" [buttonText]="'Generate SDD'"
                        (buttonClick)="onButtonSubmit()"></app-button>
                        <!-- <a *ngIf="showGoToGenerateCode" class="goToLink" (click)="goToGenerateCode()">Go To Generate Code</a> -->
                        <button *ngIf="showGoToGenerateCode" class="btn-navigate" (click)="goToGenerateCode()">Next: Go to  Generate Code</button>
                </div>
            </form>
        </div>
    </div>
</div>
