<!-- <app-sidenav [item]="currentItem"></app-sidenav> -->
<div class="container-fluid responsive p-2 p-md-5 text-center">
  <div class="row">
    <div class="col-12 fw-bold">

      <h3 class="mt-4">
        You don't have access to Konverse. If you believe you should have access, Please <a
          href="https://genpactindprod.service-now.com/esc?id=sc_cat_item&table=sc_cat_item&sys_id=d72380791bcd3010cbcea8afe54bcb8b&recordUrl=com.glideapp.servicecatalog_cat_item_view.do%3Fv%3D1&sysparm_id=d72380791bcd3010cbcea8afe54bcb8b"
          target="_blank">click here</a> to request access.
        <br>
        <br>
        Thank you for your understanding.
      </h3>
    </div>

  </div>

</div>