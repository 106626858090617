import { Component } from '@angular/core';  
import { Router } from '@angular/router';  
import {SopService} from 'src/app/shared/service/sop.service';

import {EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';


import { ModalDirective } from 'ngx-bootstrap/modal';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AccesscheckService } from 'src/app/shared/service/accesscheck.service';
import { LoaderService } from '../shared/service/loader.service';
import { ToastMessageService } from '../shared/service/toast-message.service';

@Component({
    selector: 'app-cghome',
    templateUrl: './cghome.component.html',
    styleUrls: ['./cghome.component.scss'],
    standalone: false
})  
export class CghomeComponent {  
  username: any;
  fname: any;

  constructor(private router: Router,
               public dialog: MatDialog,
                  private route: ActivatedRoute,
                  private toastmessage : ToastMessageService,
                 
                   private useraccesscheckService: AccesscheckService,
                   private sopService: SopService,
                   private loaderservice:LoaderService,

  ) {}  
  
  features = [  
    {  
      image: 'assets/CGL/images/Group2.svg',  
      title: 'Build your project',  
      description:  
        'An all-encompassing process that involves taking a project from initial concept to completion. This includes defining business requirements, analyzing needs, designing the software architecture, developing code, creating user interfaces, and generating test cases. The goal is to methodically plan and construct a software project that meets all specified objectives and user expectations.',  
      link: '/cgy/brd'  
    },  
    {  
      image: 'assets/CGL/images/stars.png',  
      title: 'AI Assist',  
      description:  
        'Improve developer efficiency with instant, AI-powered chat support, minimizing downtime and increasing productivity. Cut operational costs by providing quick resolutions to coding queries.',  
      link: '/cgy/ai-assist'  
    },  
    {  
      image: 'assets/CGL/images/Code enhancent.svg',  
      title: ' Code Assist',  
      description:  
        'Designed to facilitate and improve the coding process. This involves enhancing existing code for better performance and readability, documenting code functionalities, adjusting code to meet changing requirements, analyzing code to identify and fix issues, and converting code into different formats or languages when necessary. These efforts aim to streamline development and ensure high-quality software output.',  
      link: '/cgy/codeassist'  
    },  
    // Add more features as needed  
    {  
      image: 'assets/images/icon4.png',  
      title: 'Real-time collaboration',  
      description:  
        'Collaborate with your team in real-time to build amazing products.',  
      link: '/feature/real-time-collaboration'  
    },  
    // {  
    //   image: 'assets/images/icon5.png',  
    //   title: 'Cloud Integration',  
    //   description:  
    //     'Seamlessly integrate with cloud services for better scalability.',  
    //   link: '/feature/cloud-integration'  
    // },  
    // {  
    //   image: 'assets/images/icon6.png',  
    //   title: 'Performance Analytics',  
    //   description:  
    //     'Gain insights into your application performance with advanced analytics.',  
    //   link: '/feature/performance-analytics'  
    // }  
  ];  
//////////////////
cards = [  
  {  
    icon: 'assets/CGL/NewLandingimages/brd.png',  
    title: 'BRD',  
    description: `Quickly generates Business Requirement Documents (BRDs), 
    simplifying project initiation. It saves time and minimizes miscommunication, 
    boosting productivity from the project kick-off. The generated output includes some empty fields that need to be filled by the user.`,
        buttonText: 'Start generating',  
    highlight: false  ,
    route: '/cgy/brd'
  },  
  {  
    icon: 'assets/CGL/NewLandingimages/ra.png',  
    title: 'RA',  
    description: `Efficiently generates Requirement Analysis (RA) documents to streamline project initiation. Saves time, reduces miscommunication, and enhances productivity from the project's start, ensuring clarity and alignment among stakeholders.`,  
    buttonText: 'Start generating',  
    highlight: false  ,
    route: '/cgy/ra'
  },  
  {  
    icon: 'assets/CGL/NewLandingimages/cr.png',  
    title: 'CR Creation',  
    description: 'Automates creation of Change Requests (CR) in ServiceNow for the SNCOE tower and triggers emails to the dedicated team. Simplifies initiation by handling mandatory fields in the SNCOE RA CR reference table, streamlining the process effectively.',  
    buttonText: 'Start generating',  
    highlight: true  ,
    route: '/cgy/cr'
  },  
  {  
    icon: 'assets/CGL/NewLandingimages/userstory.png',  
    title: 'User Story',  
    description: 'Generates User Stories efficiently, streamlining project initiation and saving time. Reduces miscommunication, enhances productivity from the project kick-off, and ensures clear requirements for development teams.',  
    buttonText: 'Start generating',  
    highlight: false  ,
    route: '/cgy/userstory'
  },  
  {  
    icon: 'assets/CGL/NewLandingimages/sdd.png',  
    title: 'SDD',  
    description: 'Produces Solution Design Documents with step-by-step implementation strategies based on RA. Accelerates timelines, reduces costs with a clear roadmap and optimized strategies, supporting successful project delivery.',  
    buttonText: 'Start generating',  
    highlight: false  ,
    route: '/cgy/sdd'
  },  
  {  
    icon: 'assets/CGL/NewLandingimages/codegen.png',  
    title: 'Code Generation',  
    description: 'Creates a workspace with all required code files based on the SDD. Accelerates project timelines and reduces development costs with a clear roadmap and optimized implementation strategies for execution.',  
    buttonText: 'Start generating',  
    highlight: false  ,
    route: '/cgy/codegen'
  },  
  {  
    icon: 'assets/CGL/NewLandingimages/tc.png',  
    title: 'Test Case Generation',  
    description: 'Generates test cases based on SDD/RA to enhance testing efficiency. Simplifies test creation, saving time and resources while ensuring comprehensive coverage and quality assurance throughout the project lifecycle.',  
    buttonText: 'Start generating',  
    highlight: false  ,
    route: '/cgy/tcggen'
  },  
  {  
    icon: 'assets/CGL/NewLandingimages/aiassist.png',  
    title: 'AI Assist',  
    description: 'Boosts productivity by automating bug resolution and code refinement. Saves development hours, reduces costs associated with manual debugging and code improvement, enhancing code quality and efficiency.',  
    buttonText: 'Start generating',  
    highlight: false  ,
    route: '/cgy/ai-assist'
  },  
  {  
    icon: 'assets/CGL/NewLandingimages/codeassist.png',  
    title: 'Code Assist',  
    description: 'Upload code files or paste code to receive detailed explanations for every line or block. Facilitates a deeper understanding of code for developers, improving learning and knowledge sharing.User can document,enhance code,etc.',  
    buttonText: 'Start generating',  
    highlight: false  ,
    route: '/cgy/codeassist'
  }  
];  

// Dynamic right content with varying structures
rightContent = [
  {
    type: 'highlight',
    title: 'Did you know?',
    description: [  
       
      'Did you know that automating project documentation and development processes can significantly boost productivity and reduce costs?',  
      'By streamlining tasks like BRD and RA generation, code creation, and test case development, you save valuable time and minimize miscommunication from the project\'s kick-off.',  
      // 'Utilizing AI-assisted tools for code enhancement, analysis, and documentation accelerates development timelines and improves code quality.',  
      // 'Embracing these solutions facilitates quicker onboarding, better collaboration, and overall efficiency across your projects.'  
    ] 
  },
  {
    type: 'media',
    title: 'Media Section',
    videoUrl: '', // Video URL (if available)
    imageUrl: 'assets/CGL/NewLandingimages/Video.png' // Image fallback if video is unavailable
  },
  {
    type: 'list',
    title: 'Latest Updates',
    list: ['History addition in AI Assist', 'Project list addition', 'Multiple file upload in Code Assist'],
    title1: 'Upcoming',
    list1: ['File upload in AI Assist', 'Test case enhancement'],
  }
];

  //////////////

  carouselData = [
    {
      image: 'assets/CGL/NewLandingimages/Banner1.png',
      title: 'Build your project',
      description: 'Streamline the entire development life cycle, from requirement documentation to code generation. Enhance productivity by consolidating processes and reduce costs through a seamless, integrated solution.',
    },
    {
      image: 'assets/CGL/NewLandingimages/Banner2.png',
      title: 'CR(SNCOE)',
      description: 'This module is dedicated to the SNCOE tower. It automates the creation of Change Requests (CR) in ServiceNow and triggers emails to the dedicated team for project initiation.',
    },
    {
      image: 'assets/CGL/NewLandingimages/Banner3.png',
      title: 'AI Assist',
      description: 'Improve developer efficiency with instant, AI-powered chat support, minimizing downtime and increasing productivity. Cut operational costs by providing quick resolutions to coding queries.',
    },
  ];
  ngOnInit(): void {
    this.getUserName();
    this.domaincheck();
  }
  
  domaincheck(){
  this.loaderservice.loading.next(true)
    this.sopService.getdomain().subscribe((res: any) => {
      if (res) {

        if(res?.domain === 'IT'){
          this.loaderservice.loading.next(false);
          this.toastmessage.showSuccess("Welcome to CoraCodeGenY")
        }

        else{
          this.router.navigate(['codeGenYLite']);
          this.loaderservice.loading.next(false);

        }
        
      }
     
    },
    (error: any) => {
      console.log(error);
      this.loaderservice.loading.next(false);

    }
    );
  }


  navigateTo(link: string) {  
    this.router.navigateByUrl(link);  
  }  
  toggleChat(): void {
    // this.isChatVisible = !this.isChatVisible;
    this.router.navigate(['/cgy/ai-assist'])
  }
  exitApp() {//closeApplication
    localStorage.removeItem('okta-username');
    localStorage.removeItem('token');
    localStorage.removeItem('okta-token-storage');
    

    this.router.navigate(['logout']);
  }

  getUserName(): void {
    const fullName = localStorage.getItem('okta-username') as string ;
    this.fname = fullName;
    let initials = '';

    // Check if there's a comma in the username
    if (fullName.includes(',')) {
      // Split the username by comma
      const nameParts = fullName.split(',');
      const firstNamePart = nameParts[0].trim(); // Part before the comma
      const secondNamePart = nameParts[1].trim(); // Part after the comma

      // Extract the first letter from both parts
      initials = firstNamePart[0].toUpperCase() + secondNamePart[0].toUpperCase();
    } else {
      // If no comma, just take the first two initials
      const nameParts = fullName.split(' ');
      if (nameParts.length > 1) {
        initials = nameParts[0][0].toUpperCase() + nameParts[1][0].toUpperCase();
      } else {
        initials = nameParts[0][0].toUpperCase();
      }
    }

    this.username = initials; // Set the initials as the username
  }
}  