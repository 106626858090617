<div id="dynamicCarousel" class="carousel slide" [attr.data-bs-ride]="'carousel'" [attr.data-bs-interval]="'3000'">  
  <!-- Indicators -->  
  <div class="carousel-indicators mt-5">  
    <button  
      *ngFor="let item of carouselItems; let i = index"  
      type="button"  
      data-bs-target="#dynamicCarousel"  
      [attr.data-bs-slide-to]="i"  
      [class.active]="i === 0"  
      [attr.aria-current]="i === 0 ? 'true' : null"  
      [attr.aria-label]="'Slide ' + (i + 1)"  
    ></button>  
  </div>  
  
  <!-- Carousel Items -->  
  <div class="carousel-inner">  
    <div *ngFor="let item of carouselItems; let i = index" class="carousel-item" [class.active]="i === 0">  
      <div class="position-relative">  
        <img [src]="item.image" class="d-block w-100" [alt]="'Slide ' + (i + 1)" />  
        <div class="carousel-caption">  
          <h2>{{ item.title }}</h2>  
          <p style="font-size: 20px;">{{ item.description }}</p>  
        </div>  
      </div>  
    </div>  
  </div>  

    <!-- Controls -->  
    <!-- Optionally, you can enable the controls -->  
    <!--  
    <button  
      class="carousel-control-prev"  
      type="button"  
      data-bs-target="#dynamicCarousel"  
      data-bs-slide="prev"  
    >  
      <span class="carousel-control-prev-icon"></span>  
      <span class="visually-hidden">Previous</span>  
    </button>  
    <button  
      class="carousel-control-next"  
      type="button"  
      data-bs-target="#dynamicCarousel"  
      data-bs-slide="next"  
    >  
      <span class="carousel-control-next-icon"></span>  
      <span class="visually-hidden">Next</span>  
    </button>  
    -->  
</div>  