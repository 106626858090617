<div class="footer">
  <div class="container">
          <div class="row mt-4">
              <div class="col-md-7">
                  <p>©2024 All rights reserved - Genpact.</p>
              </div>
              <div class="col-md-5">
                  <div class="left-social ml-4">
                      <p>Follow us on social media:</p>
                  <ul class="social">
                      <li><a href="https://www.facebook.com/ProudToBeGenpact/" target="_blank"><i class="fab fa-facebook-square"></i></a></li>
                      <li style="margin-top: -4px;"><a href="https://twitter.com/genpact" target="_blank" class="img-link"><img src="../../../../assets/images/newTwitterIcon.png" alt=""></a></li>
                      <li><a href="https://www.linkedin.com/authwall?trk=qf&original_referer=https://www.genpact.com/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F210064%3Ftrk%3Dtyah" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                      <li><a href="https://www.youtube.com/genpactltd" target="_blank"><i class="fab fa-youtube"></i></a></li>
                  </ul>
                  </div>
              </div>
          </div>
  </div>
</div>