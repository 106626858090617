import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { SharedService } from '../../../Services/shared.service';
import { DevTalkService } from '../../../Services/dev-talk.service';
import {
  ApiEndpoints,
  MessageTypeSnack,
  StatusCode,
} from '../../../data/global-vars';
import { ActivityAudit, DevTalkReq } from '../../../data/app-model';
import {
  FormControl,
  Validators,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { detect, languages, LANG } from 'program-language-detector';
import { SettingsComponent } from '../../includes/popup/settings/settings.component';
import { MatDialog } from '@angular/material/dialog';
import { DisclaimerComponent } from '../../includes/popup/disclaimer/disclaimer.component';
import { SopService } from 'src/app/shared/service/sop.service';

@Component({
    selector: 'app-dev-talk',
    templateUrl: './dev-talk.component.html',
    styleUrls: ['./dev-talk.component.scss'],
    standalone: false
})
export class DevTalkComponent implements OnInit {
  showCustomLoader: boolean = false;
  // dynamicForm: FormGroup;
  originalHeight = '35px';
  isPageVisited: boolean = true;
  code: string = '';
  counter: number = 1;
  tooltipText: string = 'Click to Copy';
  lastMessageFromUser: boolean = true;
  activityAudit: ActivityAudit = {
    ohr_id: '',
    time_stamp: '',
    module: '',
  };
  devTalkReq: DevTalkReq = {
    user_prompt: '',
    reset_context:true
  }
  isDevTalk : boolean = false;

  constructor(
    private sharedService: SharedService,
    private devService: DevTalkService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private sopService: SopService
  ) {
    // this.dynamicForm = this.formBuilder.group({
    //   textareaContent: ['']
    // });
  }

  messageFormControl = new FormControl('', [
    Validators.minLength(2),
    Validators.maxLength(40000),
  ]);

  ngOnInit(): void {
    //throw new Error('Method not implemented.');
    // this.chatMessages.push({"type": "user", "message": "Hello and welcome! I am your personal Coding AI Assistant, DevTalk, your Infinite Subject Matter Expert, ready to assist you across all subject areas and  design principles for coding. You can refer to me as 'DevTalk' throughout our interaction. My primary mission is to craft top-notch software solutions.As an expert in programming, security, documentation, and a follower of industry best practices, including SOLID and DRY principles, I will diligently work on your software project.I'll be sure to ask questions to gain a complete understanding of your specifications before I engineer the software. No need for you to provide code – I'm here to assist you in every step."})
  }
  @ViewChild('textarea') textareaRef!: ElementRef;
  @ViewChild('ChatWindow') ChatWindow!: ElementRef;
  userMessage: string = '';
  gptResponse: string = '';
  blocks: { type: string; content: string; lng: string }[] = [];
  codeMirrorOptions: any = {
    indentWithTabs: true,
    smartIndent: true,
    readOnly : true,
    //lineNumbers: true,
    //lineWrapping: true,
    //autofocus: true,
    //viewportMargin: Infinity,
    //extraKeys: { "Ctrl-Space": "autocomplete" },
    //gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true,
    // theme: 'material'
  };
  //chatMessages: any [] = [
  //  {"type": "bot", "message": "Hello! How can I assist you today?" },
  //  {"type": "user", "message": "Hi, I have a question." },
  //  {"type": "bot", "message": "Please go ahead and ask your question"}
  //  ];
  chatMessages: any[] = [];
  promptMessages: any[] = [];

  sendMessage(event: any = null) {
    this.isDevTalk = true;
    this.storeLogs();
    if (event) {
      event.preventDefault(); // Prevent the default behavior of the "Enter" key
    }
    //this.logActivity();
    if (
      this.userMessage &&
      this.userMessage.trim() != '' &&
      this.messageFormControl.valid
    ) {
      this.showCustomLoader = true; // Show the custom loader
      this.chatMessages.push({ type: 'user', message: this.userMessage });
      this.promptMessages.push({ role: 'user', content: this.userMessage });
      this.devTalkReq.user_prompt = JSON.stringify(this.promptMessages);
      this.devService.postUserPrompt(this.devTalkReq).subscribe({
        next: (res) => {
          this.devTalkReq.reset_context = false;
          if (res.status && res.status_code == StatusCode.Success) {
            //this.chatMessages.push({
            //  type: 'bot',
            //  message: res.result.gpt_response,
            //});
            console.log('dev talk resp->>', res);
            this.gptResponse = res.result.gpt_response;
            this.promptMessages.push({ role: 'assistant', content: res.result.gpt_response });
            this.splitCodeAndText();
            this.userMessage = '';
            // Hide the custom loader after receiving the response
            this.showCustomLoader = false;
            // scroll to bottom function commented here--------------
            setTimeout(() => {
              this.scrollToBottom(this.ChatWindow);
            });
            if (this.textareaRef) {
              this.textareaRef.nativeElement.style.height = 'auto';
            }
            // if (this.lastMessageFromUser) {
            //   // Scroll to the top of the last bot message every time
            //   setTimeout(() => {
            //     this.scrollToTopOfLastBotMessage(this.ChatWindow);
            //   });
            // }
            // this.lastMessageFromUser = false;
          } else {
            if (res.status_code == 500) {
              this.sharedService.openSnackBar(
                res.message,
                MessageTypeSnack.Error
              );
              this.showCustomLoader = false;
            } else if (res.status_code == 400) {
              this.sharedService.openSnackBar(
                res.message,
                MessageTypeSnack.Error
              );
              this.showCustomLoader = false;

            } else {
              this.sharedService.openSnackBar(
                res.message,
                MessageTypeSnack.Error
              );
              this.showCustomLoader = false;
            }
          }
        },
        error: (err) => {
          this.sharedService.openSnackBar(err, MessageTypeSnack.Error);
          // Hide the custom loader after receiving the response
          this.showCustomLoader = false;
        },
      });
      this.userMessage = '';
      this.textareaRef.nativeElement.style.height = 'auto';
      this.logActivity();
    } else {
      this.sharedService.openSnackBar(
        'Please enter something',
        MessageTypeSnack.Error
      );
    }
    // const textarea = document.getElementById("resizabletextbox") as HTMLTextAreaElement;
    // textarea.style.height = this.originalHeight;

    //   // Get the user's input message
    //const userMessage = this.userMessage.trim();

    //// Clear the input field
    //this.userMessage = '';

    //// Ensure the user's message is not empty
    //if (!userMessage) {
    //  return;
    //}

    //// Add the user's message to the chatMessages array
    //this.chatMessages.push({ type: 'user', message: userMessage });

    //// Simulate a response from the bot (you can replace this with actual logic)
    //const botResponse = 'I received your message: ' + userMessage;
    //this.chatMessages.push({ type: 'bot', message: botResponse });

    // Ensure the chat window scrolls to the bottom to show the latest messages

    // Reset the textarea height to its default after sending a message
    //  this.resetTextareaHeight();
  }

  storeLogs()
  {
    const payload = {
      'event_type' : 'codeGenYLite',
      'event_data' : {
        'feature':'dev_talk',
        "userPrompt":this.userMessage,
      } 
    }
    this.sopService.logsStorage(payload).subscribe((res)=>{
      // console.log(res);
    })
  }

  logActivity() {
    this.activityAudit.module = ApiEndpoints.GetGptResponseCustom;
    this.activityAudit.time_stamp = String(new Date());
    let userName = localStorage.getItem('okta-username');
    this.activityAudit.ohr_id = userName ? String(userName) : '';
    //this.sharedService.postActivityLog(this.activityAudit).subscribe({
    this.sharedService.postActivityLog(this.activityAudit).subscribe({
      next: (res) => {
        if (res.status && res.status_code == StatusCode.Success) {
        } else {
          if (res.status_code == 500) {
          } else if (res.status_code == 400) {
          } else {
          }
        }
      },
      error: (err) => {},
    });
  }

  //  scroll to bottom function added here.............

  private scrollToBottom(chatWindow: ElementRef): void {
    if (chatWindow && chatWindow.nativeElement) {
      const chatWindowElement = chatWindow.nativeElement;
      chatWindowElement.scrollTop = chatWindowElement.scrollHeight;
    }
  }
  adjustTextareaHeight(event: any): void {
    const textarea = event.target;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }
  // scroll to bot div function added here................
  // private scrollToTopOfLastBotMessage(chatWindow: ElementRef): void {
  //   if (chatWindow && chatWindow.nativeElement) {
  //     const chatWindowElement = chatWindow.nativeElement;

  //     // Find the last bot message element
  //     const botMessages = chatWindowElement.querySelectorAll('.bot-message');
  //     const lastBotMessage = botMessages[botMessages.length - 1];

  //     // Scroll to the top of the last bot message
  //     if (lastBotMessage) {
  //       chatWindowElement.scrollTop = lastBotMessage.offsetTop;
  //     }
  //   }
  // }

  // adjustTextareaHeight(event: Event): void {
  //   const textarea = event.target as HTMLTextAreaElement;
  //   textarea.style.height = '40px'; // Reset height to a fixed value

  //   // Calculate the new height based on the scrollHeight
  //   const newHeight = Math.min(textarea.scrollHeight, parseInt(getComputedStyle(textarea).maxHeight));

  //   // Set the height to the new height only if it's greater than the fixed height
  //   textarea.style.height = newHeight > 40 ? newHeight + 'px' : '40px';

  //   // Update overflow-y based on whether the content exceeds one line
  //   textarea.style.overflowY = newHeight > 40 ? 'auto' : 'hidden';
  // }

  // private resetTextareaHeight(): void {
  //   const textarea = document.querySelector('.custom-control') as HTMLTextAreaElement;
  //   if (textarea) {
  //     textarea.style.height = '40px';
  //   }
  // }

  splitCodeAndText(): void {
    this.blocks = [];

    // Split the response based on the triple-single-quote delimiter
    const parts = this.gptResponse.split('```');

    // Start from index 1 because the first part is empty
    for (let i = 0; i < parts.length; i += 2) {
      let language = '';
      const textBlock = parts[i] ? parts[i].trim() : '';
      const processedContent = textBlock.replace(
        /\*\*(.*?)\*\*/g,
        '<strong>$1</strong>'
      );
      let codeBlock = parts[i + 1] ? parts[i + 1].trim() : '';
      if (codeBlock) {
        const indexOfNewLine: number = codeBlock.indexOf('\n');
        if (indexOfNewLine != 1) {
          language = codeBlock.substring(0, indexOfNewLine);
          codeBlock = codeBlock.substring(indexOfNewLine + 1);
          // this.languagedetector(codeBlock);
        }
      }

      this.blocks.push({ type: 'text', content: processedContent, lng: '' });
      this.blocks.push({ type: 'code', content: codeBlock, lng: language });
    }
    if (parts && parts.length > 0)
      this.chatMessages.push({
        type: 'bot',
        message: this.blocks,
      });
  }

  // private resetTextareaHeight(): void {
  //   const textarea = document.querySelector('.custom-control') as HTMLTextAreaElement;
  //   if (textarea) {
  //     textarea.style.height = '40px';
  //   }
  // }

  copyText(code: string) {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        // Change tooltip text after successfully copying
        this.tooltipText = 'Copied!';
        this.code = code;
        // Set a timeout to change the tooltip back to "Click to Copy" after 5 seconds (5000 milliseconds)
        setTimeout(() => {
          this.tooltipText = 'Click to Copy';
        }, 5000);
      })
      .catch(() => {
        this.sharedService.openSnackBar(
          'Unable to copy text',
          MessageTypeSnack.Error
        );
      });
  }

  // downloadFile() {
  //   // Check if the code has been copied
  //   if (!this.code) {
  //     // Handle the case where no code has been copied
  //     return;
  //   }

  //   const blob = new Blob([this.code], { type: 'application/msword' });
  //   const url = URL.createObjectURL(blob);
  //   const anchor = document.createElement('a');
  //   anchor.href = url;
  //   anchor.download = 'copied_text.doc';
  //   anchor.click();
  //   URL.revokeObjectURL(url);
  //   anchor.remove();

  //   // Clear the code value after downloading
  //   this.code = '';
  // }

  downloadFile(code: string) {
    const detectedExtensions = this.languagedetector(code);
    const extension = detectedExtensions[0];
    const tempstore = extension.slice(1);
    // const blob = new Blob([code], { type: 'application/msword' });
    const blob = new Blob([code], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = `code_${tempstore}_${extension}`;
    anchor.click();
    URL.revokeObjectURL(url);
    anchor.remove();
  }

  languagedetector(codestring: string) {
    const detectionResult = detect(codestring);
    console.log('Detected language:', detectionResult);
    let tempstore = detectionResult.toLowerCase();
    console.log('lowercase', tempstore);

    const languageExtensions: { [key: string]: string[] } = {
      javascript: ['.js'],
      python: ['.py'],
      C: ['.c'],
      'c++': ['.cpp'],
      java: ['.java'],
      html: ['.html'],
      css: ['.css'],
      ruby: ['.rb'],
      go: ['.go'],
      php: ['.php'],
      // Add more programming languages and their file extensions as needed
    };

    const detectedExtensions = languageExtensions[tempstore] || ['.txt'];
    console.log('Detected file extensions:', detectedExtensions);
    return detectedExtensions;
  }

  openDialog(): void {

    const dialogRef = this.dialog.open(DisclaimerComponent);
}
}
