import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { SharedService } from '../../codeGenYLite/Services/shared.service';
import { ActivityAudit, FileModel } from '../../codeGenYLite/data/app-model';
import { RoundLoaderService } from '../../codeGenYLite/Services/roundloader.service';
import { SopService } from 'src/app/shared/service/sop.service';
import { ToastMessageService } from '../../shared/service/toast-message.service';
import hljs from 'highlight.js';
import * as monaco from 'monaco-editor';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
//import prism
import 'prismjs';
import * as Prism from 'prismjs';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-enhancecode',
    templateUrl: './enhancecode.component.html',
    styleUrls: ['./enhancecode.component.scss'],
    standalone: false
})
export class EnhancecodeComponent {
  @Output() switchTab = new EventEmitter<number>(); // Event emitter to notify the parent component
  @ViewChild('fileInput') fileInput: any;
  @ViewChild('textarea', { static: false }) textarea!: ElementRef;
  myForm!: FormGroup; // Define the form group
  showGoToGenerateCode:boolean = false;  //go to generate code btn hide here.........
  invalidSize: boolean = false;
  invalidType: boolean = false;
  isLoading : boolean = false;
  selFiles: any;
  downloadLink: string = '';
  downloadLink2: string = '';
  docName: string = '';
  activityAudit: ActivityAudit = {
    ohr_id: '',
    time_stamp: '',
    module: '',
  }
  // docAvailable!: Subscription;
  isDocAvailable: boolean = false;
  isDocAvailableHasError: boolean = false;
  fileData: any;
  sddDocName: any;
  downloadbtn: boolean =  false;
docname: any;
docuploaded: boolean = false;
selectedFile: File | null = null;
namefile: any;
codes: any;
placeholder: any;
codedata: string = ''; // Initialize codedata property
codepoints:any;
codeextension:any='';
  content: string = '';
  codelang: any = ''; // Initialize the 'codelang' property
  originalFileExtension: string = '';
  codeMirrorOptions = {
    theme: 'material', // Choose your preferred theme
    mode: 'auto', // Specify the mode for syntax highlighting
    lineNumbers: true,
    lineWrapping: true,
    autofocus: true // Example of additional options


    // Add more options as needed
  };
  tooltipText: string = 'Click to Copy'; // Initialize the tooltip text
  editorOptions = {theme: 'vs-dark', language: this.codeextension};

  codingLanguages: string[] = ['Angular','Apex', 'AngularJS','AURA', 'C#', 'CSS','Dataweave', 'Dax','Django', 'HTML', 'Java', 'JavaScript','JSON','JSON (Azure Logic Apps)','LWC', 'MongoDB', 'MySQL', 'Node.js','Oracle', 'PHP', 'PostgreSQL','powershell','PySpark', 'Python', 'React', 'Ruby','ServiceNow','SQL','Terraform', 'TypeScript','Unix','Vue.js','XML','XML (Taleo Connect Client)','YAML'];

highlightedCode: any;
iscopied:boolean = false;
processName: any;

  // dialogRef!: MatDialogRef<CompletionpopupComponent, any>;
  constructor(public dialog: MatDialog, private sopService: SopService, private sanitizer: DomSanitizer,
    private loaderService: RoundLoaderService, private toastMessage : ToastMessageService,) {
       // Set the default coding language to the first element in the array

     }
  ngOnDestroy(): void {
    // this.docAvailable.unsubscribe();
    }

    ngOnInit(): void {
      console.log(this.codeMirrorOptions);
        //throw new Error('Method not implemented.');
      }
  // onButtonSubmit() {
  //   if (this.selFiles && this.selFiles.length > 0) {
  //     this.sopService.generatesdd(this.selFiles).subscribe({


  //          else {

  //         }
  //         // this.activityLogging();
  //       },

  //     )};
  //       (error: any) => {
  //         this.loaderService.hideLoader();
  //         // this.activityLogging();
  //       },
  //     });
  //   }
  //   else {
  //     // this.sharedService.openSnackBar("Please upload a file", MessageTypeSnack.Error);
  //     this.toastMessage.
  //   }
  // }
changes() {
  this.toastMessage.showmessage(this.codelang);
this.placeholder=this.setExtension(this.codelang);
this.codes = this.placeholder;



}
setExtension(language: string): string {
  switch (language) {
    case 'C#':
      return '.cs,.txt';
    case 'CSS':
      return '.css,.txt';
    case 'HTML':
      return '.html,.txt';
    case 'Java':
      return '.java,.txt';
    case 'JavaScript':
    case 'MongoDB':

      return '.js,.txt';
      case 'TypeScript':
      return '.ts,.txt';
    case 'MySQL':
    case 'PostgreSQL':
    case 'SQL':
    case 'Oracle':
      return '.sql,.txt';
    case 'PHP':
      return '.php,.txt';
    case 'Python' :
      return '.py,.txt';
    case 'PySpark':
      return '.py,.txt';
    case 'Ruby':
      return '.rb,.txt';
    case 'DAX':
      return '.txt';
    case 'powershell':
      return '.ps1,.txt';
      case 'YAML':
        return '.yaml,.yml,.txt';
      case 'XML':
        return '.xml,.txt';
      case 'JSON':
        return '.json,.txt';
      case 'JSON (Azure Logic Apps)':
          return '.json,.txt';
      case 'Terraform':
        return '.tf,.txt';
    default:
      return '.txt'; // Default extension if language not found
  }
}

onupload(event: any) {
  const file = event.target.files[0];
  const maxCharLimit = 40000; // Define the character limit

  if (file) {
    // Validate file extension before reading content
    this.originalFileExtension = file.name.split('.').pop()?.toLowerCase() || '';
    if (!this.isValidFileExtension(this.originalFileExtension)) {
      this.toastMessage.showError('Invalid file type. Only ' + this.placeholder + ' are allowed.');
      // Clear file input
      this.fileInput.nativeElement.value = null;
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      this.content = reader.result as string;

      if (this.content.length > maxCharLimit) {
        this.toastMessage.showError('File content exceeds the maximum allowed limit of 40,000 characters.');
        // Clear file input
        this.fileInput.nativeElement.value = null;
        return;
      }

      this.docuploaded = true;
      this.selectedFile = file;
      this.namefile = file.name;
      this.setEditorLanguage(this.originalFileExtension);
    };
    
    // Read the file content as text
    reader.readAsText(file);
  }
}
  isValidFileExtension(extension: string): boolean {
    const allowedExtensions = this.codes;
    return allowedExtensions.includes(extension);
  }
  setEditorLanguage(fileExtension: string | undefined) {
    switch (fileExtension) {
      case 'ts':
        this.editorOptions.language = 'typescript';
        break;
      case 'js':
        this.editorOptions.language = 'javascript';
        break;
      case 'cs':
        this.editorOptions.language = 'csharp';
        break;
      case 'html':
        this.editorOptions.language = 'html';
        break;
      case 'css':
        this.editorOptions.language = 'css';
        break;
      case 'py':
        this.editorOptions.language = 'python';
        break;
      case 'java':
        this.editorOptions.language = 'java';
        break;
      case 'sql':
        this.editorOptions.language = 'sql';
        break;
      case 'php':
        this.editorOptions.language = 'php';
        break;
      case 'rb':
        this.editorOptions.language = 'ruby';
        break;
      case 'json':
        this.editorOptions.language = 'json';
        break;
      case 'txt':
        this.editorOptions.language = 'plaintext';
        break;
      default:
        this.editorOptions.language = 'plaintext'; // Default to plaintext for unknown extensions
        break;
    }
  }
  onButtonSubmit(event : any) {
    this.isLoading = true;

    const file = event;
    if (this.selFiles && this.selFiles.length > 0)
    // const file = this.selFiles[0];
      this.fileData = file;
      console.log(this.fileData?.file, this.fileData?.name)
    // this.getOHR();

    const blob = new Blob([this.content], { type: 'text/plain' });
    const file1 = new File([blob], 'converted.txt', { type: 'text/plain' });








    const formData = new FormData();
    if (this.content) {
      // formData.append('file', this.selectedFile);
      formData.append('file', file1);
    }
//if there is no selected file and content is present then add file1 to formdata
    // if (!this.selectedFile && this.content) {
    //   formData.append('file', file1);
    // }

    formData.append('language', this.codelang);
    this.toastMessage.showSuccess("uploading"+this.selectedFile?.name+"file");
    formData.append('process_name', this.processName);
    //formData.append('user_id', this.userOHR);
  //  formData.append('app-name', this.functionSelected);
    this.sopService.getenhancement(formData).subscribe(
      (response: any) => {
        // this.loaderService.hideLoader();
        console.log(response)
        if( response?.message == 'Code Enhancement is in progress and will be generated shortly')
        {
          this.sddDocName = 'SDD';
          // this.generatetestcase();
          // this.testcase=response.data;
          // this.fileUploadedStatus = true;
          // this.filestatus1=false;
          this.toastMessage.showSuccess(response?.message);
          // setTimeout(() => {
          this.enhancedocStatus();
          // }, 10000); // 10 seconds in milliseconds
         }
         else
         {
          this.toastMessage.showError(response?.message);
       //  this.isLoading = false;
         }
      },
      (error:any) => {
        // Handle the error here
       this.isLoading = false;
        console.error(error);
        this.toastMessage.showError(error?.message);
      }
    );

    // else {
    //   this.toastMessage.showError("Please upload a file");
    // }
  }

  enhancedocStatus() {
  //this.loaderService.showLoader();
    this.sopService.getenhancementstatus().subscribe(
      //need to check response type if blob call download method

      (response: any) => {
          if(response.message === 'Code documentation is not ready') {
           // this.docuploaded = false;
            this.downloadbtn = true;
        this.isLoading = true;//
                    // Document not available yet, continue checking after 15 seconds
                    setTimeout(() => {
                      this.toastMessage.showmessage('code enhancement is being done, please wait for a moment.')
                      this.enhancedocStatus();
                   //   this.isLoading = true;
                    }, 15000); // 15 seconds in milliseconds
                  }

               if(response?.improved_code){
                this.isLoading = false;
                this.downloadbtn = true;
                this.toastMessage.showSuccess('Code Enhancement is ready');
               // this.codedata = response?.improved_code;
                this.codepoints = response?.improvement_points;
                this.codeextension = response?.file_extension;
                const improvedCodeWithQuotes: string = response.improved_code;

                // Remove triple quotes from the beginning and end of the string
               this.codedata = improvedCodeWithQuotes.replace(/^```[\s\n]*|```$/g, '');


               }

          else { // Document is ready
          // this.isLoading = false;
            this.downloadbtn = true;
            // this.showGoToGenerateCode = true;
        }
      },
      (error) => {
        this.checkd();
       this.isLoading = false;
        console.error('Error downloading file:', error);
        // Handle error as needed
      }
    );
  }

  triggerFileInputClick() {
    this.fileInput.nativeElement.click();
  }
  removeFile(fileInput: HTMLInputElement){
    this.docuploaded = false;
    this.selectedFile = null;
    this.downloadbtn = false;
    this.content = '';
    this.codedata = '';
    this.codepoints = '';
    //remove file from File location
    fileInput.value = '';


  }
  checkd(){
    this.sopService.downloadcodedocumentation().subscribe(
      (response: Blob) => {
        this.downloadbtn = true;
        // this.download();
      },
      (error) => {
       this.isLoading = false;
        console.log('Error downloading file:', error);
        // Handle error as needed
      }   );
  }
  download() {
    this.sopService.downloadcodedocumentation().subscribe(
      (response: Blob) => {
       this.isLoading = false;
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        // Assuming process_name is a variable containing the process name
        const timestamp = new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
        const fileNameParts = this.fileData?.name.split('_');
        const processName = fileNameParts && fileNameParts.length >= 3 ? fileNameParts[1] : ''; // Assuming process name is the second part

        const filename = `Code documentation_${processName}_${timestamp}.docx`;

        // Set the filename for the download
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        this.toastMessage.showSuccess('File Downloaded Successfully');
        // Cleanup
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

      }
    );
    }
  goToGenerateCode() {
    this.switchTab.emit(2); // Emit the event to notify the parent component to switch to the SDD tab
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];

    if (!file) {
      this.selectedFile = null; // Clear selectedFile if no file is chosen
      this.selFiles = [];
      this.toastMessage.showError("No file chosen.");
      return;
    }

    const fileSize = file.size / 1024 / 1024; // Convert bytes to MB
    const fileType = file.name.split('.').pop();

    if (fileSize > 1) {
      this.invalidSize = true;
      this.invalidType = false;
      return;
    }

    if (!['doc', 'docx'].includes(fileType)) {
      this.invalidSize = false;
      this.invalidType = true;
      return;
    }

    // If a valid file is selected, reset error flags and proceed
    this.invalidSize = false;
    this.invalidType = false;
    this.selectedFile = file;
    this.selFiles[0] = event.target.files[0]; // Clear any previous selected files
  }
  copyText() {
    const codeElement = document.getElementById('codeSnippet');
    if (codeElement) {
      const range = document.createRange();
      range.selectNode(codeElement);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      try {
        document.execCommand('copy');
        window.getSelection()?.removeAllRanges();
        //add a timeout iscopied true for 3sec the false



        this.iscopied = true;
        setTimeout(() => {
          this.iscopied = false;
        }, 4000);
        // Optionally, show a message indicating successful copy
      } catch (error) {
        console.error('Error copying text:', error);
        // Handle error if necessary
      }
    }
  }

  copyText1(): void {
    navigator.clipboard.writeText(this.codedata).then(() => {
      // Change tooltip text after successfully copying
      this.tooltipText = 'Copied!';

      // Set a timeout to change the tooltip back to "Click to Copy" after 5 seconds (5000 milliseconds)
      setTimeout(() => {
        this.tooltipText = 'Click to Copy';
      }, 5000);
    }).catch(() => {
      this.toastMessage.showError('Error copying text');
    });
  }

  downloadCode() {
    const blob = new Blob([this.codedata], { type: `text/${this.codeextension}` });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    //removing .extension from filename
    const filename = this.selectedFile?.name.split('.').slice(0, -1).join('.');
    a.download = `${ filename ? filename: this.processName}.${this.originalFileExtension? this.originalFileExtension : 'txt'}`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  }

