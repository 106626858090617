import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { ToastMessageService } from 'src/app/shared/service/toast-message.service';
import { MultiagentService } from '../shared/service/multiagent.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MarkdownModule } from 'ngx-markdown';

interface ChatMessage {
  name: string;
  content: string;
}

@Component({
  selector: 'app-colloborative-agent',
  templateUrl: './colloborative-agent.component.html',
  imports: [CommonModule, FormsModule, MarkdownModule],
  standalone: true,
  styleUrls: ['./colloborative-agent.component.scss']
})
export class ColloborativeAgentComponent implements OnDestroy {
  @ViewChild('scrollChat') scrollChat!: ElementRef;
  chatData: any[] = []; // Array to hold chat data
  selectedFiles: File[] = []; // Array to hold selected files
  prompt: string = ''; // User input prompt
  inputAccept: string = '.pdf,.doc,.docx,.png,.jpg,.jpeg,.csv,.xlsx,.ppt,.pptx'; // Accepted file types
  isLoading: boolean = false;
  showInstructions: boolean = true;
  lastResponseLength: number = 0;
  processedMessages: Set<string> = new Set(); // Track processed messages
  chatSubscriber!: Subscription;
  resetChatStatus: boolean = false;
  token: string = '';
  txtarea: boolean = true;
  uploadfilename: string = '';
  threadId: any;
  isPromptActive: boolean = false; // New variable to track input state

  constructor(
    private toastMessage: ToastMessageService,
    private loaderService: LoaderService,
    private multiagentService: MultiagentService,
  ) { }

  ngOnDestroy(): void {
    if (this.chatSubscriber) {
      this.chatSubscriber.unsubscribe();
    }
  }

  autoResize(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  handleEnter(): void {
    if (this.prompt.trim()) {
      this.sendMessage();
    }
  }

  scrollToBottom(): void {
    this.scrollChat.nativeElement.scrollTop = this.scrollChat.nativeElement.scrollHeight;
  }

  getResponse(event: any) {
    this.loaderService.loading.next(true);
    this.resetChatStatus = false;

    this.multiagentService.getThread(this.prompt).subscribe(
      (res: any) => {
        this.loaderService.loading.next(false);

        if (res && res.data) {
          this.prompt = res.data;
          this.sendMessage();
        } else {
          this.sendMessage();
        }

        this.resetChatStatus = true;
      },
      (error: any) => {
        this.loaderService.loading.next(false);
        this.toastMessage.showError('Something went wrong');
        this.resetChatStatus = true;
      }
    );
  }

  sendMessage(): void {
    if (this.prompt.trim()) {
      this.showInstructions = false;
      this.isLoading = true;
      const payload: any = {
        task: this.prompt,
      };

      if (this.uploadfilename) {
        payload['filename'] = this.uploadfilename;
      }

      this.chatData.push({ role: 'user', content: this.prompt });

      this.multiagentService.getThread(payload.task).subscribe((res: any) => {
        if (res && res.thread_id) {
          this.threadId = res.thread_id;
          this.pollChat(this.threadId);
        } else {
          this.isLoading = false;
          this.toastMessage.showError('Failed to get thread ID');
        }
      }, (error: any) => {
        this.isLoading = false;
        this.toastMessage.showError(error);
        this.resetChatStatus = true;
      });

      this.prompt = '';
      this.scrollToBottom();
    } else {
      this.toastMessage.showError('Question is required');
    }
  }

  pollChat(threadId: number): void {
    const intervalId = setInterval(() => {
      this.multiagentService.getChat(threadId).subscribe((res: any) => {
        if (res && res.data) {
          const newResponses = res.data[0].filter((message: ChatMessage) => {
            const messageId = `${message.name}:${message.content}`;
            if (!this.processedMessages.has(messageId)) {
              this.processedMessages.add(messageId);
              return true;
            }
            return false;
          });

          newResponses.forEach((message: ChatMessage) => {
            const formattedMessage = {
              role: 'bot',
              content: `${message.name}:\n${message.content}`
            };
            this.chatData.push(formattedMessage);
          });

          this.scrollToBottom();
        }

        if (res.status === false) {
          clearInterval(intervalId);
          this.isLoading = false;
        }
      }, (error: any) => {
        this.isLoading = false;
        clearInterval(intervalId);
        this.toastMessage.showError(error);
        this.resetChatStatus = true;
      });
    }, 8000);
  }

  copyToClipboard(content: string): void {
    navigator.clipboard.writeText(content).then(() => {
      console.log('Copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }

  regenerate(index: number) {
    this.prompt = this.chatData[index - 1]?.content;
    this.sendMessage();
  }

  uploadFile(action: string = 'upload'): void {
    if (action === 'reset') {
      this.selectedFiles = [];
      this.uploadfilename = '';
      this.txtarea = true;
    } else {
      document.getElementById('upload_chatFile')?.click();
    }
  }

  uploads(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      let fileSizeMB = 0;
      const maxFileSize = 16;

      for (const file of Array.from(input.files)) {
        const fileType = file.name.split('.').pop()?.toLowerCase();

        if (!this.inputAccept.includes(`.${fileType}`)) {
          this.toastMessage.showmessage(`The file type .${fileType} is not accepted. Allowed types: ${this.inputAccept}`);
          this.clearFileInput('upload_chatFile');
          return;
        }

        fileSizeMB += file.size / 1024 / 1024;

        if (fileSizeMB > maxFileSize) {
          this.clearFileInput('upload_chatFile');
          this.toastMessage.showmessage('Maximum file size limit is 16MB.');
          return;
        }

        this.selectedFiles.push(file);
      }
    }
  }

  clearFileInput(elementId: string): void {
    const fileInput = document.getElementById(elementId) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
      this.txtarea = true;
    }
  }

  inputPrompt(question: string): void {
    console.log(`Prompting with question: ${question}`);
  }

  newChat(): void {
    this.chatData = [];
    this.selectedFiles = [];
    this.prompt = '';
    this.txtarea = true;
    this.clearFileInput('upload_chatFile');
    console.log('Starting a new chat');
  }

  sentToInput(): void {
    this.sendMessage();
  }
}
