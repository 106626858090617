import { Component, OnInit, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { VoiceRecognitionService } from '../service/voice-recognition.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastMessageService } from 'src/app/shared/service/toast-message.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { InterAssistService } from '../service/inter-assist.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as FileSaver from 'file-saver';
import * as HtmlDocx from 'html-docx-js-typescript';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
    selector: 'app-transcript',
    templateUrl: './transcript.component.html',
    styleUrls: ['./transcript.component.scss'],
    standalone: false
})
export class TranscriptComponent implements OnInit{
  selection: string = 'audio/video'; // Default selection
  fileDetails: { name: string, type?: string } | null = null;
  uniqid!: string; // Unique ID for the file
  reqid: any;
  filedata!: File;
  audioid: any;
  dnld: boolean = false;
  isLoading = false;
  reportbtn: boolean = false;
  reportdata: any;
  reportForm!: FormGroup;
  isEditing = false;
 sumbtn: boolean = true;
  userid: any[] = [];
  sumstatus:boolean = false;
  reportstatus:boolean = false;
  // uniqId: any[] = [];

  dropdownSettings: IDropdownSettings = {
    singleSelection: true,  // or false if you want multiple selections
    idField: 'id',          // 'id' is the unique identifier in your options array
    textField: 'name',      // 'name' is the field used for display text
    itemsShowLimit: 3,
    maxHeight: 160,
    allowSearchFilter: true
  };

score: any;
fcandidate: any;
finterviewer: any;
osummary: any;
creview: any;
  summaryreportdata: any;
  docxBlob!: Blob;





  constructor(private http: HttpClient,
    private speechservice: VoiceRecognitionService,
    private route: ActivatedRoute,
    private toastservice : ToastMessageService,
    private loaderservice : LoaderService,
    private panelservice: InterAssistService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private router: Router
) {
// this.speechservice.init();
this.route.params.subscribe((res: any) => {
if (res?.id) {
 this.uniqid = res.id;
  this.reqid = res.rid;
}
})
}

nav(){
  this.router.navigate(['interview-assist/'+this.reqid+'/'+this.uniqid+'/IntelliQ&A']);

}

ngOnInit() {
  this.getlist();
  this.reportForm = this.fb.group({
    scoreCard: [''],
    candidateFeedback: [''],
    interviewerFeedback: [''],
    summary: [''],
    jobProfileReview: ['']
  });

  this.reportForm.patchValue({
    scoreCard: this.reportdata["Candidate’s Score Card:"],
    candidateFeedback: this.reportdata["Feedback of the Candidate:"],
    interviewerFeedback: this.reportdata["Feedback of the Interviewer:"],
    summary: this.reportdata["Overall Summary:"],
    jobProfileReview: this.reportdata["Review As Per the Job Profile or Requirement:"]
  });
}

toggleEdit() {
  this.isEditing = !this.isEditing;
}

saveEdits() {
  console.log('Saved', this.reportForm.value);
  this.toggleEdit();
}

downloadReport1() {
  const content = document.querySelector('.report-d')?.innerHTML;
  if (content) {
    HtmlDocx.asBlob(content).then((converted: Blob | Buffer) => { // Accept both Blob and Buffer
      if (converted instanceof Blob) {
        FileSaver.saveAs(converted, 'report.docx');
      } else {
        // Handle the case where converted is a Buffer
        // For example, you might need to convert it to a Blob first
        const blob = new Blob([converted], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        FileSaver.saveAs(blob, 'report.docx');
      }
    });
  }
}


downloadReport() {
  // Construct HTML content for the report
   // Function to replace newlines with HTML line breaks
const reportData = this.reportdata;
   const htmlContent = `
       <html>
           <head>
               <style>
                   body { font-family: Arial, sans-serif; }
                   h2 { color: #00aecf; margin-top: 12px; }
                   p { margin: 4px; white-space: pre-wrap; } // Ensure spaces and formatting are retained
               </style>
           </head>
           <body>
               <h1 style="color: #00aecf; text-align: center;">Interview Evaluation Report</h1>
               <h2>Candidate Score Card:</h2>
               <p>${this.formatText(reportData["Candidate’s Score Card:"])}</p>
               <h2>Feedback of the Candidate:</h2>
               <p>${this.formatText(reportData["Feedback of the Candidate:"])}</p>
               <h2>Feedback of the Interviewer:</h2>
               <p>${this.formatText(reportData["Feedback of the Interviewer:"])}</p>
               <h2>Overall Summary:</h2>
               <p>${this.formatText(reportData["Overall Summary:"])}</p>
               <h2>Review As Per the Job Profile or Requirement:</h2>
               <p>${this.formatText(reportData["Review As Per the Job Profile or Requirement:"])}</p>
           </body>
       </html>
   `;
const content = htmlContent;
if (content) {
HtmlDocx.asBlob(content).then((converted: Blob | Buffer) => { // Accept both Blob and Buffer
  if (converted instanceof Blob) {
    FileSaver.saveAs(converted, 'report.docx');
  } else {
    // Handle the case where converted is a Buffer
    // For example, you might need to convert it to a Blob first
    const blob = new Blob([converted], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    FileSaver.saveAs(blob, 'report.docx');
  }
});
}
}


async sendReportAsDocx() {
  this.isLoading = true;
  const reportContentElement = document.getElementById('report-content');
  if (reportContentElement) {
    const reportContent = reportContentElement.innerHTML;
    HtmlDocx.asBlob(reportContent).then((converted: Blob | Buffer) => { // Accept both Blob and Buffer
      if (converted instanceof Blob) {
       // const docxBlob = new Blob([converted], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

     //  FileSaver.saveAs(converted, 'report.docx');

      const formData = new FormData();
      formData.append('id', this.uniqid);
      formData.append('final_report', converted);

      this.panelservice.savereport(formData).subscribe(
        (response: any) => {
          this.isLoading = false;
          if (response.status === 'success') {
            this.toastservice.showSuccess(response.message);
            this.changestatus();
          } else {
            this.toastservice.showError(response.message);
          }
        },
        (error) => {
          this.isLoading = false;
          this.toastservice.showError(error.message);
        }
      );

      } else {
        // Handle the case where converted is a Buffer
        const docxBlob = new Blob([converted], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
       // FileSaver.saveAs(docxBlob, 'report.docx');



      const formData = new FormData();
      formData.append('id', this.uniqid);
      formData.append('final_report', docxBlob);

      this.panelservice.savereport(formData).subscribe(
        (response: any) => {
          this.isLoading = false;
          if (response.status === 'success') {
            this.toastservice.showSuccess(response.message);
            this.changestatus();
          } else {
            this.toastservice.showError(response.message);
          }
        },
        (error) => {
          this.isLoading = false;
          this.toastservice.showError(error.message);
        }
      );
    }
    });


  }
}







formatText(text: string): string {
// First, normalize newlines and replace them with <br> for initial split
text = text.replace(/\n/g, '<br>');

// Split the text into lines
const lines = text.split('<br>');
const transformedLines = [];
let isListActive = false; // Track whether we're currently in a list

lines.forEach(line => {
    // Check if the line should be a bullet point
    if (line.trim().startsWith('- ')) {
        if (!isListActive) {
            transformedLines.push('<ul>'); // Start a new list
            isListActive = true;
        }
        // Add the line as a list item, removing the "- " marker
        transformedLines.push(`<li>${line.trim().substring(2)}</li>`);
    } else {
        if (isListActive) {
            transformedLines.push('</ul>'); // Close the list
            isListActive = false;
        }
        // Add normal line
        transformedLines.push(`<p>${line}</p>`);
    }
});

// If the list was not closed, close it
if (isListActive) {
    transformedLines.push('</ul>');
}

return transformedLines.join(''); // Join all transformed lines back into a single string
}














  handleSelectionChange(event: any) {
    this.selection = event.target.value;
    this.fileDetails = null; // Reset file details on change
  }

  handleFileChange(event: Event) {
    const element = event.target as HTMLInputElement;
    let files = element.files;
    if (files && files.length > 0) {
      const file = files[0];
      this.filedata = file;
      this.fileDetails = {
        name: file.name,
        type: file.type
      };

      // Auto-upload for transcripts
      if (this.selection === 'upload transcript') {
        this.uploadFile(file);
      }
      if (this.selection === 'audio/video') {
        this.callApiForTranscript();
      }
    }
  }

  uploadFile(file: File) {
    console.log('Uploading file');
this.toastservice.showSuccess('File uploaded successfully'+'please go generate report');

  }

  callApiForTranscript() {
    const formData = new FormData();
    if (this.selection === 'audio/video') {
    formData.append('audio_file', this.filedata);
    }
    if (this.selection === 'upload transcript'){
      formData.append('transcript_file', this.filedata);

    }
    formData.append('id', this.uniqid);
     this.isLoading = true;
    this.panelservice.gettranscript(formData).pipe(
      catchError((error) => {
         this.isLoading = false;
        this.toastservice.showError('Error in getting transcript');
        return of(null);
      })
    ).subscribe((res: any) => {
       this.isLoading = false;
      if (res) {
        this.audioid = res.data;
        this.toastservice.showSuccess(res?.message);
        //this.router.navigate(['/transcript']);
      }
    });
  }

  downloadTranscript() {
    const formData = new FormData();
    formData.append('id', this.uniqid);
    formData.append('transcript_id', this.audioid);
     this.isLoading = true;
    this.dnld = true;
    this.panelservice.downloadTranscript(formData).pipe(
      catchError((error) => {
         this.isLoading = false;
       // this.toastservice.showError('Error in downloading transcript');
        return of(null);
      })
    ).subscribe((res: any) => {

      if (res) {
        if(res?.message === 'Transcription is in progress'){
        this.toastservice.showSuccess(res.message);
        setTimeout(() => {

          this.toastservice.showSuccess('Transcription is in progress');
          this.downloadTranscript();
        }, 5000);
        }
        else{
           this.isLoading = false;
          this.toastservice.showSuccess(res.message);
        }
      }
    });
  }

  download() {
     this.isLoading = true;
    const formData = new FormData();
    formData.append('id', this.uniqid);
    formData.append('transcript_id', this.audioid);
    this.panelservice.downloadTranscript1(formData).subscribe(
        (response: Blob) => {
             this.isLoading = false;
            // Changed the MIME type to 'text/plain' for TXT files
            const blob = new Blob([response], { type: 'text/plain' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            const timestamp = new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });

            // Changed the file extension to '.txt'
            const filename = `Transcript_${this.uniqid}_${timestamp}.txt`;

            a.download = filename;
            document.body.appendChild(a);
            a.click();
            this.toastservice.showSuccess('File Downloaded Successfully');
             this.isLoading = false;

            // Cleanup
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        },
        error => {
             this.isLoading = false;
            this.toastservice.showError('Failed to download file');
            console.error('Download error:', error);
        }
    );
}

generatereport(report:string) {
  const report1 = report;
  const formData = new FormData();
  formData.append('id', this.uniqid);
  formData.append('report_type', report1);
  if (this.selection === 'audio/video') {
    // formData.append('audio_file', this.filedata);
    }
    if (this.selection === 'upload transcript'){
      formData.append('transcript_file', this.filedata);

    }
  // formData.append('transcript_id', this.audioid);
    this.isLoading = true;
  this.panelservice.generateReport(formData)
  .pipe(
    catchError((error) => {
       this.isLoading = false;
      this.toastservice.showError('Error in generating report');
      return of(null);
    })
  ).subscribe((res: any) => {
     this.isLoading = false;
    if (res) {
      this.toastservice.showSuccess(res.message);
      if(report1 !== 'normal'){
      this.downloadreport();}
    }
    if(report1 === 'normal'){
      this.summaryreportdata = res.data;
      this.sumstatus = true;
      this.reportstatus = false;

    }
  });


}

downloadreport() {
  this.isLoading = true;
  const formData = new FormData();
  formData.append('id', this.uniqid);
  this.panelservice.downloadReport(formData).subscribe(
      (res: any) => {
        if (res) {
          if(res?.message === 'Report is in progress'){
          this.toastservice.showSuccess(res.message);
          setTimeout(() => {

            this.toastservice.showSuccess('Report is in progress');
            this.downloadreport();
          }, 5000);
          }
          else if(res?.message === 'Report is created successfully'){
             this.isLoading = false;
             this.reportdata = res.data;
             this.score = res.data["Candidate’s Score Card:"];
              this.fcandidate = res.data["Feedback of the Candidate:"];
              this.finterviewer = res.data["Feedback of the Interviewer:"];
              this.osummary = res.data["Overall Summary:"];
              this.creview = res.data["Review As Per the Job Profile or Requirement:"];
              this.reportstatus = true;
              this.sumstatus = false;
            this.toastservice.showSuccess(res.message);
          }
        }
      },
      error => {
           this.isLoading = false;
          this.toastservice.showError('Failed to download file');
          console.error('Download error:', error);
      }
  );
}

getlist(){

  this.panelservice.userList().subscribe(data => {
    this.userid = data
      .filter((user: { status: string; }) => user.status === 'report analysis')
      .map((user: { id: any; requisition_id: any; }) => ({
        id: user.id,
        name: `${user.requisition_id}_${user.id}`
      }));


  });
}



changestatus(){
  this.isLoading = true;
    const formData = new FormData();
    formData.append('id', this.uniqid); // Append the unique id to the form data
    formData.append('status', 'completed'); // Append the status to the form data
 this.panelservice.changestatus(formData).subscribe(
      (response:any) => {

        if(response.status ==  'success'){
     this.isLoading = false;
      this.toastservice.showSuccess(response.message);
      this.router.navigate(['interview-assist/transcript-manager']);
        }
        else{
         this.isLoading = false;
        }
    },
    (error) => {
     this.isLoading = false;
      this.toastservice.showError(error.message);
    }
 );


  }











}
