import { Component } from '@angular/core';

@Component({
    selector: 'app-admin-portal',
    templateUrl: './admin-portal.component.html',
    styleUrls: ['./admin-portal.component.scss'],
    standalone: false
})
export class AdminPortalComponent {

}
