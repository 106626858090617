import { Component, ViewChild, ElementRef, OnInit, OnDestroy, Input } from '@angular/core';
import { faRobot } from '@fortawesome/free-solid-svg-icons/faRobot';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faCheck, faClose, faEdit, faPlay, faCode, faCoffee, faTasks, faDownload, faL } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from 'src/app/shared/Utils/CommonService';
import  {TcgService} from '../../shared/service/tcg.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { RoundLoaderService } from 'src/app/codeGenYLite/Services/roundloader.service';
import { ToastMessageService } from 'src/app/shared/service/toast-message.service';
import  {SopService} from 'src/app/shared/service/sop.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';


@Component({
    selector: 'app-tcgenerate',
    templateUrl: './tcgenerate.component.html',
    styleUrls: ['./tcgenerate.component.scss'],
    standalone: false
})
export class TcgenerateComponent implements OnInit {
  @ViewChild('messageContainer') messageContainer!: ElementRef;
  @ViewChild('fileInput') fileInput: any;

  @Input() placeholder1 = 'Develop an intuitive and user-friendly UI for an enterprise dashboard that allows users to customize and personalize the displayed data based on their roles and responsibilities.';
  [x: string]: any;
  docname: any;
    downloadStatus = false;
    processStatus: boolean = false;
    targetSASUrl: any;
    fileUploadedStatus = false;
    fileData: any;
    userOHR: any;
   fupload:boolean=false;
    intervalId: any; // Variable to store the interval ID
    functionList: any = [];
    functionSelected: any;
    subscription!: Subscription;
    functionname: any;
    dupload: boolean=false;
    doclist: any;
    dfileselected: any;
    filestatus:boolean=false;
    testcase: any;
    filestatus1: boolean=true;
    testanswer: any;
    rasheet:boolean=true;
    saverasheet:boolean=false;
    dropdownSettings: IDropdownSettings = {
      singleSelection: true,
      idField: 'value',
      textField: 'name',
      itemsShowLimit: 3,
      maxHeight: 160,
      allowSearchFilter: true
    };
  processName: any;
  tcSelected: any = [];
  filetype: any = [
    {name: 'RA', value: 'RA'}
  ];;

tcFiles1: any = [
  //  name,value format
  //SDD
    {name: 'SDD', value: 'SDD'},
  //RA
    {name: 'RA', value: 'RA'}


];
// //ftype intialization to RA
//   ftype: any = [
//     {name: 'RA', value: 'RA'}
//   ];

  tcFiles: any = [
  //  name,value format
    {name: 'Finance', value: 'Finance'},
    {name: 'HR', value: 'HR'},
    {name: 'I&L', value: 'I&L'},
   // {name: 'Sales', value: 'Sales'},
    //BO
    {name: 'BO', value: 'BO'},
    //BI
    {name: 'BI', value: 'BI'},


  ];
    isFileNeeded:boolean = false;
    isFileNotNeeded:boolean = false;
    downloadbtn:boolean=false;

  constructor(public commonService: CommonService, public _sanitizer: DomSanitizer,
    public loaderService: RoundLoaderService, private toastMessage: ToastMessageService,
    private sopService: SopService, private tcgService:TcgService) {

  }

  ngOnInit(): void {
    this.getOHR();
  }

  getOHR() {
    let userData = localStorage.getItem('okta-token-storage');
    if (userData) {
      let ohr_id = JSON.parse(userData)?.accessToken?.claims?.OHR;
      if (ohr_id) {
        this.userOHR = ohr_id;
      }
    }
  }

  onItemSelect(event: any) {
    console.log(event);
  }

  scrollToBottom(): void {
    try {
      this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error(err);
    }
  }

  edit()
  {
    this.rasheet = false;
    this.saverasheet=true;
   // this.isFileNeeded = false;
  }

  save()
  {
    this.toastMessage.showmessage('Changes saved successfully');
    this.saverasheet=false;
    this.rasheet=true;
   // this.isFileNeeded = false;
  }

      // Upload file for translation
      postUpload(event: any) {
        // allow only if .docx file is uploaded

if(this.filetype.length == 0){
  this.toastMessage.showError('Please select a file type');
  this.fileInput.nativeElement.value = null;
  return;
}
        if (event.target.files[0].name.split('.').pop() !== 'docx') {
          this.toastMessage.showError('Please upload a .docx file');
          return;
        }

        const file = event.target.files[0];
        this.loaderService.showLoader();
          this.fileData = file;
          this.toastMessage.showmessage('Your File is being Uploaded ...');
          console.log(this.fileData?.file, this.fileData?.name)
        this.getOHR();
        const formData = new FormData();
        formData.append('ra_sheet', this.fileData);
        formData.append('file_type', this.filetype[0].value);

        //formData.append('user_id', this.userOHR);
      //  formData.append('app-name', this.functionSelected);
        this.tcgService.fileupload(formData).subscribe(
          (response: any) => {
            this.loaderService.hideLoader();
            console.log(response)
            if( response?.status == 'success')
            {
              this.generatetestcase();
              this.testcase=response.data;
              this.fileUploadedStatus = true;
              this.filestatus1=false;
              this.toastMessage.showSuccess(response?.message+"\nFileName: "+this.fileData?.name);
             }
             else
             {
              this.toastMessage.showError(response?.message);
             }
          },
          (error:any) => {
            // Handle the error here
            this.loaderService.hideLoader();
            console.error(error);
            this.toastMessage.showError(error?.message);
          }
        );

      }
    //
    fileupload()
    {
      this.fupload=!this.fupload;
      this.dupload=false;
     }

    filelist()
    {
      this.fupload= false;
      this.dupload=true;
    }

generatetestcase1(){
  this.loaderService.showLoader();
 
  const payload:any =
  {
    summary: this.testanswer,
    file_status: this.isFileNeeded.toString(),
    "process_name": this.processName,
  }

if(this.tcSelected.length > 0){
  //tcselected in json format
  //      payload['collection'] = this.tcSelected.map((item: any) => item.value);

  payload.collection = this.tcSelected[0].value;
}
  //this.toastMessage.showmessage('Generating summary for the Testcase(s)...');
  this.tcgService.generatetestcase(payload).subscribe(
    (response: any) => {
      this.loaderService.hideLoader();
      console.log(response)
      if( response?.status == 'success')
      {
      //  this.testanswer = response.data;
      // this.downloadbtn=true;
        this.toastMessage.showSuccess(response?.message);
        this.testcasestatus();
      }
      else
      {
        this.toastMessage.showError(response?.message);
      }
    },
    (error:any) => {
      // Handle the error here
      this.loaderService.hideLoader();
      console.error(error);
      this.toastMessage.showError(error?.message);
    }
  );
}

testcasestatus(){
  this.loaderService.showLoader();
  this.tcgService.testcasestatus().subscribe(
    (response: any) => {
      console.log(response)
      if( response?.status == 'success')
      {
       // this.testanswer = response.data;
        this.toastMessage.showSuccess(response?.message);
        if(response.message === 'Test case generation is still in progress'){
          this.downloadbtn = false;
            //call this api after 10secs
            setTimeout(() => {
              this.testcasestatus();
            }, 10000);
        }
      else{
        this.downloadbtn = true;
        this.loaderService.hideLoader();

      }


      }
      else
      {
        this.loaderService.hideLoader();
        this.downloadbtn = true;

        this.toastMessage.showError(response?.message);
      }
    },
    (error:any) => {
      // Handle the error here
      this.downloadbtn = true;

      this.loaderService.hideLoader();
      console.error(error);
     // this.toastMessage.showError(error?.message);
    }
  );
}


    downloadList()
    {
      const httpOptions = {
        responseType: 'blob' as 'json' };

      const payload:any =
      {
        summary: this.testanswer,
        file_status: this.isFileNeeded.toString(),
        "process_name": this.processName,
      }

    if(this.tcSelected.length > 0){
      //tcselected in json format
      //      payload['collection'] = this.tcSelected.map((item: any) => item.value);

      payload.collection = this.tcSelected[0].value;
    }

          this.loaderService.showLoader();
          this.toastMessage.showmessage('File Downloading...');
           this.tcgService.fileDownload(payload).subscribe(
          (response: Blob) => {
          this.loaderService.hideLoader();
          const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          const timestamp = new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
        const fileNameParts = this.fileData?.name.split('_');
        const processName = fileNameParts && fileNameParts.length >= 3 ? fileNameParts[1] : ''; // Assuming process name is the second part

        const filename = `Testcase_${processName}_${timestamp}.xlsx`;

        // Set the filename for the download
        a.download = filename; // Set the desired file name
          document.body.appendChild(a);
          a.click();
          this.toastMessage.showSuccess('File Downloaded Successfully');
          // Cleanup
          this.downloadbtn = false;

          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
          this.resetFile();
        },
        (error) => {
          // Handle the error here
          this.loaderService.hideLoader();
          console.error(error);
          this.toastMessage.showError(error.message);
        }
      );
    }

    generatetestcase()
    {
      this.loaderService.showLoader();
// const payload:any ={
//   'file_type': this.filetype[0].value,
// }
const payload = new FormData();
// payload.append('ra_sheet', this.fileData);
payload.append('file_type', this.filetype[0].value);

      this.toastMessage.showmessage('Generating summary for the Testcase(s)...');
      this.tcgService.testcase1(payload).subscribe(
        (response: any) => {
          this.loaderService.hideLoader();
          console.log(response)
          if( response?.status == 'success')
          {
           this.testanswer = response.data;
            this.toastMessage.showSuccess(response?.message);
          }
          else
          {
            this.toastMessage.showError(response?.message);
          }
        },
        (error:any) => {
          // Handle the error here
          this.loaderService.hideLoader();
          console.error(error);
          this.toastMessage.showError(error?.message);
        }
      );
    }

      resetFile() {
        this.fileUploadedStatus = false;
        this.fileData = '';
        this.downloadStatus = false;
        this.targetSASUrl = '';
        this.filestatus1=true;
        localStorage.removeItem('f_tarnslate');
      }
}


