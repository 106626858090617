import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { CodeEnhance } from '../../../data/code-enhance-model';
import { ActivityAudit, FileModel } from '../../../data/app-model';
import { CodeEnhanceService } from '../../../Services/code-enhance.service';
import { ApiEndpoints, MessageTypeSnack, StatusCode } from '../../../data/global-vars';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedService } from '../../../Services/shared.service';
import JSZip from 'jszip';
import { marked } from 'marked';
import { MatDialog } from '@angular/material/dialog';
import { DisclaimerComponent } from '../../includes/popup/disclaimer/disclaimer.component';
import { RoundLoaderService } from 'src/app/codeGenYLite/Services/roundloader.service';
import { SopService } from 'src/app/shared/service/sop.service';
import {environment} from '../../../../../environments/environment';
import { Subscription, finalize, interval, takeUntil, takeWhile } from 'rxjs';
import { TimeouterrorComponent } from '../../includes/popup/timeouterror/timeouterror.component';

@Component({
    selector: 'app-code-enhancement',
    templateUrl: './code-enhancement.component.html',
    styleUrls: ['./code-enhancement.component.scss'],
    standalone: false
})
export class CodeEnhancementComponent implements OnInit {
  @ViewChild('enhancedWindow') enhancedWindow!: ElementRef;

    // validation error variables ------------
    isCodeLengthInvalid: boolean = false;
    isFileEmpty: boolean = false;
    isPageVisited: boolean = true;
    isFileSizeExceeded: boolean = false;
    isInvalidFileType: boolean = false;
    downloadLink2 : string = '';
    isDocAvailableHasError: boolean = false;
    docAvailable!: Subscription;
    isDocAvailable: boolean = false;
    tooltipText = 'Click to copy';
    activityAudit: ActivityAudit = {
    ohr_id: '',
    time_stamp: '',
    module: '',
  }

  filesArray= [];
  unmarkedContent: any;
  demo: any;

  constructor(private codeEnhanceService: CodeEnhanceService,
     private loaderService: RoundLoaderService,
    private sanitizer: DomSanitizer,
    private sharedService: SharedService,
    public dialog: MatDialog,
    private sopService: SopService,
  ) {

  }

  codeMirrorOptions: any = {
        lint: true,
        //theme: 'material'
      };
  codeMirrorOptionsreadonly: any = {
    //mode: "python",
    indentWithTabs: true,
    smartIndent: true,
    // readOnly : true,
    //lineNumbers: true,
    lineWrapping: true,
    extraKeys: { "Ctrl-Space": "autocomplete" },
    gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true,
    //theme: 'material'
  };
  selFiles: any;
  isCodeInputError: boolean = false;
  fileUrl: any;
  downloadFilename: string = '';
  codeEnhance: CodeEnhance = {
    code: '',
    improved_code: '',
    improvement_points: '',
    file_extension: '',
    code_document_file: '',
  }
  isCodeEnchanced: boolean = false;

  ngOnInit(): void {
      //throw new Error('Method not implemented.');
  }

  isCodeEnhanced = false;

//  new onfileselected added with validation --------------------------

onFileSelected(event: any) {
  const files: File[] = event.target.files;

  // Reset error flags
  this.isFileEmpty = false;
  this.isFileSizeExceeded = false;
  this.isInvalidFileType = false;

  if (!files || files.length === 0) {
    this.isFileEmpty = true;
    return;
  }

  const selectedFile = files[0];

  // Validate file size
  const maxSize = 1 * 1024 * 1024; // 1MB in bytes
  if (selectedFile.size > maxSize) {
    this.isFileSizeExceeded = true;
    return;
  }

  // Validate file type
  const allowedFileTypes = [".py", ".txt", ".vb", ".vba", ".doc", ".docx"];
  const fileType = selectedFile.name.substr(selectedFile.name.lastIndexOf(".")).toLowerCase();
  if (!allowedFileTypes.includes(fileType)) {
    this.isInvalidFileType = true;
    return;
  }

  // Read file content
  const fileReader = new FileReader();
  fileReader.onload = (e) => {
    const content = fileReader.result?.toString();
    this.codeEnhance.code = content as string;
  };

  fileReader.readAsText(selectedFile);
}

  // onFileSelected(event: any) {
  //   const files: File[] = event.target.files;
  //   this.selFiles = [];
  //   let name = 'file';
  //   if (files) {
  //     let selLength = this.selFiles.length;
  //     for (let i = 0; i < files.length; i++) {
  //       let newName = name + selLength++;
  //       let fileData: FileModel = { name: newName, file_data: files[i] };
  //       this.selFiles.push(fileData);
  //     }
  //     let fileReader = new FileReader();
  //     fileReader.onload = (e) => {
  //       let content = fileReader.result?.toString();
  //       this.codeEnhance.code = content as string;
  //     }
  //     fileReader.readAsText(files[0]);
  //   }
  //   else {
  //     this.sharedService.openSnackBar("No file chosen.", MessageTypeSnack.Error);
  //   }

    //this.codeEnhamceService.uploadFile(this.codeEnhance, this.selFiles).subscribe({
    //  next: (res) => {
    //    debugger;
    //    if (res.status && res.status_code == StatusCode.Success) {
    //      this.codeEnhance.code = res.result.file_txt;
    //    } else {
    //      if (res.statusCode == 500) {
    //        //
    //      } else if (res.status_code == 400) {
    //        //
    //      } else {
    //        //
    //      }
    //    }
    //  },
    //  error: (err) => {
    //    /*this.sharedService.openSnackBar(err, MessageTypeSnack.Error);*/
    //  },
    //});
  // }
  openDialog(): void {
    const dialogRef = this.dialog.open(DisclaimerComponent);
}

  onButtonClick() {
    this.isCodeEnchanced = true;
    this.storeLogs();
    this.loaderService.showLoader();
    this.isCodeInputError = false;
    this.isCodeLengthInvalid = false;
    this.codeEnhance.improved_code = '';
    this.codeEnhance.improvement_points = '';
    if (this.codeEnhance.code != null && this.codeEnhance.code.trim() != '') {
          // Validate code length
    const minLength = 25;
    const maxLength = 40000;
    const codeLength = this.codeEnhance.code.trim().length;

    if (codeLength < minLength || codeLength > maxLength) {
      this.isCodeLengthInvalid = true;
      this.loaderService.hideLoader();
      return;
    }
      this.codeEnhanceService.enhanceCode(this.codeEnhance.code).subscribe({
        next: (res) => {
          if (res.status && res.status_code == StatusCode.Success) {
            // console.log(res);
            // console.log(res.result);
            // this.codeEnhance.improved_code = res.result.improved_code;
            this.downloadLink2 = res.result.code_document_file;
            // console.log(this.downloadLink2);
            this.isDocAvailable = false;
            this.docAvailable = interval(environment.reqPoolingInterval)
            .pipe(
              takeWhile(() => !this.conditionMetO()),
              takeUntil(interval(environment.reqPoolingTimeout)),
              finalize(() => this.considerComplete())
          )
            .subscribe(() => this.checkDoc());
            
            // this.codeEnhance.improved_code = res.result.improved_code;
            // this.codeEnhance.improvement_points = res.result.improvement_points;
            // const unmarkedContent = res.result.improvement_points;
            // const markedcontent = marked.parse(unmarkedContent);
            // const finalContent = String(markedcontent);
            // console.log(finalContent);
            // this.codeEnhance.improvement_points = finalContent;
            // this.codeEnhance.file_extension = res.result.file_extension;
            // this.downloadFilename = 'file' + res.result.file_extension;
            // this.isCodeEnhanced = true;
            // this.loaderService.hideLoader();
                        // Scroll to the bottom after setting isGenerateCode to true
                        // this.scrollToBottom(this.enhancedWindow);
          } else {
            if (res.status_code == 500) {
              this.sharedService.openSnackBar(res.message, MessageTypeSnack.Error);
              this.loaderService.hideLoader();
            } else if (res.status_code == 400) {
              this.loaderService.hideLoader();
              this.sharedService.openSnackBar(res.message, MessageTypeSnack.Error);
            } else {
              this.loaderService.hideLoader();
              this.sharedService.openSnackBar(res.message, MessageTypeSnack.Error);
            }
          }
          this.activityLogging();
        },
        error: (err) => {
          this.sharedService.openSnackBar(err, MessageTypeSnack.Error);
          this.activityLogging();
        },
      });


    }
    else {
      this.isCodeInputError = true;
    }

  }

  checkDoc() {
    // console.log("fucniton called")
    this.sharedService.checkDocumentStatus(this.downloadLink2).subscribe({
      next: (res) => {
        // console.log("respond from enhance",res.result);
        if (res.status && res.status_code == StatusCode.Success) {
          // console.log("status check")
          this.isDocAvailable = res.result.is_document_available;
          // console.log("availability check")
          if (this.isDocAvailable) {
            // console.log("available condition")
            //this.docAvailable.unsubscribe();
            this.unmarkedContent = res.result.data_response.improvement_points;

            // this.demo = res.result.code_document;

            const markedcontent = marked.parse(this.unmarkedContent);
            const finalContent = String(markedcontent);
            // console.log("marked points", finalContent);
            this.codeEnhance.improved_code = res.result.data_response.improved_code;
            // console.log("improvedcode", this.codeEnhance.improved_code);
            this.codeEnhance.improvement_points = finalContent;
            // this.codeEnhance.file_extension = res.result.data_response.file_extension;
            // console.log("extension", this.codeEnhance.file_extension);
            // this.downloadFilename = 'file' + res.result.file_extension;
            this.downloadFilename = 'file' + res.result.data_response.file_extension;
            console.log(this.downloadFilename);
            this.codeEnhance.file_extension = res.result.data_response.file_extension;
            this.isCodeEnhanced = true;
                        // Scroll to the bottom after setting isGenerateCode to true
                        this.scrollToBottom(this.enhancedWindow);
            let filename = res.result.data_response.code_document_file;
            if (this.downloadLink2.toLowerCase().includes('devflow')) {
              this.codeEnhance.code_document_file =
                environment.apiURL + '/temp/' + this.downloadLink2;
            }
            else {
              this.codeEnhance.code_document_file =
                environment.apiURL + '/static/' + this.downloadLink2;
            }

            this.isCodeEnhanced = true;
            // Scroll to the bottom after setting isGenerateCode to true
            this.scrollToBottom(this.enhancedWindow);

            this.loaderService.hideLoader();

          }
        } else {
          this.isDocAvailable = true;
          //this.docAvailable.unsubscribe();
          this.isDocAvailableHasError = true;
          this.loaderService.hideLoader();
          this.sharedService.openSnackBar(res.message, MessageTypeSnack.Error);


        }
      },
      error: (err) => {
        //this.docAvailable.unsubscribe();
        this.isDocAvailable = true;
        this.isDocAvailableHasError = true;
        this.loaderService.hideLoader();
        this.sharedService.openSnackBar(err, MessageTypeSnack.Error);
      },
    });
    //return response && response.someProperty === 'someValue';
  }

  conditionMet(response: any): boolean {
    // Check if the response meets your condition
    return response && response.result.is_document_available;
  }

  conditionMetO(): boolean {
  // console.log(this.isDocAvailable);
    return this.isDocAvailable;

  }

  considerComplete() {
    this.loaderService.hideLoader();
    if (!this.isDocAvailable) {
      // this.sharedService.openSnackBar("Something went wrong. Please try again.", MessageTypeSnack.Error);
      const dialogRef = this.dialog.open(TimeouterrorComponent, {
        width: '500px'
      })

    }

  }

  storeLogs(){
    const payload = {
      'event_type' : 'codeGenYLite',
      'event_data' : {
        'feature':'code_enhancement',
      } 
    }
    this.sopService.logsStorage(payload).subscribe((res)=>{
      // console.log(res);
    })
  }

  activityLogging() {
    this.activityAudit.module = ApiEndpoints.EnhanceCustom;
    this.activityAudit.time_stamp = String(new Date());
    let userName = localStorage.getItem('okta-username');
    this.activityAudit.ohr_id = userName ? String(userName) : '';
    //this.sharedService.postActivityLog(this.activityAudit);
    this.sharedService.postActivityLog(this.activityAudit).subscribe({
      next: (res) => {
        if (res.status && res.status_code == StatusCode.Success) {

        } else {
          if (res.status_code == 500) {

          } else if (res.status_code == 400) {

          } else {

          }
        }
      },
      error: (err) => {

      },
    });
  }

  bindValue(textAreaVal: string) {
    this.codeEnhance.code = textAreaVal;
  }

  // copyText(): void {
  //   navigator.clipboard.writeText(this.codeEnhance.improved_code).catch(() => {
  //     this.sharedService.openSnackBar("Unable to copy text", MessageTypeSnack.Error);
  //   });
  // }

  copyText(): void {
    navigator.clipboard.writeText(this.codeEnhance.improved_code).then(() => {
      // Change tooltip text after successfully copying
      this.tooltipText = 'Copied!';

      // Set a timeout to change the tooltip back to "Click to Copy" after 5 seconds (5000 milliseconds)
      setTimeout(() => {
        this.tooltipText = 'Click to Copy';
      }, 5000);
    }).catch(() => {
      this.sharedService.openSnackBar('Unable to copy text', MessageTypeSnack.Error);
    });
  }



  downloadFile(): void {
    let data = this.codeEnhance.improved_code;
    /*data += this.codeEnhance.code;*/
    //let encodedData = btoa(data)
    //var json = atob(encodedData);
    //const blob = this.base64toBlob(json);

    const blob = new Blob([data], { type: 'application/octet-stream' });

    //this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = this.downloadFilename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);


  }

  base64toBlob(byteString: string) {
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: "octet/stream" });
  }

    // scroll to box code goes here.............
    private scrollToBottom(enhancedWindow: ElementRef): void {
      if (enhancedWindow && enhancedWindow.nativeElement) {
        const enhancedWindowElement = enhancedWindow.nativeElement;
        enhancedWindowElement.scrollTop = enhancedWindowElement.scrollHeight;
      }
  }

  setEditorContent(event: any) {
    // console.log(event, typeof event);
    //console.log(this.content);
  }


  downloadFile_0() {
    const files = [
      { name: 'file1.js', content: this.codeEnhance.improved_code },
      { name: 'file2.js', content: this.codeEnhance.improved_code },
      // Add more files as needed
    ];

    this.downloadFilesAsZip(files);
  }

  downloadFilesAsZip(files: { name: string, content: string }[]) {
    const zip = new JSZip();

    files.forEach(file => {
      zip.file(file.name, file.content);
    });

    zip.generateAsync({ type: 'blob' }).then((content: BlobPart) => {
      const zipFile = new Blob([content], { type: 'application/zip' });
      const zipUrl = URL.createObjectURL(zipFile);

      const link = document.createElement('a');
      link.href = zipUrl;
      link.download = 'files.zip';
      document.body.appendChild(link);
      link.click();

      setTimeout(() => {
        document.body.removeChild(link);
        URL.revokeObjectURL(zipUrl);
      }, 100);
    });
  }

}
