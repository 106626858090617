import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AiAgentService {

  // accessToken = localStorage.getItem('token');

  private headers = {
    'Accept': 'application/json',
    'User-Agent': 'Mozilla/5.0 (iPhone; CPU iPhone OS 14_7_1 like Mac OS X) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.114 Mobile Safari/537.36',

  };

  constructor() { }

  getWelcome(): Promise<any> {
    return fetch('http://65.0.27.103:5000/', {
      method: 'GET',
      headers: this.headers
    }).then(response => response.json());
  }

  sendQuery(apiUrl: string, body: any): Promise<any> {
    const accessToken = localStorage.getItem('token');
    return fetch(apiUrl, {
      method: 'POST',
      headers: {
        ...this.headers,
        'User-Agent': 'Mozilla/5.0 (iPhone; CPU iPhone OS 14_7_1 like Mac OS X) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.114 Mobile Safari/537.36',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(body)
    }).then(response => response.json());
  }
}
