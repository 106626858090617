import { Component,  Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-build-your-project',
    templateUrl: './build-your-project.component.html',
    styleUrls: ['./build-your-project.component.scss'],
    standalone: false
})
export class BuildYourProjectComponent {

 tabsArray: string[] = ['BRD', 'SDD', 'Generate Code'];
  activatedTab:number = 0;

  constructor(){}

  setTab(index:any){
  this.activatedTab = index;
  }
  switchTab(index: any) {
    this.activatedTab = index;
  }


}
