<!-- <app-top-bar></app-top-bar> -->

<div class="fine-tune-model-container" style="padding: 5px; background-color:#ffffff;overflow-y: scroll;">
  <div class="chat-result-display-section">
    <div #messageContainer class="_react_scroll_to_bottom">
      <div class="chat-messages">
        <!-- <div class="gpt-message">
          <fa-icon [icon]="redditIcon" class="redditcss" size="2x"></fa-icon>
          <div class="message-content">
            Hello, I am the AI-assisted code development assistant.
            <br /><br />
            Now, please tell me the requirements that need to be completed,
            describing them in as much detail as possible.
          </div>
        </div> -->


        <div class="user-message" *ngIf="userReq">
          <div class="message-content" [innerHTML]="UserDiv"></div>
          <fa-icon
            class="odnoklassnikicss"
            [icon]="odnoklassniki"
            size="2x"
          ></fa-icon>
        </div>

        <div class="gpt-message" *ngIf="questionsArray.length > 0">
          <fa-icon [icon]="redditIcon" class="redditcss" size="2x"></fa-icon>
          <div class="message-content">
            <h4>
              <strong>
                In order to better understand the requirements, I also need to
                confirm the following questions:
              </strong>
            </h4>
            <!-- <table class="table w-100 table-bordered  pl-5 align-middle">
              <thead>
                <tr>
                  <th scope="col"><strong>Question</strong></th>
                  <th scope="col"><strong>Answer</strong></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let ques of questionsArray; let i = index">
                  <td>
                    <span>{{ ques.question }}</span
                    >{{ ques.reasoning }}
                  </td>
                  <td>
                    <div class="ui fluid icon input">
                      <textarea
                        type="text"
                         style="resize:both!important; border-radius:0px;"
                        name="clarify_answer"
                        (change)="quesAnsEdit($any($event.target).value, i)"
                        value="{{ ques.answer_sample }}"

                        placeholder="Answer"
                        autocomplete="off"
                      ></textarea>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table> -->
            <div class="user-message" >
              <div class="message-content">
                <p *ngFor="let ques of questionsArray; let i = index">
                  <b class="fw-500">Question {{ i + 1 }}: </b><span>{{ ques.question }}</span
                    >{{ ques.reasoning }}<br />
                  <b class="fw-500">Answer:</b><br>
                    <textarea
                  type="text"
                   style="resize:both!important; border-radius:0px; width:520px;"
                  name="clarify_answer"
                  (change)="quesAnsEdit($any($event.target).value, i)"
                  value="{{ ques.answer_sample }}"

                  placeholder="Answer"
                  autocomplete="off"
                ></textarea>
                </p>
              </div>
              <fa-icon
                class="odnoklassnikicss"
                [icon]="odnoklassniki"
                size="2x"
              ></fa-icon>
            </div>



            <button
              class="btn btn-primary"
              (click)="QuesSubmit()"
              style="background-color: #1BA641 !important; color:#ffffff !important;border-radius:0px"

            >
              Submit
            </button>
          </div>
        </div>

        <div class="user-message" *ngIf="userQuesReq">
          <div class="message-content">
            <p *ngFor="let arr of UserQuesDiv; let i = index">
              <b class="fw-500">Question {{ i + 1 }}: </b>{{ arr.question
              }}<br />
              <b class="fw-500">Answer:</b> {{ arr.answer_sample }}
            </p>
          </div>
          <fa-icon
            class="odnoklassnikicss"
            [icon]="odnoklassniki"
            size="2x"
          ></fa-icon>
        </div>

        <div class="gpt-message" *ngIf="DevReqText != ''">
          <fa-icon [icon]="redditIcon" class="redditcss" size="2x"></fa-icon>
          <div class="message-content">
            <h4>
              <strong>
                The requirements document has been generated, and the BRD
                document will be generated after confirmation:
              </strong>
            </h4>
            <div
              [innerText]="DevReqText"
              [contentEditable]="DevReqTextEdit"
              id="devReqTxt"
              [ngClass]="DevReqTextEdit == true ? 'devReqTxt' : ''"
            ></div>
            <br />
            <button *ngIf="generatebtn"
              class="BRD-btn"
              (click)="rastatus = false;generatebrd()">
            Generate BRD
            </button>

            <!-- <button *ngIf="rageneratebtn"
            style="margin-left: 2px;"
            class="BRD-btn"
            (click)="rastatus = true;generatebrd()">
          Generate RA
          </button> -->

            <button *ngIf="downloadbtn "
            class="BRD-btn"
            style="margin-left: 2px;"
            (click)="rastatus = false;download()">
           Download BRD

          </button>
          <!-- <button *ngIf="radownloadbtn"
          style="margin-left: 2px;"

          class="BRD-btn"
          (click)="rastatus = true;download()">
          Download RA

        </button> -->

            <button

              class="BRD-btn"
              style="background-color: #10548C !important; color:#ffffff !important; margin-left: 2px;"
              (click)="DevReqEdit()"

            >
              Edit
            </button>
            <!-- <button
              class="ui secondary button"
              style="background-color: #ffffff !important; margin-left: 2px;"
              (click)="DownloadUserRequirement()"

            >
              <fa-icon [icon]="faDownload"></fa-icon>
              Download Document
            </button> -->
          </div>
        </div>
<!--

 -->
        <div class="user-message" *ngIf="DevReqDataEnable">
          <div class="message-content" [innerHTML]="DevUserReqData"></div>
          <fa-icon
            class="odnoklassnikicss"
            [icon]="odnoklassniki"
            size="2x"
          ></fa-icon>
        </div>







      <div *ngIf="isLoading" class="LoadingDiv">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Generating answer, please wait....
      </div>
      <div *ngIf="isLoading" class="overlay">
        <div class="spinner"></div>
      </div>
    </div>
  </div>

  <div style="bottom:0;" style="background-color:transparent" class="seacrh-bar-container"  [hidden]="promptSearchHidden">
    <!-- <div class="search-bar" style="border-radius:0px" [ngClass]="promptError == true ? 'error' : ''">
      <textarea
        class="search-bar-input"
        type="text"
             style="resize:both!important; border-radius:0px;"
        placeholder="Detail your requirement here"
        [(ngModel)]="formData.userPrompt"
        [value]="formData.userPrompt"
      ></textarea>
    </div>
    <button style="border-radius: 0px;" class="search-btn" (click)="SubmitClick()">
      <fa-icon [icon]="faPaperPlane"></fa-icon> Send
    </button> -->
    <!-- <button title="Download File" class="download-btn" (click)="generateAndDownloadLastPDF()">
        <fa-icon [icon]="faDownload"></fa-icon>
      </button> -->
      <div class="code-box" style="background-color: white;">
        <div class="custom-container">
          <p class="code-enhance-header">This module efficiently generates Business Requirement Documents 
             simplifying project initiation. It saves time and minimizes miscommunication,
             boosting productivity from the project kick-off.
             The generated output includes some empty fields that need to be manually filled by the user.</p>
            <button class="BRD-btn" (click)="resetinput()" >Refresh<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16">
              <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9"/>
              <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z"/>
            </svg></button>
          <p class="code-enhance-desc"></p>
          <div class="form-box">
            <form>
              <div class="row gx-2 gy-2">
                <div class="col">
                <div class="form-group">
    <label for="exampleInputEmail1" class="astreik">Project Name:</label>
    <textarea [(ngModel)]="processName" [ngModelOptions]="{ standalone: true }"
        class="form-control resizer-none" rows="4" (change)="storeinput()"
        placeholder="Please provide a concise and descriptive name for the process that accurately reflects its purpose and function."
        name="processName" #processNameField="ngModel" required minlength="5" maxlength="255" ></textarea>
    <div class="text-danger">
        <!-- <small *ngIf="processNameField.errors?.required">Process name is required.</small> -->
        <small *ngIf="processNameField.errors?.['minlength']">Project Name cannot be less than 5 and cannot be greater than 255.</small>
                        <!-- <small *ngIf="processName.length >= 5">
                          {{ 50 - processName.length }} characters left (max is 50).
                        </small> -->
      </div>
</div>


                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="exampleFormControlSelect1" >Preferred Tech Stack
:</label>
                    <mat-select style="background-color:#ffffff; height:50px; margin-top:5px" [(ngModel)]="codelang" [ngModelOptions]="{ standalone: true }" (ngModelChange)="storeinput()" placeholder="Please specify the Preferred Tech Stack
 for the project." multiple>
                      <mat-option *ngFor="let language of codingLanguages" [value]="language">{{ language }}</mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>

              <div class="row gx-2 gy-2" style="display:none;">
                <div class="col">
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1" class="astreik">Project description:</label>
                    <textarea [(ngModel)]="projDesc" (change)="storeinput()" [ngModelOptions]="{ standalone: true }"
                    class="form-control resizer-none"
                    #projDescField="ngModel" required minlength="25"
                    rows="5" placeholder="In this section, provide a comprehensive overview of the entire project. Include the project’s goals, objectives, and any relevant background information. Describe the context in which the project will be executed, detailing its significance and potential impact on the organization."></textarea>
                    <div class="text-danger">
                      <!-- <small>Project description is required.</small> -->
                      <small *ngIf="projDescField.errors?.['minlength']">Project description can not be less than 25.</small>
                      <!-- <small *ngIf="projDescField.errors?.['maxlength']">Project description can not be greater than 7500.</small> -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="row gx-2 gy-2">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1" class="astreik">Project Objective:</label>
                    <textarea (change)="storeinput()" [(ngModel)]="automationObj" [ngModelOptions]="{ standalone: true }"
                     class="form-control resizer-none"
                     #automationObjField="ngModel" required minlength="25"
                     rows="5" placeholder="Outline the specific objectives and goals of automating the identified process. Clearly define what you aim to achieve through automation, such as increased efficiency, reduced manual effort, or improved accuracy. Be explicit about the desired outcomes to ensure a focused and effective automation strategy."></textarea>
                    <div class="text-danger">
                      <!-- <small>Automation objective is required.</small> -->
                      <small *ngIf="automationObjField.errors?.['minlength']">Automation objective can not be less than 25.</small>
                      <!-- <small *ngIf="automationObjField.errors?.['maxlength']">Automation objective can not be greater than 7500.</small> -->
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1" class="astreik">Project Scope:</label>
                    <textarea (change)="storeinput()" [(ngModel)]="projScope" [ngModelOptions]="{ standalone: true }"
                    class="form-control resizer-none"
                    #projScopeField="ngModel" required minlength="5"
                    rows="5" placeholder="Define the boundaries and limitations of the project. Clearly state what is included within the Project Scope and what is not. Consider factors such as time constraints, resource availability, and any external dependencies. Providing a well-defined Project Scope will help manage expectations and ensure a focused and successful project execution."></textarea>
                    <div class="text-danger">
                      <small *ngIf="projScopeField.errors?.['minlength']">Project Scope cannot be less than 5.</small>
                      <!-- <small *ngIf="projScopeField.errors?.['maxlength']">Project Scope can not be greater than 5000.</small> -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="row gx-2 gy-2">
                <div class="col">
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1" class="astreik">Present Process:</label>
                    <textarea (change)="storeinput()" [(ngModel)]="presentProcess" [ngModelOptions]="{ standalone: true }"
                    class="form-control resizer-none"
                    #presentProcessField="ngModel" required minlength="5"
                    rows="5" placeholder="Describe the current manual or automated process that you intend to improve or replace. Include key steps, stakeholders involved, and any pain points or inefficiencies you have observed. This will provide a baseline understanding of the existing process and the areas that require enhancement."></textarea>
                    <div class="text-danger">
                      <!-- <small>Present Process is required.</small> -->
                      <small *ngIf="presentProcessField.errors?.['minlength']">Present Process can not be less than 5.</small>
                      <!-- <small *ngIf="presentProcessField.errors?.['maxlength']">Present Process can not be greater than 2500.</small> -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="row gx-2 gy-2">
                <div class="col">
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1" class="astreik">Proposed Process:</label>
                    <textarea (change)="storeinput()" [(ngModel)]="proposedProcess" [ngModelOptions]="{ standalone: true }"
                    class="form-control resizer-none"
                    #proposedProcessField="ngModel" required minlength="5"
                    rows="5" placeholder="Outline the envisioned automated process. Specify the steps involved, the technologies or tools to be used, and how the Proposed Process differs from the current one. Emphasize the improvements and efficiencies expected, ensuring a clear understanding of the changes you plan to implement."></textarea>
                    <div class="text-danger">
                      <!-- <small>Proposed Process is required.</small> -->
                      <small *ngIf="proposedProcessField.errors?.['minlength']">Proposed Process can not be less than 5.</small>
                      <!-- <small *ngIf="proposedProcessField.errors?.['maxlength']">Proposed Process can not be greater than 12500.</small> -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="btn-submit">
    <button type="button"
        [disabled]="!(processName && processName.length >= 5 ) ||
                    
                    !(automationObj && automationObj.length >= 25 ) ||
                    !(projScope && projScope.length >= 5 ) ||
                    !(presentProcess && presentProcess.length >= 5 ) ||
                    !(proposedProcess && proposedProcess.length >= 5 )"
        (click)="SubmitClick()"
        class="BRD-btn">
        Submit
    </button>
</div>

            </form>
          </div>
        </div>
      </div>

  </div>
</div>
</div>
<!-- modal bootstrap -->
<!-- Button trigger modal -->
<button style="display:none;" id="btnbrd" type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1> -->
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="popup-content">
          <div class="success">
        <div class="img-header text-center" >
          <img src="../../../../../assets/images/done.png" alt="">
        </div>
        <div class="content text-center">
        <!-- <p class="awesome">Awesome !</p> -->
        <p class="text-content">
          The BRD document is created and now ready for download
        </p>

      </div>
      </div>
      </div>
      </div>
      <div class="modal-footer">

        <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
        <button type="button" (click)="download()" class="btn btn-primary">Download BRD</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isspinner" class="overlay">
  <div class="spinner"></div>
</div>
