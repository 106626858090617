<!-- Header -->  


<app-cheader></app-cheader> 
<app-carousel [carouselItems]="carouselData"></app-carousel>

<!-- Main Content -->  
<main class="main-content">  
  <div class="container">
    <!-- Left Container: Cards -->
    <div class="left-container">
      <div class="card" *ngFor="let card of cards">
        <h3>
          <img [src]="card.icon" [alt]="card.title" />
          {{ card.title }}
        </h3>
        <p title="{{ card.description }}">  
          {{ card.description}}  

          <!-- {{ card.description | slice:0:200 }}{{ card.description.length > 200 ? '...' : '' }}   -->
        </p>  
        <button [routerLink]="card.route" [ngClass]="{ 'highlight-button': card.highlight }">
          {{ card.buttonText }} <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
          </svg>
        </button>
      </div>
    </div>
  
    <!-- Right Container: Unique Sections -->
    <div class="right-container">
      <div *ngFor="let item of rightContent">  
        <!-- Section 1: Text and Highlight -->  
        <ng-container *ngIf="item.type === 'highlight'">  
          <div class="highlight-section">  
            <h3>{{ item.title }}</h3>  
            <ul class="custom-list1" style="padding: 0px;" >  
              <li *ngFor="let desc of item.description">  
                {{ desc }}  
              </li>  
            </ul>  
          </div>  
        </ng-container>  
        
        <!-- Section 2: Video or Image -->  
        <ng-container *ngIf="item.type === 'media'">  
          <div style="display:none;" class="media-section">  
            <h3>{{ item.title }}</h3>  
            <!-- Render Video if Provided -->  
            <video  
              *ngIf="item.videoUrl&& !item.imageUrl"  
              [src]="item.videoUrl"  
              controls  
              width="100%"  
              height="auto"  
            ></video>  
            <!-- Render Image if Provided -->  
            <img  
              *ngIf="!item.videoUrl && item.imageUrl"  
              [src]="item.imageUrl"  
              [alt]="item.title"  
            />  
          </div>  
        </ng-container>  
        
        <!-- Section 3: List Content -->  
        <ng-container *ngIf="item.type === 'list'">  
          <div class="list-section">  
            <h3>{{ item.title }}</h3>  
            <ul class="custom-list">  
              <li *ngFor="let listItem of item.list">{{ listItem }}</li>  
            </ul> 
            <br>
            <h3>{{ item.title1 }}</h3>  
            <ul class="custom-list">  
              <li *ngFor="let listItem of item.list1">{{ listItem }}</li>  
            </ul>  
          </div>  
        </ng-container>  
      </div>  
    </div>
  </div>
   
</main>  
  
<!-- Footer -->  
<!-- <footer class="home-footer">  
  <div class="relentless">  
    <div class="container">  
      <div class="logo">  
        <img src="../../../assets/images/genpact-vertical-logo.svg" alt="Genpact Logo" />  
      </div>  
      <div class="relentless-font-style">  
        <p>  
          The relentless pursuit of a world that works  
          <span>better for </span><span>people</span>  
        </p>  
        <img src="../../../assets/images/underline-white.svg" alt="Underline" />  
      </div>  
    </div>  
  </div>  
  <div class="footer">  
    <div class="container">  
      <p>©2024 All rights reserved - Genpact.</p>  
      <div class="left-social">  
        <p>Follow us on social media:</p>  
        <ul class="social">  
          <li><a href="https://www.facebook.com/ProudToBeGenpact/" target="_blank"><i class="fab fa-facebook-square"></i></a></li>  
          <li><a href="https://twitter.com/genpact" target="_blank"><img src="../../../../assets/images/newTwitterIcon.png" alt="Twitter"></a></li>  
          <li><a href="https://www.linkedin.com/company/210064" target="_blank"><i class="fab fa-linkedin"></i></a></li>  
          <li><a href="https://www.youtube.com/genpactltd" target="_blank"><i class="fab fa-youtube"></i></a></li>  
        </ul>  
      </div>  
    </div>  
  </div>  
</footer>   -->