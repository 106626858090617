import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ToastMessageService } from './shared/service/toast-message.service';
import { Location } from '@angular/common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private toastMessage: ToastMessageService,
    private location: Location
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let token: any = localStorage.getItem('token');

    // add bearer before specific token  starts
    let bearerArray = ['translate_upload', 'translate_start', 'translate_status',
      'chat_history', 'delete_p', 'old_chat', 'chat_update', 'new_chat',
      'upload_video', 'status', 'transcript', 'uploading', 'submitting', 'image-generate',
      'upload-jd', 'upload-cv', 'calculate-match-jd', 'calculate-match-cv', 'submit-match',
      'recent_bing', 'recent_response', 'sentiments-analysis', 'add-doc', 'del-doc-list', 'doc-list',
      'upload-soc-files', 'excel-upload', 'excel-download', 'queryVS-status',
      'accounts', 'meeting-notes', 'meeting-notes-status', 'buying-centers', 'clarify_requirements', 'workspace-zip', 'codeGenYLite',
      'check_subscription', 'set_subscription', 'close_application', 'log_activity', 'get_gpt_response', 'reset_chat', 'upload_file', 'enhance', 'get_documentation', 'download_code_documentation', 'generate_brd', 'get_sdd', 'generate_code',
      'tcgupload', 'tcgdownload', 'upload-rasheet', 'generate-testcase', 'generate-rasummary', 'check_document_status', 'extracttext', 'clarify_api_status', 'generate_initial_code_status', 'generate_geny_brd', 'brd_document_status',
      'generate_geny_sdd', 'sdd_document_status', 'upload-sdd', 'store_logs', 'get_gpt_answer', 'get_documentation', 'get_documentation_status',
      'get_enhancement', 'get_enhancement_status', 'get_documentation_download', 'save-report', 'get-report',
      'chatbot', 'check_domain', 'code_adjust', 'code_adjust_status', 'generate_geny_ra_in', 'ra_document_status_in', 'generate_user_story', 'userstory_status', 'uploadfile_sharepoint_in',
      'uploader', 'byod-status', 'getanswer', 'testcase_status', 'insert_brd_logs', 'show_brd_logs', 'change_brd_status', 'get_conversion', 'get_conversion_status',
      'code_analyze', 'code_analyze_status', 'generate_geny_ra', 'generate_geny_ra_status', 'ra_document_status', 'insert_ra_logs', 'show_ra_logs', 'show_ra_logs_file', 'change_ra_status', 'ra_document_preview',
      'brd_document_preview', 'sdd_document_preview', 'ra_document_preview_in', 'insert_feedback_logs', 'userstory_preview', 'testcase_preview',
      'code_enh_adj_status', 'code_enh_adj', 'image-verify', 'multi-agent',
      'code_enh_adj_status', 'code_enh_adj', 'insert_assist_logs', 'update_assist_records', 'show_assist_details', 'extract_file_content',
      'delete_assist_records', 'display-function', 'welcome', 'research/search', 'code_security', 'code_security_status', 'search', 'ask-genpact'



    ];
    // Get the API URL endpoint
    let apiEndpoint = request.url.split('/');
    let finalUrl = apiEndpoint[apiEndpoint?.length - 1].split('?')[0];


    if (bearerArray.includes(finalUrl)) {
      token = 'Bearer ' + token;
    }
    // add bearer before specific token  end

    request = request.clone({
      headers: request.headers.set('authorization', token)
    })
    return next.handle(request).pipe(
      catchError(errorData => {
        // console.log(errorData)
        if (errorData.status === 409 ||
          errorData.status === 403
          //|| errorData.status === 400
          || errorData.status === 422) {

          this.router.navigate(['/unauthorized']);
          return throwError(errorData)

        } else if (errorData.status === 401) {
          const currentUrl = this.location.path();
          localStorage.removeItem('token');
          localStorage.setItem('p_redirect', currentUrl)
          this.router.navigate(['/']);
          window.location.reload();
        }
        else if (errorData.status === 422) {

        } else if (errorData.status === 404) {

        }
        else if (errorData.status === 429) {
          console.log('hello', errorData?.error?.message)
          this.toastMessage.showError(errorData?.error?.message);
        }
        return throwError(errorData)
      })

    )
  }
}


export const AuthInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true
}
