import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-cgpopup',
  standalone: false,

  templateUrl: './cgpopup.component.html',
  styleUrl: './cgpopup.component.scss'
})
export class CgpopupComponent {

  @Input() isPageVisited: boolean = false;
  @Output() isPageVisitedChange = new EventEmitter<boolean>();

  isCheckboxChecked: boolean = false;
  isAcceptButtonActivated: boolean = false;
  checkstatus:boolean = false;

  constructor(public dialogRef: MatDialogRef<CgpopupComponent>) { }

  ngOnInit(){
     // this.getSubscriptionStatus();
     const popup = localStorage.getItem('policypop') ? localStorage.getItem('policypop'):null;
    if(popup == 'true'){
      this.checkstatus = true;
    }
    else{
      this.checkstatus = false;
    }
  }


  onCloseClick(): void {
    this.dialogRef.close();
  }

  onAcceptClick() {
    this.onCloseClick();
    this.isPageVisited = false;
    this.isPageVisitedChange.emit(this.isPageVisited);
  }

  onCheckboxChange() {
    this.isAcceptButtonActivated = this.isCheckboxChecked;
  }
  statuscheck(){
    console.log(this.checkstatus);
    // this.checkstatus = !this.checkstatus;
    localStorage.setItem('policypop',this.checkstatus.toString())
  }

}
