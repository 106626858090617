<div class="row">
    <div *ngFor="let feature of features" class="col-md-6 mb-4">
      <div class="card h-100 shadow-sm">
        <!-- <img style="height:32px;width:32px;"  [src]="feature.image" class="card-img-top" alt="{{ feature.title }}"> -->
        <div class="card-body">
          <h5 class="card-title">{{ feature.title }}</h5>
          <p class="card-text">{{ feature.description }}</p>
          <a [href]="feature.link" class="btn btn-primary">Learn More</a>
        </div>
      </div>
    </div>
  </div>
  