import { Component, EventEmitter, Output, OnInit } from '@angular/core';

@Component({
  selector: 'app-csidebar',
  standalone: false,
  templateUrl: './csidebar.component.html',
  styleUrl: './csidebar.component.scss'
})
export class CsidebarComponent implements OnInit { // Implement OnInit
  @Output() tabSelected = new EventEmitter<{ label: string; value: string }>();

  tabs = [
    { label: 'Crews', value: 'crews' },
    { label: 'Tools', value: 'tools' },
    { label: 'Agents', value: 'agents' },
    { label: 'Tasks', value: 'tasks' },
    { label: 'Kickoff!', value: 'kickoff' },
    { label: 'Results', value: 'results' },
    { label: 'Import/export', value: 'import-export' },
    { label: 'Public Crew', value: 'public-crew' }
  ];

  activeTab: { label: string; value: string } | null = null; // Track the active tab

  ngOnInit() {
    this.activeTab = this.tabs[0]; // Set "Crews" as the initial active tab
    this.tabSelected.emit(this.activeTab); // Emit the initial selection
  }

  selectTab(tab: { label: string; value: string }) {
    this.activeTab = tab;
    this.tabSelected.emit(tab);
  }
}