import { Component, ViewChild, ElementRef, OnInit, ChangeDetectorRef, TemplateRef } from '@angular/core';
//C:\Gen-AI repo\kon-verse\Konverse\src\app\codeGenYLite\Services
import { SharedService } from 'src/app/codeGenYLite/Services/shared.service';
import { DevTalkService } from 'src/app/codeGenYLite/Services/dev-talk.service';
import {
  ApiEndpoints,
  MessageTypeSnack,
  StatusCode,
} from 'src/app/codeGenYLite/data/global-vars';
import { ActivityAudit } from 'src/app/codeGenYLite/data/app-model';
import {
  FormControl,
  Validators,
  FormBuilder,
  FormGroup,
  FormsModule,
} from '@angular/forms';
import { detect, languages, LANG } from 'program-language-detector';
//C:\Gen-AI repo\kon-verse\Konverse\src\app\codeGenYLite\components\includes
import { SettingsComponent } from 'src/app/codeGenYLite/components/includes/popup/settings/settings.component';
import { MatDialog } from '@angular/material/dialog';
import { DisclaimerComponent } from 'src/app/codeGenYLite/components/includes/popup/disclaimer/disclaimer.component';
import { SopService } from 'src/app/shared/service/sop.service';
import { ToastMessageService } from '../shared/service/toast-message.service';
// import { CheaderComponent } from '../cheader/cheader.component';

// Import all major modes using a wildcard import
import 'codemirror/mode/meta'; // Import the meta mode to enable auto-loading of other modes
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription, timer } from 'rxjs';
import { marked } from 'marked';
import { LoaderService } from '../cgl/aichat-services/service/loader.service';
import { CommonModule, KeyValue } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import * as PizZip from 'pizzip';
import * as Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Paragraph, TextRun, Packer } from 'docx';
// import 'codemirror/mode/*';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { MarkdownModule } from 'ngx-markdown';
// import { LoaderService } from '../cgl/aichat-services/service/loader.service';


interface DevTalkReq {
  user_prompt: string,
  reset_context: boolean,
  prompt_generator: boolean,
  file_status: boolean,
  file: any
}

// Define an interface for prompt categories  
interface PromptCategory {
  category: string;
  prompts: string[];
}

@Component({
  selector: 'app-agentic-playground',
  imports: [CommonModule, FormsModule, MarkdownModule],
  standalone: true,
  templateUrl: './agentic-playground.component.html',
  styleUrl: './agentic-playground.component.scss'
})
export class AgenticPlaygroundComponent implements OnInit {

  @ViewChild('sessionModal') sessionModal: any;

  sessionName: string = '';
  selectedEndpoint: string = '';
  endpoints: string[] = ['Endpoint 1', 'Endpoint 2', 'Endpoint 3'];

  @ViewChild('fileInput') fileInput: any;

  showCustomLoader: boolean = false;
  // dynamicForm: FormGroup;
  originalHeight = '35px';
  isPageVisited: boolean = true;
  code: string = '';
  counter: number = 1;
  tooltipText: string = 'Click to Copy';
  lastMessageFromUser: boolean = true;
  activityAudit: ActivityAudit = {
    ohr_id: '',
    time_stamp: '',
    module: '',
  };
  ischat: boolean = false;

  devTalkReq: DevTalkReq = {
    user_prompt: '',
    reset_context: true,
    prompt_generator: false,
    file_status: false,
    file: '',
  }
  isDevTalk: boolean = false;
  botResponseLoading: boolean = false;
  // Other properties as needed...  
  isCopied: boolean = false;

  chatMessages: any[] = [];
  active_chatId: any;
  histroyDeleteId: any;
  chatHistoryList: any = [];
  isHistoryOpen = false;
  //chathistorylist: any = {}; // Your chat history data  
  groupedHistories: any = {};
  modalRef?: BsModalRef;
  deleteid!: Event;

  private chatSubscription: Subscription | null = null;
  private isGenerating: boolean = false;
  promptgen: boolean = false;
  isspinner: boolean = false;
options: any[] =[ 'Get Endpoint' , 'Docker' , 'AWS' , 'Deploy on Scout']

  promptCategories: PromptCategory[] = [
    {
      category: 'Developers',
      prompts: [
        'Write a function in [Input Language] to carry out [User Input].',
        'Optimize the following algorithm in [Input Language] for better performance: [User Input].',
        'Create an application in [Input Language] that fetches data from an API endpoint provided by the user.',
        'Implement multithreading in [Input Language] to perform parallel processing tasks described by the user.'
      ]
    },
    {
      category: 'Testers',
      prompts: [
        'Write unit tests in [Input Language] for the given function: [User Input].',
        'Create an automated test script in [Input Language] that validates functionality described by the user.',
        'Design a performance test plan using tools available for applications written in [Input Language].',
        'Document and report bugs found during testing with detailed steps to reproduce.'
      ]
    },
    {
      category: 'Business Analysts',
      prompts: [
        'Draft user stories based on client requirements provided.',
        // 'Conduct stakeholder interviews and document functional requirements.',  
        // 'Create process flow diagrams illustrating current vs proposed workflows.',  
        'Generate reports analyzing key metrics from recent user data.'
      ]
    },
    {
      category: 'System Administrators',
      prompts: [
        'Write scripts to automate server configuration tasks.',
        'Create policies for managing user access control within systems.',
        'Design backup strategies ensuring data integrity and availability.',
        'Implement network security protocols safeguarding against unauthorized access.'
      ]
    },
    {
      category: 'Database Administrators',
      prompts: [
        'Design a normalized database schema based on provided requirements.',
        'Optimize complex SQL queries to improve performance.',
        'Develop a plan for migrating data from legacy systems.',
        'Establish procedures for regular database backups.'
      ]
    },
    {
      category: 'General',
      prompts: [
        'Help me to generate Python code',
        'Help me to generate Basic HTML template',
        'Generate unit tests for the following code snippet <code snippet>',
        'Suggest refactoring options for this method to improve code readability and maintainability <code snippet>',
        'Translate this Python code to Java <python code>',
        'Explain the concept of <specific design pattern/architectural concept> and provide an example of how it can be applied in my project.',
        'Find and fix potential bugs in this code <code snippet>'
      ]
    }
  ];

  selectedView: 'playground' | 'deploy' = 'playground'; // Default to 'playground'

  selectView(view: 'playground' | 'deploy') {
    this.selectedView = view;
  }

  viewMore: boolean = false
  // Property to hold the first three prompts from the 'General' category  
  mainPrompts: string[] = [];
  isLoading: boolean = true;
  // Property to store the selected category  
  selectedCategory: PromptCategory | null = null;

  extension: any = `only 'ts', 'html', 'css', 'scss', 'json', 'txt', 'js', 'cs', 'py', 'java', 'xml', 'sql', 'php', 'ps1', 'rb', 'tf', 'vue', 'yaml', 'yml' are allowed.`


  allowedFileTypes1: string[] = [
    '.ts', '.html', '.css', '.scss', '.json', '.txt', '.js', '.cs', '.py',
    '.java', '.xml', '.sql', '.php', '.ps1', '.rb', '.tf', '.vue', '.yaml', '.yml'
  ];

  uploadedFiles: File[] = [];
  filecontent: any;

  // Allowed file types
  allowedFileTypes: string[] = [
    'application/typescript',    // 'ts'  
    'text/html',                 // 'html'  
    'text/css',                  // 'css'  
    'text/x-scss',               // 'scss'  
    'application/json',          // 'json'  
    'text/plain',                // 'txt'  
    'application/javascript',    // 'js'  
    'text/x-csharp',             // 'cs'  
    'text/x-python',             // 'py'  
    'text/x-java-source',        // 'java'  
    'application/xml',           // 'xml'  
    'application/sql',           // 'sql'  
    'application/x-httpd-php',   // 'php'  
    'text/x-powershell',         // 'ps1'  
    'text/x-ruby',               // 'rb'  
    'text/plain',                // 'tf' (Terraform files)  
    'text/x-vue',                // 'vue'  
    'application/x-yaml',        // 'yaml'  
    'application/x-yaml'         // 'yml'  
  ];
  filename!: string;

  constructor(
    private sharedService: SharedService,
    private devService: DevTalkService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private toastMessage: ToastMessageService,
    private cdr: ChangeDetectorRef,
    private loaderService: LoaderService,
    private modalService: BsModalService,
    private http: HttpClient,
    private router: Router,
    private modalserviceai: NgbModal,


    private location: Location,
    private sopService: SopService
  ) {
    // this.dynamicForm = this.formBuilder.group({
    //   textareaContent: ['']
    // });
    const generalCategory = this.promptCategories.find(cat => cat.category === 'General');
    if (generalCategory) {
      this.mainPrompts = generalCategory.prompts.slice(0, 3);
    }
  }

  openSessionModal() {
    this.modalserviceai.open(this.sessionModal, { centered: true });
  }

  submitSessionForm() {
    if (this.sessionName && this.selectedEndpoint) {
      console.log('Session Name:', this.sessionName);
      console.log('Selected Endpoint:', this.selectedEndpoint);
      this.modalserviceai.dismissAll();
      // Handle form submission logic here
    }
  }

  messageFormControl = new FormControl('', [
    Validators.minLength(2),
    Validators.maxLength(40000),
  ]);

  ngOnInit(): void {

    // this.isspinner = true;  
    timer(2000).subscribe(() => {
      this.isspinner = false;
    });



    this.getHistory();
    this.toggleHistory();

    this.scrollToBottom1();
    //throw new Error('Method not implemented.');
    // this.chatMessages.push({"type": "user", "message": "Hello and welcome! I am your personal Coding AI Assistant, DevTalk, your Infinite Subject Matter Expert, ready to assist you across all subject areas and  design principles for coding. You can refer to me as 'DevTalk' throughout our interaction. My primary mission is to craft top-notch software solutions.As an expert in programming, security, documentation, and a follower of industry best practices, including SOLID and DRY principles, I will diligently work on your software project.I'll be sure to ask questions to gain a complete understanding of your specifications before I engineer the software. No need for you to provide code – I'm here to assist you in every step."})
  }
  @ViewChild('textarea') textareaRef!: ElementRef;
  @ViewChild('ChatWindow', { static: false }) chatWindow!: ElementRef;
  @ViewChild('ChatWindow1', { static: false }) chatWindow1!: ElementRef;

  userMessage: string = '';
  gptResponse: string = '';
  blocks: { type: string; content: string; lng: string }[] = [];
  codeMirrorOptions: any = {
    theme: 'material', // Choose your preferred theme
    mode: 'auto', // Specify the mode for syntax highlighting
    lineNumbers: true,
    lineWrapping: true,
    autofocus: true, //
    readOnly: false
  };
  //chatMessages: any [] = [
  //  {"type": "bot", "message": "Hello! How can I assist you today?" },
  //  {"type": "user", "message": "Hi, I have a question." },
  //  {"type": "bot", "message": "Please go ahead and ask your question"}
  //  ];
  promptMessages: any[] = [];





  // Handle file input changes
  //  handleFileUpload(event: Event): void {
  //   const input = event.target as HTMLInputElement;
  //   if (input?.files) {
  //     const files = Array.from(input.files);
  //     files.forEach((file) => {
  //       if (this.isAllowedFileType(file)) {
  //         this.uploadedFiles.push(file);
  //       } else {
  //         alert(`File type not allowed: ${file.name}`);
  //       }
  //     });
  //   }
  // }
  handleFileUpload(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input?.files) {
      const file = input.files[0]; // Get the first file
      if (file && this.isAllowedFileType(file)) {
        this.uploadedFiles = [file]; // Clear existing files and add the new one
        this.filename = file.name;
        this.extractfile();
      } else if (file) {
        // alert(`File type not allowed: ${file.name}`);
        this.toastMessage.showError(`File type not allowed: ${file.name}`);
      }
    }
  }



  // Remove a specific file
  removeFile(index: number): void {
    this.uploadedFiles.splice(index, 1);
    this.uploadedFiles = [];
    this.fileInput.nativeElement.value = null; // Clear the file input after upload


  }

  // Check if a file type is allowed
  isAllowedFileType(file: File): boolean {
    const allowedExtensions = [
      'ts', 'html', 'css', 'scss', 'json', 'txt', 'js', 'cs', 'py',
      'java', 'xml', 'sql', 'php', 'ps1', 'rb', 'tf', 'vue', 'yaml', 'yml'
    ];

    // Extract file extension
    const fileExtension = file.name.split('.').pop()?.toLowerCase();

    return fileExtension ? allowedExtensions.includes(fileExtension) : false;
  }
  getFileIconSvg(fileName: string): string {
    const extension = fileName.includes('.') ? fileName.split('.').pop()?.toLowerCase() : '';

    const svgMap: Record<string, string> = {
      'ts': `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-ts" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg>`,
      'html': `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-html" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zm-9.736 7.35v3.999h-.791v-1.714H1.79v1.714H1V11.85h.791v1.626h1.682V11.85h.79Zm2.251.662v3.337h-.794v-3.337H4.588v-.662h3.064v.662zm2.176 3.337v-2.66h.038l.952 2.159h.516l.946-2.16h.038v2.661h.715V11.85h-.8l-1.14 2.596H9.93L8.79 11.85h-.805v3.999zm4.71-.674h1.696v.674H12.61V11.85h.79v3.325Z"/>
              </svg>`,
      'css': `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-css" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zm-9.736 7.35v3.999h-.791v-1.714H1.79v1.714H1V11.85h.791v1.626h1.682V11.85h.79Zm2.251.662v3.337h-.794v-3.337H4.588v-.662h3.064v.662zm2.176 3.337v-2.66h.038l.952 2.159h.516l.946-2.16h.038v2.661h.715V11.85h-.8l-1.14 2.596H9.93L8.79 11.85h-.805v3.999zm4.71-.674h1.696v.674H12.61V11.85h.79v3.325Z"/>
              </svg>`,
      'scss': `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-scss" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg>`,
      'json': `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-json" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg>`,
      'txt': `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zm-9.736 7.35v3.999h-.791v-1.714H1.79v1.714H1V11.85h.791v1.626h1.682V11.85h.79Zm2.251.662v3.337h-.794v-3.337H4.588v-.662h3.064v.662zm2.176 3.337v-2.66h.038l.952 2.159h.516l.946-2.16h.038v2.661h.715V11.85h-.8l-1.14 2.596H9.93L8.79 11.85h-.805v3.999zm4.71-.674h1.696v.674H12.61V11.85h.79v3.325Z"/>
              </svg>`,
      'js': `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-js" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg>`,
      'cs': `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-cs" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg>`,
      'py': `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-python" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zm-9.736 7.35v3.999h-.791v-1.714H1.79v1.714H1V11.85h.791v1.626h1.682V11.85h.79Zm2.251.662v3.337h-.794v-3.337H4.588v-.662h3.064v.662zm2.176 3.337v-2.66h.038l.952 2.159h.516l.946-2.16h.038v2.661h.715V11.85h-.8l-1.14 2.596H9.93L8.79 11.85h-.805v3.999zm4.71-.674h1.696v.674H12.61V11.85h.79v3.325Z"/>
              </svg>`,
      'java': `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-java" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg>`,
      'xml': `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-xml" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg>`,
      'sql': `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-sql" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg>`,
      'php': `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-php" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg>`,
      'ps1': `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-powershell" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zm-9.736 7.35v3.999h-.791v-1.714H1.79v1.714H1V11.85h.791v1.626h1.682V11.85h.79Zm2.251.662v3.337h-.794v-3.337H4.588v-.662h3.064v.662zm2.176 3.337v-2.66h.038l.952 2.159h.516l.946-2.16h.038v2.661h.715V11.85h-.8l-1.14 2.596H9.93L8.79 11.85h-.805v3.999zm4.71-.674h1.696v.674H12.61V11.85h.79v3.325Z"/>
              </svg>`,
      'rb': `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-ruby" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg>`,
      'tf': `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-tf" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg>`,
      'vue': `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-vue" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg>`,
      'yaml': `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-yaml" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg>`,
      'yml': `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-yml" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg>`
    }

    return extension && svgMap[extension] ? svgMap[extension] : svgMap['txt'];  // Default to 'txt' icon
  }





  // Drag over event
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    const target = event.currentTarget as HTMLElement;
    target.classList.add('drag-over');
  }

  // Drop event for drag-and-drop
  onDrop(event: DragEvent): void {
    event.preventDefault();
    const target = event.currentTarget as HTMLElement;
    target.classList.remove('drag-over');

    if (event.dataTransfer?.files) {
      const files = Array.from(event.dataTransfer.files);
      files.forEach((file) => {
        if (this.isAllowedFileType(file)) {
          this.uploadedFiles.push(file);
        } else {
          // alert(`File type not allowed: ${file.name}`);
          this.toastMessage.showError(`File type not allowed: ${file.name}`);

        }
      });
    }
  }

  // Adjust textarea height dynamically


  // Reset input area
  private resetInput(): void {
    this.userMessage = '';
    this.uploadedFiles = [];
    const inputElement = document.getElementById('file-upload') as HTMLInputElement;
    if (inputElement) {
      inputElement.value = ''; // Reset file input
    }
  }


  showMorePrompts() {
    this.viewMore = !this.viewMore;
    // Reset the selected category when opening the modal  
    if (this.viewMore === false) {
      this.selectedCategory = null;
    }
  }

  selectCategory(category: PromptCategory) {
    this.selectedCategory = category;
  }

  backToCategories() {
    this.selectedCategory = null;
  }

  setUserMessage(prompt: string) {
    this.userMessage = prompt;
  }

  // sendMessage function  
  regenerate(index: number) {
    // if (index > 0 && index < this.chatMessages.length) {
    this.userMessage = this.chatMessages[index - 1]?.message;
    console.log(this.userMessage)
    this.sendMessage();
    // }
  }


  lastChat() {
    let userChat = this.chatMessages.filter((ele: any) => ele.type == 'user');
    console.log(userChat)
    return userChat.pop();;
  }
  loadChat(chatId: number, chat: any) {
    this.isLoading = true;

    // Load the chat messages for the selected chat ID    
    const chatDetails = this.chatHistoryList.chat_details.find((chat: any) => chat.chat_id === chatId);

    if (chatDetails) {
      this.chatMessages = chatDetails.history;  // Assign directly to reset chatMessages  
      this.promptMessages = chatDetails.prompt_history || []; // Assign the prompt messages  

      // Ensure promptMessages is structured correctly  
      // If your chatDetails.history has messages with 'role' and 'content', you can reconstruct promptMessages:  
      // this.promptMessages = this.chatMessages.map(msg => ({ role: msg.type === 'user' ? 'user' : 'assistant', content: msg.message }));  

      this.ischat = true;
      this.active_chatId = chatId;

      this.userMessage = '';
      this.promptgen = false;
      this.isLoading = false;
    }
  }
  sendMessage(event: any = null): void {
    console.log(this.filecontent);
    this.ischat = true;
    this.isDevTalk = true;

    if (event) {
      event.preventDefault(); // Prevent the default behavior of the "Enter" key  
    }

    if (this.userMessage && this.userMessage.trim() !== '') {
      // Unsubscribe from any previous subscription  
      if (this.chatSubscription) {
        this.chatSubscription.unsubscribe();
        this.chatSubscription = null;
      }

      this.showCustomLoader = true; // Show the custom loader  this.isLoading = false;
      this.chatMessages.push({
        type: 'user',
        message: (this.filename ? ("Uploaded file:" + this.filename + '\n') : '') + (this.filecontent ? (this.filecontent + '\n') : '') + this.userMessage,
        file: this.filename ? this.filename : '',
        userMessage: this.userMessage,
      });

      // this.promptMessages.push({ role: 'user', content: this.userMessage });  
      this.promptMessages = this.chatMessages.map(msg => ({
        role: msg.type === 'user' ? 'user' : 'assistant',
        content: msg.message
      }));
      this.devTalkReq.user_prompt = JSON.stringify(this.promptMessages);
      this.devTalkReq.prompt_generator = this.promptgen;

      //  this.devTalkReq.file_status = this.uploadedFiles && this.uploadedFiles.length > 0 ? true : false ;

      //  this.devTalkReq.file = this.uploadedFiles && this.uploadedFiles.length > 0 ? this.uploadedFiles : false;

      // this.uploadedFiles = [file]; // Clear existing files and add the new one
      // this.filename = file.name;
      const formData = new FormData();

      formData.append('user_prompt', JSON.stringify(this.promptMessages));
      formData.append('prompt_generator', this.promptgen ? 'true' : 'false');
      // formData.append('file_status',this.devTalkReq.file_status ? 'true':'false');

      //  if (this.devTalkReq.file) {  
      //   this.uploadedFiles.forEach((file) => {  
      //     formData.append('file', file);  
      //   });  
      // }  


      this.botResponseLoading = true;
      this.isGenerating = true; // Bot is generating a response  

      // Scroll to bottom after adding user's message  
      this.cdr.detectChanges();

      // Prepare new message object for the assistant  
      let newMessage = {
        type: 'bot',
        message: '', // Initialize as a string  
        timeStamp: new Date(),
      };
      let isFirstChunk = true;

      // Subscribe and store the subscription  
      this.chatSubscription = this.sopService.chatcode(formData)
        .subscribe({

          next: (res: any) => {
            this.userMessage = '';
            if (res.status === 'streaming') {
              if (isFirstChunk) {
                // Push the initial newMessage to chatMessages  
                this.chatMessages.push(newMessage);
                isFirstChunk = false;

                // Scroll to bottom after adding bot's initial message  
                this.cdr.detectChanges();
                setTimeout(() => {
                  this.scrollToBottom();
                }, 0);
              }

              // Append streaming data to the bot's message  
              newMessage.message += res.data;


              // Optionally, trigger change detection  
              this.scrollToBottom();
              this.cdr.detectChanges();


            }


            else if (res.status === 'success') {
              // Finalize the bot's message  
              newMessage.message = res.data;
              this.promptMessages.push({ role: 'assistant', content: res.data });

              // Update UI indicators  
              this.showCustomLoader = false;
              this.botResponseLoading = false;
              this.isGenerating = false;
              this.uploadedFiles = [];
              this.filename = '';
              this.filecontent = '';
              this.fileInput.nativeElement.value = null; // Clear the file input after upload

              // Scroll to bottom after bot's message is complete  
              this.scrollToBottom();
              this.cdr.detectChanges();



            }
          },
          error: (error: any) => {
            console.log('Error occurred:', error);

            if (error.status === 401) {
              // Handle 401 Unauthorized errors  
              this.toastMessage.showError("Your session has expired. Refreshing the page, please wait...");
              const currentUrl = this.location.path();
              localStorage.setItem('p_redirect', currentUrl);
              this.router.navigate(['/']);
              window.location.reload();
            } else {
              // Handle other errors  
              let errorMessage = 'An unexpected error occurred';

              // Check for a server-provided error message  
              if (error.error && error.error.message) {
                errorMessage = error.error.message;
              } else if (error.message) {
                // Use the message from the Error object  
                errorMessage = error.message;
              }

              // Display the error message  
              this.toastMessage.showError(errorMessage);
            }

            // Update UI indicators  
            this.botResponseLoading = false;
            this.showCustomLoader = false;
            this.isGenerating = false;
            this.userMessage = '';
            if (this.promptMessages.length && this.chatMessages.length == 1) {
              this.promptMessages = [];
              this.chatMessages = [];
              this.filename = '';
              this.ischat = false;
              this.isDevTalk = false;
              this.uploadedFiles = [];
              this.fileInput.nativeElement.value = null; // Clear the file input after upload
            }
          },
          complete: () => {


            if (this.active_chatId) {
              this.updateHistroy({ 'chatdata': this.chatMessages, 'title': this.lastChat()?.message });
            } else {
              this.createHistroy({ 'chatdata': this.chatMessages, 'title': this.lastChat()?.message });
            }
            this.userMessage = '';
          },
        });



    } else {
      this.toastMessage.showmessage('Question is required');
    }
  }

  extractfile() {
    this.isLoading = true;
    const formData = new FormData();

    this.uploadedFiles.forEach((file) => {
      formData.append('file', file);
    });

    this.sopService.extract(formData)
      .subscribe(
        (res: any) => {

          this.isLoading = false;
          this.toastMessage.showSuccess(res.message);
          this.toastMessage.showmessage('File has ' + res.token_size + ' input tokens');
          this.filecontent = res.file_content

        },
        (error: any) => {
          console.log(error);
          this.isLoading = false;
          this.toastMessage.showError(error?.error?.message);
          this.uploadedFiles = [];
          this.filename = '';
          this.filecontent = '';
          this.fileInput.nativeElement.value = null; // Clear the file input after upload
        }

      );

  }


  createHistroy(chat: any) {
    let payload = { user_prompt: chat.chatdata };
    console.log(payload)

    this.sopService.crateHistory(payload)
      .subscribe(
        (res: any) => {
          console.log(res)
          if (res?.chat_id) {
            this.active_chatId = res?.chat_id;

            let data = {
              "chat_id": res?.chat_id,
              "date": new Date(),
              "delete_status": false,
              "title": chat?.title,
            }
            this.getHistory();
            // this.chatHistoryList.unshift(data)


          }
        },
        (error: { message: string; }) => {
          console.error(error);
          this.toastMessage.showError(error?.message);
        }
      );
  }

  // chat Update
  updateHistroy(chat: any) {
    let payload = { user_prompt: chat.chatdata, chat_id: this.active_chatId };
    console.log(payload)

    this.sopService.updateHistory(payload)
      .subscribe(
        (res: any) => {
          console.log(res)
          if (res) {
            this.getHistory1();



          }
        },
        (error: { message: string; }) => {
          console.error(error);
          this.toastMessage.showError(error?.message);
        }
      );
  }

  openModal(template: TemplateRef<any>, id: any, event: Event) {
    const size = 'modal-sm'

    this.modalRef = this.modalService.show(template, { class: size, ignoreBackdropClick: true });
    this.histroyDeleteId = id;
    this.deleteid = event;
  }
  // get all histroy of user

  getHistory() {
    this.isLoading = true;
    this.loaderService.loading.next(true);
    this.sopService.getHistory()
      .subscribe(
        (res: any) => {
          this.loaderService.loading.next(false);
          if (res) {
            this.chatHistoryList = res || [];

            if (res?.chat_details?.length == 0) {
              this.isLoading = false;
              return;
            }

            this.groupHistoriesByDate();
          }
        },
        (error: { error: { message: string; }; }) => {
          this.loaderService.loading.next(false);
          this.isLoading = false;

          console.log(error);
          this.toastMessage.showError(error?.error?.message);
        }
      );
  }

  getHistory1() {
    // this.loaderService.loading.next(true);
    this.sopService.getHistory()
      .subscribe(
        (res: any) => {
          // this.loaderService.loading.next(false);
          if (res) {
            this.chatHistoryList = res || [];
          }
        },
        (error) => {
          this.loaderService.loading.next(false);
          console.log(error);
          this.toastMessage.showError(error?.error?.message);
        }
      );
  }
  // groupHistoriesByDate() {  
  //   const chatDetails = this.chatHistoryList.chat_details;  
  //   this.groupedHistories = {};  

  //   const today = new Date();  
  //   const earlierThisMonth: any[] = [];  

  //   chatDetails.forEach((chat: { date: string | number | Date; history: any[]; title: any; }) => {  
  //     const chatDate = new Date(chat.date);  
  //     let groupKey = '';  

  //     if (  
  //       chatDate.getFullYear() === today.getFullYear() &&  
  //       chatDate.getMonth() === today.getMonth() &&  
  //       chatDate.getDate() === today.getDate()  
  //     ) {  
  //       groupKey = 'Today';  
  //     } else if (  
  //       chatDate.getFullYear() === today.getFullYear() &&  
  //       chatDate.getMonth() === today.getMonth()  
  //     ) {  
  //       groupKey = 'Earlier this Month';  
  //     } else {  
  //       const monthName = chatDate.toLocaleString('default', { month: 'long' });  
  //       groupKey = `${monthName} ${chatDate.getFullYear()}`;  
  //     }  

  //     if (!this.groupedHistories[groupKey]) {  
  //       this.groupedHistories[groupKey] = [];  
  //     }  

  //     // Set the title as the first user message or 'No Title'  
  //     const firstUserMessage = chat.history.find((msg: any) => msg.type === 'user');  
  //     chat.title = firstUserMessage ? firstUserMessage.message : 'No Title';  

  //     this.groupedHistories[groupKey].push(chat);  
  //   });  
  // }  

  // sortGroups = (a: KeyValue<string, any>, b: KeyValue<string, any>): number => {  
  //   const order: { [key: string]: number } = {  
  //     'Today': 1,  
  //     'Earlier this Month': 2  
  //   };  

  //   const aOrder = order[a.key] || 3;  
  //   const bOrder = order[b.key] || 3;  

  //   if (aOrder !== bOrder) {  
  //     return aOrder - bOrder;  
  //   }  

  //   // If same order value, sort by date descending  
  //   return new Date(b.key).getTime() - new Date(a.key).getTime();  
  // };  

  groupHistoriesByDate() {
    const chatDetails = this.chatHistoryList.chat_details;
    this.groupedHistories = {};

    const today = new Date();
    const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    // Start of the current month  
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    // Start date for the last seven days (excluding today)  
    let lastSevenDaysStart = new Date(startOfToday);
    lastSevenDaysStart.setDate(startOfToday.getDate() - 7);

    // Ensure that lastSevenDaysStart is not before startOfMonth  
    if (lastSevenDaysStart < startOfMonth) {
      lastSevenDaysStart = startOfMonth;
    }

    chatDetails.forEach((chat: { date: string | number | Date; history: any[]; title: any; chat_id: any }) => {
      const chatDate = new Date(chat.date);
      let groupKey = '';

      if (chatDate >= startOfToday) {
        groupKey = 'Today';
      } else if (chatDate >= lastSevenDaysStart && chatDate < startOfToday) {
        groupKey = 'Last seven days';
      } else if (chatDate >= startOfMonth && chatDate < lastSevenDaysStart) {
        groupKey = 'Earlier this Month';
      } else {
        const monthName = chatDate.toLocaleString('default', { month: 'long' });
        groupKey = `${monthName} ${chatDate.getFullYear()}`;
      }

      if (!this.groupedHistories[groupKey]) {
        this.groupedHistories[groupKey] = [];
      }

      // Set the title as the existing title or the first user message, or 'No Title' if neither exists  
      const firstUserMessage = chat.history.find((msg: any) => msg.type === 'user');
      chat.title = chat.title ? chat.title : (firstUserMessage ? firstUserMessage.message : 'No Title');

      this.groupedHistories[groupKey].push(chat);
    });

    // **Sort the chats within each group in descending order of date**  
    for (let groupKey in this.groupedHistories) {
      this.groupedHistories[groupKey].sort((a: { date: string | number | Date; }, b: { date: string | number | Date; }) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB.getTime() - dateA.getTime(); // Descending order  
      });
    }

    this.isLoading = false;
  }

  // Sorting function for sorting the groups  
  sortGroups = (a: KeyValue<string, any>, b: KeyValue<string, any>): number => {
    const order: { [key: string]: number } = {
      'Today': 1,
      'Last seven days': 2,
      'Earlier this Month': 3,
    };

    const aOrder = order[a.key] || 4;
    const bOrder = order[b.key] || 4;

    // First, sort based on predefined group order  
    if (aOrder !== bOrder) {
      return aOrder - bOrder;
    }

    if (aOrder === 4) {
      // For custom month-year groups, parse the month and year  
      const parseMonthYear = (key: string): Date | null => {
        const regex = /^([A-Za-z]+)\s(\d{4})$/;
        const match = key.match(regex);
        if (match) {
          const monthName = match[1];
          const year = parseInt(match[2], 10);
          const month = new Date(`${monthName} 1, ${year}`).getMonth();
          return new Date(year, month);
        } else {
          return null;
        }
      };

      const aDate = parseMonthYear(a.key);
      const bDate = parseMonthYear(b.key);

      if (aDate && bDate) {
        return bDate.getTime() - aDate.getTime(); // Descending order  
      } else {
        return 0;
      }
    }

    return 0; // Keep the order as is for 'Today', 'Last seven days', 'Earlier this Month'  
  };



  toggleHistory() {
    this.isHistoryOpen = !this.isHistoryOpen;
  }

  newChat() {
    this.userMessage = '';
    this.chatMessages = [];
    this.promptMessages = [];
    this.active_chatId = '';
    this.histroyDeleteId = '';
    this.ischat = false;
    this.uploadedFiles = [];
    this.fileInput.nativeElement.value = null; // Clear the file input after upload
  }


  deleteChat(chatId: number, event: Event) {
    event.stopPropagation(); // Prevent triggering the loadChat  
    this.ischat = false;
    // Implement your delete logic here, e.g., call a service to delete the chat  
    // Remove from the data structure and re-group  
    this.chatMessages = [];
    this.chatHistoryList.chat_details = this.chatHistoryList.chat_details.filter((chat: any) => chat.chat_id !== chatId);
    // this.chatHistoryList.cha
    this.deleteHistory();
    this.groupHistoriesByDate();
  }

  //Delete history
  deleteHistory() {

    this.loaderService.loading.next(true);
    let payload = { chat_id: this.histroyDeleteId };
    this.sopService.deleteUserHistory(payload)
      .subscribe(
        (res: any) => {
          this.loaderService.loading.next(false);
          // this.modalRef?.hide();

          if (res) {
            let index = this.chatHistoryList.findIndex((ele: any) => ele.chat_id === this.histroyDeleteId);
            if (index !== -1) this.chatHistoryList.splice(index, 1);
          }

          // if (this.histroyDeleteId == this.active_chatId) this.new_conversation();
          this.histroyDeleteId = '';

        },
        (error) => {
          this.loaderService.loading.next(false);
          // this.modalRef?.hide();
          console.error(error);
          this.toastMessage.showError(error?.message);
        }
      );
  }
  // stopGenerating function  
  stopGenerating(): void {
    if (this.chatSubscription) {
      this.chatSubscription.unsubscribe();
      this.chatSubscription = null;
    }
    this.botResponseLoading = false;
    this.showCustomLoader = false; // Hide the custom loader  
    this.isGenerating = false; // Bot has stopped generating  
    if (this.active_chatId) {
      this.updateHistroy({ 'chatdata': this.chatMessages, 'title': this.lastChat()?.message });
    } else {
      this.createHistroy({ 'chatdata': this.chatMessages, 'title': this.lastChat()?.message });
    }
    this.userMessage = '';
  }

  // Helper method to update the last assistant message during streaming  
  updateLastAssistantMessage(content: string): void {
    // Find the last message of type 'bot'  
    for (let i = this.chatMessages.length - 1; i >= 0; i--) {
      if (this.chatMessages[i].type === 'bot') {
        this.chatMessages[i].message = [{ type: 'text', content: content }]; // Type is any[]  
        break;
      }
    }
  }
  // Helper method to update the last assistant message with final blocks  
  updateLastAssistantMessageBlocks(blocks: any[]): void {
    // Find the last message of type 'bot'  
    for (let i = this.chatMessages.length - 1; i >= 0; i--) {
      if (this.chatMessages[i].type === 'bot') {
        this.chatMessages[i].message = blocks; // Both are any[]  
        break;
      }
    }
  }

  // Helper method to process assistant content into blocks  
  processAssistantContent(content: string): any[] {
    const blocks: any[] = [];
    const lines = content.split('\n');
    let currentBlock: any = null;
    let isCodeBlock = false;
    let codeLanguage = '';

    for (let line of lines) {
      if (line.startsWith('```')) {
        if (isCodeBlock) {
          // End of code block  
          blocks.push({
            type: 'code',
            content: currentBlock.content.trim(),
            lng: codeLanguage || 'plaintext',
          });
          currentBlock = null;
          isCodeBlock = false;
          codeLanguage = '';
        } else {
          // Start of code block  
          isCodeBlock = true;
          codeLanguage = line.slice(3).trim(); // Get the language after ```  
          currentBlock = { content: '' };
        }
      } else {
        if (isCodeBlock) {
          currentBlock.content += line + '\n';
        } else {
          if (!currentBlock || currentBlock.type !== 'text') {
            if (currentBlock) {
              blocks.push(currentBlock);
            }
            currentBlock = { type: 'text', content: '' };
          }
          currentBlock.content += line + '\n';
        }
      }
    }

    // Push any remaining block  
    if (currentBlock) {
      if (isCodeBlock) {
        blocks.push({
          type: 'code',
          content: currentBlock.content.trim(),
          lng: codeLanguage || 'plaintext',
        });
      } else {
        blocks.push({
          type: 'text',
          content: currentBlock.content.trim(),
        });
      }
    }

    return blocks;
  }

  // ngOnDestroy lifecycle hook to clean up subscriptions  
  ngOnDestroy(): void {
    if (this.chatSubscription) {
      this.chatSubscription.unsubscribe();
      this.chatSubscription = null;
    }
  }




  storeLogs() {
    const payload = {
      'event_type': 'codeGenYLite',
      'event_data': {
        'feature': 'dev_talk',
        "userPrompt": this.userMessage,
      }
    }
    this.sopService.logsStorage(payload).subscribe((res) => {
      // console.log(res);
    })
  }

  logActivity() {
    this.activityAudit.module = ApiEndpoints.GetGptResponseCustom;
    this.activityAudit.time_stamp = String(new Date());
    let userName = localStorage.getItem('okta-username');
    this.activityAudit.ohr_id = userName ? String(userName) : '';
    //this.sharedService.postActivityLog(this.activityAudit).subscribe({
    this.sharedService.postActivityLog(this.activityAudit).subscribe({
      next: (res) => {
        if (res.status && res.status_code == StatusCode.Success) {
        } else {
          if (res.status_code == 500) {
          } else if (res.status_code == 400) {
          } else {
          }
        }
      },
      error: (err) => { },
    });
  }

  //  scroll to bottom function added here.............
  scrollToBottom() {
    setTimeout(() => {
      const containerElement = this.chatWindow.nativeElement;
      containerElement.scrollTop = containerElement.scrollHeight;
    }, 1000);
  }

  scrollToBottom1() {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 0);
  }


  // private scrollToBottom(chatWindow: ElementRef): void {
  //   if (chatWindow && chatWindow.nativeElement) {
  //     const chatWindowElement = chatWindow.nativeElement;
  //     chatWindowElement.scrollTop = chatWindowElement.scrollHeight;
  //   }
  // }
  adjustTextareaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;

    // Reset the height before adjusting
    textarea.style.height = 'auto';

    // Get line height from computed styles
    const lineHeight = parseInt(getComputedStyle(textarea).lineHeight || '20', 10);

    // Adjust height based on scrollHeight
    textarea.style.height = `${textarea.scrollHeight}px`;

    // Set a max height, for example, 5 lines
    const maxHeight = lineHeight * 5;
    if (textarea.scrollHeight > maxHeight) {
      textarea.style.height = `${maxHeight}px`;  // Cap at max height (5 lines)
      textarea.style.overflowY = 'auto';  // Show scrollbar if content exceeds max height
    } else {
      textarea.style.overflowY = 'hidden';  // Hide scrollbar if content fits within max height
    }

    // Move content upwards by setting scrollTop to the difference between scrollHeight and clientHeight
    const diff = textarea.scrollHeight - textarea.clientHeight;
    textarea.scrollTop = diff;  // This scrolls the textarea content upwards as it grows
  }





  // scroll to bot div function added here................
  // private scrollToTopOfLastBotMessage(chatWindow: ElementRef): void {
  //   if (chatWindow && chatWindow.nativeElement) {
  //     const chatWindowElement = chatWindow.nativeElement;

  //     // Find the last bot message element
  //     const botMessages = chatWindowElement.querySelectorAll('.bot-message');
  //     const lastBotMessage = botMessages[botMessages.length - 1];

  //     // Scroll to the top of the last bot message
  //     if (lastBotMessage) {
  //       chatWindowElement.scrollTop = lastBotMessage.offsetTop;
  //     }
  //   }
  // }

  // adjustTextareaHeight(event: Event): void {
  //   const textarea = event.target as HTMLTextAreaElement;
  //   textarea.style.height = '40px'; // Reset height to a fixed value

  //   // Calculate the new height based on the scrollHeight
  //   const newHeight = Math.min(textarea.scrollHeight, parseInt(getComputedStyle(textarea).maxHeight));

  //   // Set the height to the new height only if it's greater than the fixed height
  //   textarea.style.height = newHeight > 40 ? newHeight + 'px' : '40px';

  //   // Update overflow-y based on whether the content exceeds one line
  //   textarea.style.overflowY = newHeight > 40 ? 'auto' : 'hidden';
  // }

  // private resetTextareaHeight(): void {
  //   const textarea = document.querySelector('.custom-control') as HTMLTextAreaElement;
  //   if (textarea) {
  //     textarea.style.height = '40px';
  //   }
  // }

  splitCodeAndText(): void {
    this.blocks = [];

    // Split the response based on the triple-single-quote delimiter
    const parts = this.gptResponse.split('```');

    // Start from index 1 because the first part is empty
    for (let i = 0; i < parts.length; i += 2) {
      let language = '';
      const textBlock = parts[i] ? parts[i].trim() : '';
      const processedContent = textBlock.replace(
        /\*\*(.*?)\*\*/g,
        '<strong>$1</strong>'
      );

      let codeBlock = parts[i + 1] ? parts[i + 1].trim() : '';
      if (codeBlock) {
        const indexOfNewLine: number = codeBlock.indexOf('\n');
        if (indexOfNewLine !== -1) { // Changed condition to check for -1
          language = codeBlock.substring(0, indexOfNewLine).trim(); // Trim language for cleanliness
          codeBlock = codeBlock.substring(indexOfNewLine + 1).trim(); // Trim code block for cleanliness
          // Optionally process the language if necessary
          // this.languagedetector(codeBlock);
        }
      }

      // Push text block to blocks array
      this.blocks.push({ type: 'text', content: processedContent, lng: '' });

      // Push code block to blocks array if it has content
      if (codeBlock) {
        this.blocks.push({ type: 'code', content: codeBlock, lng: language });
      }
    }

    // Push to chat messages if there are any parts
    if (this.blocks.length > 0) {
      this.chatMessages.push({
        type: 'bot',
        message: this.blocks,
      });
    }
  }


  // private resetTextareaHeight(): void {
  //   const textarea = document.querySelector('.custom-control') as HTMLTextAreaElement;
  //   if (textarea) {
  //     textarea.style.height = '40px';
  //   }
  // }

  copyText(code: string) {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        // Change tooltip text after successfully copying
        this.tooltipText = 'Copied!';
        this.code = code;
        // Set a timeout to change the tooltip back to "Click to Copy" after 5 seconds (5000 milliseconds)
        setTimeout(() => {
          this.tooltipText = 'Click to Copy';
        }, 5000);
      })
      .catch(() => {
        this.sharedService.openSnackBar(
          'Unable to copy text',
          MessageTypeSnack.Error
        );
      });
  }

  // downloadFile() {
  //   // Check if the code has been copied
  //   if (!this.code) {
  //     // Handle the case where no code has been copied
  //     return;
  //   }

  //   const blob = new Blob([this.code], { type: 'application/msword' });
  //   const url = URL.createObjectURL(blob);
  //   const anchor = document.createElement('a');
  //   anchor.href = url;
  //   anchor.download = 'copied_text.doc';
  //   anchor.click();
  //   URL.revokeObjectURL(url);
  //   anchor.remove();

  //   // Clear the code value after downloading
  //   this.code = '';
  // }

  downloadFile(code: string) {
    const detectedExtensions = this.languagedetector(code);
    const extension = detectedExtensions[0];
    const tempstore = extension.slice(1);
    // const blob = new Blob([code], { type: 'application/msword' });
    const blob = new Blob([code], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = `code_${tempstore}_${extension}`;
    anchor.click();
    URL.revokeObjectURL(url);
    anchor.remove();
  }

  languagedetector(codestring: string) {
    const detectionResult = detect(codestring);
    console.log('Detected language:', detectionResult);
    let tempstore = detectionResult.toLowerCase();
    console.log('lowercase', tempstore);

    const languageExtensions: { [key: string]: string[] } = {
      javascript: ['.js'],
      python: ['.py'],
      C: ['.c'],
      'c++': ['.cpp'],
      java: ['.java'],
      html: ['.html'],
      css: ['.css'],
      ruby: ['.rb'],
      go: ['.go'],
      php: ['.php'],
      // Add more programming languages and their file extensions as needed
    };

    const detectedExtensions = languageExtensions[tempstore] || ['.txt'];
    console.log('Detected file extensions:', detectedExtensions);
    return detectedExtensions;
  }

  openDialog(): void {

    const dialogRef = this.dialog.open(DisclaimerComponent);
  }
  // copyToClipboard(text: any) {  
  //   const tempInput = document.createElement('textarea');
  //   tempInput.value = text;
  //   document.body.appendChild(tempInput);   
  //   tempInput.select();   
  //   document.execCommand('copy');
  //   document.body.removeChild(tempInput);
  // }
  async copyToClipboard(markdown: string) {
    const html = this.convertMarkdownToHtml(markdown);
    const text = this.convertMarkdownToText(markdown); // You need to implement this method
    try {
      await navigator.clipboard.write([
        new ClipboardItem({
          'text/html': new Blob([html], { type: 'text/html' }),
          'text/plain': new Blob([text], { type: 'text/plain' })
        })
      ]);
      this.isCopied = true;
      setTimeout(() => {
        this.isCopied = false;
      }, 3000);
      console.log('Content copied to clipboard');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }
  convertMarkdownToText(markdown: string): string {
    // Simple conversion logic here
    // You might want to use a library for more comprehensive conversion
    return markdown.replace(/[#*]/g, '').trim();
  }
  convertMarkdownToHtml(markdown: string): string {
    return marked(markdown);
  }

  // generateDocument(message:any) {  
  //   const content = this.formatConversation(message);  

  //   this.loadFile('assets/template.docx').subscribe(  
  //     (contentBuffer) => {  
  //       const zip = new PizZip(contentBuffer);  
  //       const doc = new Docxtemplater().loadZip(zip);  

  //       // Set the template variables  
  //       doc.setData({  
  //         conversation: content  
  //       });  

  //       try {  
  //         // Render the document (replace all occurrences of placeholders)  
  //         doc.render();  
  //       } catch (error) {  
  //         console.error('Error rendering the document', error);  
  //         throw error;  
  //       }  

  //       const out = doc.getZip().generate({  
  //         type: 'blob',  
  //         mimeType:  
  //           'application/vnd.openxmlformats-officedocument.wordprocessingml.document'  
  //       });  

  //       // Prompt the user to save the file  
  //       saveAs(out, 'conversation_history.docx');  
  //     },  
  //     (error: any) => {  
  //       console.error('Error loading the template', error);  
  //     }  
  //   );  
  // }  

  // loadFile(url: string): Observable<ArrayBuffer> {  
  //   return this.http.get(url, { responseType: 'arraybuffer' });  
  // }  


  // formatConversation(conversation: any[]): string {  
  //   return conversation  
  //     .map(entry => {  
  //       const timeStamp = entry.timeStamp  
  //         ? ` [${new Date(entry.timeStamp).toLocaleString()}]`  
  //         : '';  
  //       return `${entry.type.toUpperCase()}${timeStamp}: ${entry.message}`;  
  //     })  
  //     .join('\n\n');  
  // }  
  // generateDocument(message: string) {  
  //   // Access the content directly  
  //   const content = message;  

  //   const doc = new Document();  

  //   // Split the content by lines  
  //   const lines = content.split('\n');  
  //   let inCodeBlock = false;  

  //   const paragraphs = lines.map((line: string) => {  
  //     if (line.startsWith('```')) {  
  //       inCodeBlock = !inCodeBlock;  
  //       return new Paragraph(''); // Empty paragraph for spacing  
  //     } else if (line.trim() === '') {  
  //       return new Paragraph(''); // Empty paragraph for blank lines  
  //     } else if (inCodeBlock) {  
  //       // Inside a code block  
  //       return new Paragraph({  
  //         children: [  
  //           new TextRun({  
  //             text: line,  
  //             font: 'Courier New',  
  //             size: 24, // Adjust the size as needed  
  //           }),  
  //         ],  
  //       });  
  //     } else {  
  //       // Regular text  
  //       return new Paragraph({  
  //         children: [  
  //           new TextRun({  
  //             text: line,  
  //             font: 'Arial',  
  //             size: 24, // Adjust the size as needed  
  //           }),  
  //         ],  
  //       });  
  //     }  
  //   });  

  //   doc.addSection({  
  //     properties: {},  
  //     children: paragraphs,  
  //   });  

  //   // Generate the Word document and save it  
  //   Packer.toBlob(doc).then(blob => {  
  //     saveAs(blob, 'conversation_history.docx');  
  //   });  
  // }  


  formatConversation(conversation: { message: string }): string {
    return conversation.message;
  }
  loadFile(url: string): Observable<ArrayBuffer> {
    return this.http.get(url, { responseType: 'arraybuffer' });
  }


}
