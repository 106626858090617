import { Component } from '@angular/core';

@Component({
  selector: 'app-crews',
  standalone: false,
  
  templateUrl: './crews.component.html',
  styleUrl: './crews.component.scss'
})
export class CrewsComponent {

  features = [  
    {  
      image: 'assets/CGL/images/Group2.svg',  
      title: 'Build your project',  
      description:  
        'An all-encompassing process that involves taking a project from initial concept to completion. This includes defining business requirements, analyzing needs, designing the software architecture, developing code, creating user interfaces, and generating test cases. The goal is to methodically plan and construct a software project that meets all specified objectives and user expectations.',  
      link: '/cgy/brd'  
    },  
    {  
      image: 'assets/CGL/images/stars.png',  
      title: 'AI Assist',  
      description:  
        'Improve developer efficiency with instant, AI-powered chat support, minimizing downtime and increasing productivity. Cut operational costs by providing quick resolutions to coding queries.',  
      link: '/cgy/ai-assist'  
    },  
    {  
      image: 'assets/CGL/images/Code enhancent.svg',  
      title: ' Code Assist',  
      description:  
        'Designed to facilitate and improve the coding process. This involves enhancing existing code for better performance and readability, documenting code functionalities, adjusting code to meet changing requirements, analyzing code to identify and fix issues, and converting code into different formats or languages when necessary. These efforts aim to streamline development and ensure high-quality software output.',  
      link: '/cgy/codeassist'  
    },  
    // Add more features as needed  
    {  
      image: 'assets/images/icon4.png',  
      title: 'Real-time collaboration',  
      description:  
        'Collaborate with your team in real-time to build amazing products.',  
      link: '/feature/real-time-collaboration'  
    },  
    // {  
    //   image: 'assets/images/icon5.png',  
    //   title: 'Cloud Integration',  
    //   description:  
    //     'Seamlessly integrate with cloud services for better scalability.',  
    //   link: '/feature/cloud-integration'  
    // },  
    // {  
    //   image: 'assets/images/icon6.png',  
    //   title: 'Performance Analytics',  
    //   description:  
    //     'Gain insights into your application performance with advanced analytics.',  
    //   link: '/feature/performance-analytics'  
    // }  
  ]; 

}
