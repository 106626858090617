<nav class="navbar navbar-expand-lg navbar-light" style="background-color: #FFFAF4;">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img src="assets/CGL/images/genb.png" height="120" alt="Genpact Logo" class="logo" />
    </a>
    <br>
    <br>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup"
      aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" style="padding-top: 60px;" id="navbarNavAltMarkup">
      <div class="navbar-nav">
        <a class="nav-link" 
           *ngFor="let tab of tabs" 
           (click)="selectTab(tab)"
           [ngClass]="{'active': activeTab === tab}">
          {{ tab.label }}
        </a>
      </div>
    </div>
  </div>
</nav>