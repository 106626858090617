<div class="container-row">
    <div class="container-col">
        <div class="row">
            <div class="col-12 mt-2">
                <div class="header">
                    <h1 class="gradient-text">GenAI Agentic Framework</h1>
                    <div class="toggle-container">
                        <label class="switch">
                            <input type="checkbox" [(ngModel)]="isSupervisorModeEnabled"
                                (change)="toggleSupervisorMode()">
                            <span class="slider"></span>
                        </label>
                        <span class="toggle-label">Supervisor Mode: {{ isSupervisorModeEnabled ? 'Enabled' : 'Disabled'
                            }}</span>
                    </div>
                </div>
            </div>
            <!-- Rest of your HTML code -->
            <div class="chat-scroll smooth-scroll" #scrollChat style="background-color:#ffffff">
                <div class="col-12" style="width: 80%;
    margin: 0 auto;">
                    <div class="row" style="gap: 20px;">
                        <ng-container *ngFor="let chat of chatData; let i = index">
                            <div class="col-12" *ngIf="chat.role == 'user'">
                                <div class="user-chat float-right">{{ chat.content.split(':')[0] }}</div>
                            </div>
                            <div class="col-12" *ngIf="chat.role != 'user'">
                                <div class="bot-chat" *ngIf="chat?.section != 'question' && chat?.section != 'sources'">
                                    {{ chat.content.split(':')[0] }}:
                                    <div style="white-space: pre-line">{{ chat.content.split(':').slice(1).join(':') }}
                                    </div>
                                </div>
                                <div class="bot-chat" *ngIf="chat?.section == 'sources'">
                                    <h6>{{ chat?.title }}</h6>
                                    <div class="row prompts">
                                        <div class="col-6 prompt" *ngFor="let item of chat?.content">
                                            <div *ngIf="item.imageTag.startsWith('<img')"
                                                style="display: flex; gap: 5px;">
                                                <div [innerHTML]="item.imageTag"></div>
                                                <a class="ellipsis" href="{{ item?.url }}" target="_blank">{{ item.name
                                                    }}</a>
                                            </div>
                                            <div *ngIf="item.imageTag.startsWith('<svg')">
                                                <div style="display: flex; gap: 10px;">
                                                    <img src="../../../assets/images/icons/search web 1.svg"
                                                        alt="search web" class="image-icon">
                                                    <a class="ellipsis" href="{{ item?.url }}" target="_blank">{{
                                                        item.name }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="bot-chat" *ngIf="chat?.section == 'question'">
                                    <div class="language-buttons">
                                        <h6>{{ chat?.title }}</h6>
                                        <div class="question-list" (click)="inputPrompt(question)"
                                            *ngFor="let question of chat?.content">
                                            <span>{{ question }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="user-action"
                                    *ngIf="chat?.section != 'question' && chat?.section != 'sources'">
                                    <div class="btn btn-sm" (click)="copyToClipboard(chat?.content)"
                                        title="Copy to clipboard">
                                        <img src="/assets/images/icons/copy.svg" alt="copy">
                                    </div>
                                    <div class="btn btn-sm" (click)="regenerate(i)">
                                        <img src="/assets/images/icons/refresh.svg" alt="refresh" title="Regenerate">
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

        <!-- text area user input -->
        <div class="question-block">
            <div class="row">
                <div class="offset-lg-1 col-lg-10 col-12">
                    <div class="row">
                        <div class="col-12">
                            <div class="chat-container">
                                <div class="new-topic" (click)="newChat()">
                                    <img class="w-100" placement="top" ngbPopover="New Topic" popoverClass="pop-block"
                                        triggers="mouseenter:mouseleave" src="assets/images/new_chat.png" alt="">
                                </div>
                                <div class="ask-question">
                                    <div class="query-section">
                                        <textarea maxlength="100" id="textArea" rows="1" class="form-control"
                                            placeholder="Ask your question" (input)="autoResize($event)"
                                            (keyup.enter)="handleEnter()" [(ngModel)]="prompt"></textarea>
                                    </div>
                                    <button class="btn" (click)="sentToInput()">
                                        <img src="/assets/images/icons/send.svg" alt="">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row px-4">
                        <div class="col-12"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isLoading" class="overlay">
    <div class="loader"></div>
</div>