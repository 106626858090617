<button style="display:none;" id="btnbrd" type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button>
<div id="#exampleModal" bsModal #autoShownModal="bs-modal" [config]="{backdrop: 'static'}" id="myModal" class="modal" style="display: block;  background-color: rgba(0, 0, 0, 0.2);" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name" aria-hidden="true" (onHidden)="onHidden()">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left" class="heading">Privacy Policy</h4>
      </div>
      <div class="highlight-line">Before continuing, please review the Use of Generative AI Policy. Compliance with the Policy is required.
      </div>
      <div class="conditions">
        <ul class="Conditionlist">
          <li class="list-item">AI isn’t perfect.  While efforts have been made to provide accurate and relevant information, there is the possibility of errors and inaccuracies.  The response you receive should not be considered professional or expert advice.  You are required to check and verify the information with reliable sources or consult a human expert as necessary and appropriate.</li>
          <li>Your prompts and use of this tool may be subject to review or audit for compliance with Genpact’s policies and/or for refining the technology.</li>
           <li>No Client or Genpact names, references, IP addresses, usernames, access key, credentials, or any other confidential or sensitive information may be shared or made available on any public or third-party website or tool. </li>
           <li>No sensitive personal information (“SPI”) and/or personally identifiable information (“PII”) should be processed by this tool.  Please ensure any documents or materials submitted to this tool do not contain any SPI or PII and also validate that no output from the toll contain any SPI or PII.  </li>
        </ul>
      </div>
      <div class="declarations">

       <div> <input class="checkbox1" type="checkbox" [(ngModel)]="checkbox1Accepted" /> By entering input and using this tool, you acknowledge and agree you have read the information above, understand it, and agree to comply with the Use of Generative AI Programs Polices and other applicable Genpact policies.
       </div><div class="condition2">    <input class="checkbox2" type="checkbox" [(ngModel)]="checkbox2Accepted"/><em><u> COMPLIANCE MANDATORY – IMPORTANT INSTRUCTIONS FOR ALL USERS.</u></em>
      It is critical that all users protect Company intellectual property rights and the rights of others.  Before using any code generated by this tool, you MUST (mandatory obligation) verify the generated code for intellectual property right infringements, including copyright violations in accordance with the <a href="../../../../assets/sop.pdf" target="_blank">SOPs</a>. It is all user’s responsibility to ensure strict adherence to the SOPs and all Genpact Policies.
    </div>   </div>
      <!-- <div>content</div> -->
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="acceptTermsAndConditions()" [disabled]="!checkbox1Accepted || !checkbox2Accepted" style="background-color: #51ACC5;">Accept</button>

        <!-- <button type="button" class="close" (click)="tryCloseModal()" [disabled]="!checkboxAccepted">&#215;</button> -->
      </div>
    </div>
  </div>
</div>
<div class="home" *ngIf="userAccessStatus">
  <div *ngIf="title">
  <app-header></app-header>
</div>
<!-- float right -->

<div class="float-right">
  <label class="form-check-label text-dark mr-3" for="style_guide">
    Choose Function:
  </label>
  <div class="float-right-content">

    <div class="btn-group px-2" role="group" aria-label="Basic radio toggle button group" style="padding-top: 10px; margin-left: 2px !important;">
      <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" [(ngModel)]="domaincheckstatus" [value]="'IT'" (change)="update1()">
      <label class="btn btn-outline-primary" for="btnradio1">IT</label>

      <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" [(ngModel)]="domaincheckstatus" [value]="'LDT'" (change)="update()">
      <label class="btn btn-outline-primary" for="btnradio2">LDT</label>
    </div>

    </div>
    </div>

  <div class="banner-container">
    <p class="tagline">
      Ever wonder, how
      <span style="color: var(--genpact-aqua)">developers</span> code better
    </p>
    <p class="subtagline">
      Single platform to enhance your code, create code document and your
      project document.
    </p>
    <!-- <button class="btn get-started-btn">
      Get started
    </button> -->
  </div>
  <div class="tab-container">
    <div class="tab-main">
      <div class="tab-container">

        <ul class="nav nav-tabs">
          <li *ngIf="selectedfunction =='IT'" id="dmbtn"  class="nav-item" (click)="setTab(2)">
            <a class="nav-link" [ngClass]="activatedTab === 2 ? 'active' : ''">
              <div class="tab-content">
                <div class="img-section">
                  <img
                    src="../../../assets/images/buildProject.png"
                    alt="Image 2"
                    class="tab-image"
                  />
                </div>
                <span class="tab-text">Build your<br />project</span>
              </div>
            </a>
          </li>

          <li   class="nav-item" (click)="setTab(0)">
            <a class="nav-link" [ngClass]="activatedTab === 0 ? 'active' : ''">
              <div class="tab-content">
                <div class="img-section">
                  <img
                    src="../../../assets/images/codeEnhance.png"
                    alt="Image 0"
                    class="tab-image"
                    style="width: 26px"
                  />
                </div>
                <span class="tab-text">Code<br />enhancement</span>
              </div>
            </a>
          </li>
          <!-- <li  *ngIf="!title" class="nav-item" (click)="setTab(0)">
            <a class="nav-link" [ngClass]="activatedTab === 0 ? 'active' : ''">
              <div class="tab-content">
                <div class="img-section">
                  <img
                    src="../../../assets/images/buildProject.png"
                    alt="Image 2"
                    class="tab-image"
                  />
                </div>
                <span class="tab-text">Build your<br />project</span>
              </div>
            </a>
          </li> -->




          <li  class="nav-item" (click)="setTab(1)">
            <a class="nav-link" [ngClass]="activatedTab === 1 ? 'active' : ''">
              <div class="tab-content">
                <div class="img-section">
                  <img
                    src="../../../assets/images/codeDocunused.png"
                    alt="Image 1"
                    class="tab-image"
                  />
                </div>
                <span class="tab-text">Code<br />documentation</span>
              </div>
            </a>
          </li>
          <li *ngIf="selectedfunction =='LDT'" id="dmbtn"  class="nav-item" (click)="setTab(2)">
            <a class="nav-link" [ngClass]="activatedTab === 2 ? 'active' : ''">
              <div class="tab-content">
                <div class="img-section">
                  <img
                    src="../../../assets/images/buildProject.png"
                    alt="Image 2"
                    class="tab-image"
                  />
                </div>
                <span class="tab-text">Build your<br />project</span>
              </div>
            </a>
          </li>
          <li    class="nav-item" (click)="setTab(3)">
            <a class="nav-link" [ngClass]="activatedTab === 3 ? 'active' : ''">
              <div class="tab-content">
                <div class="img-section">
                  <img
                    src="../../../assets/images/devTalk.png"
                    alt="Image 3"
                    class="tab-image"
                    style="width: 37px"
                  />
                </div>
                <span class="tab-text">codeGenY Lite</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="tab-section">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div *ngIf="activatedTab === 0">
              <!-- <app-card [cardTitle]="homeCardData.cardTitle" [cardContent]="homeCardData.cardContent" [cardButton]="homeCardData.cardButton" [cardClass]="homeCardData.cardClass"></app-card> -->
              <div class="custom-card" >
                <p class="card-title">Code enhancement</p>
                <p class="card-content">Boost productivity by automating bug resolution and code refinement, saving development hours and reducing the costs associated with manual debugging and code improvement.</p>
                <button class="tab-btn" (click)="openEnhanceCode()">Enhance your code</button>


              </div>

              <!-- <div class="custom-card" *ngIf="!title">
                <p class="card-title">Build your project</p>
                <p class="card-content">
                  Streamline the entire development life cycle, from requirement documentation to code generation. Enhance productivity by consolidating processes and reduce costs through a seamless, integrated solution.
                </p>
                <button class="tab-btn" (click)="openBIP()">Start building your project</button>
              </div> -->
            </div>

            <div *ngIf="activatedTab === 1">
              <div class="custom-card" >
                <p class="card-title">Code documentation</p>
                <p class="card-content">
                  Increase team efficiency by automating code documentation, facilitating quicker onboarding and collaboration. Reduce costs related to time-consuming manual documentation efforts.
                </p>
                <button class="tab-btn" (click)="openCodeDoc()">Start code documentation</button>
              </div>
            </div>

            <div *ngIf="activatedTab === 2">
              <div class="custom-card">
                <p class="card-title">Build your project</p>
                <p class="card-content">
                  Streamline the entire development life cycle, from requirement documentation to code generation. Enhance productivity by consolidating processes and reduce costs through a seamless, integrated solution.
                </p>
                <button style="width:205px;" class="tab-btn" (click)="openBIP()">Build your project</button>

                <!-- <button style="margin-top: 1px;width:205px;" class="tab-btn " (click)="openBIP1()">Build your IT  project</button> -->

              </div>
            </div>

            <div *ngIf="activatedTab === 3">
              <div class="custom-card">
                <p class="card-title">codeGenYLite</p>
                <p class="card-content">
                  Improve developer efficiency with instant, AI-powered chat
                  support, minimizing downtime and increasing productivity. Cut
                  operational costs by providing quick resolutions to coding
                  queries.
                </p>
                <button class="tab-btn" (click)="openDevTalk()">Let's chat</button>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div *ngIf="activatedTab === 0">
              <div class="custom-card">
                <div class="divider" style="height:420px!important;">
                <p class="card-title">Features</p>

                <p class="card-content-bulleted">
                  - Bugs<br> - Inline commentary<br> - Consistency in naming conventions<br>
                  - Code modularity<br> - Error handling mechanisms<br> - Code
                  efficiency<br> - Use of deprecated or unsupported libraries<br> - Code
                  duplication<br> - Logical flow and cohesion
                </p></div>
                <!-- <div class="divider"></div> -->
              </div>
            </div>

            <div *ngIf="activatedTab === 1">
              <div class="custom-card">
                <div class="divider" style="height:420px!important;">
                <p class="card-title">Features</p>

                <p class="card-content-bulleted">
                  - Comments<br> - Function descriptions<br> - Code structure overview<br> -
                  Variable and constant descriptions<br> - Usage examples -
                  Dependencies<br> - Installation and setup instructions<br> -
                  Contributing guidelines<br> - Changelog<br> - License information
                </p>
              </div>
              </div>
            </div>

            <div *ngIf="activatedTab === 2">
              <div class="custom-card">
                <div class="divider" style="height:420px!important;">
                <p class="card-title">Features</p>

                <p class="card-content-bulleted">
                  - Seamless project kickoff<br> - Rapid project timeline
                  acceleration<br> - Crystal clear project roadmap<br> - Cost effective
                  automated processes<br> - Swift project delivery promotion
                </p>
              </div>
              </div>
            </div>

            <div *ngIf="activatedTab === 3">
              <div class="custom-card">
                <div class="divider">
                <p class="card-title">Features</p>

                <p class="card-content-bulleted">
                  - Real-Time issue resolution<br> - Guidance on coding best
                  practices<br> - Efficient learning curve<br> - Enhanced code quality<br> -
                  Increased development speed<br> - Context-aware support<br> - 24/7
                  developer assistance<br> - Improved collaboration<br> - Reduction in
                  development errors<br> - Increased developer confidence<br> -
                  Continuous skill enhancement<br> - Adaptability to various
                  development stacks<br> - Enhanced problem-solving skills<br> - Reduced
                  onboarding time for new developers<br> - Developer satisfaction
                  and well-being
                </p>
              </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div
              *ngIf="
                activatedTab === 0 ||
                activatedTab === 1 ||
                activatedTab === 2 ||
                activatedTab === 3
              "
            >
              <div class="custom-card" style="text-align: center">
                <img
                  src="../../../assets/images/chart.png"
                  alt=""
                  style="width: 120px"
                />
                <p class="card-title-fixed">
                  ELEVATE YOUR CODING EXPERIENCE WITH codeGenY Lite
                </p>
                <p class="card-content">
                  codeGenY Lite is more than just a code generator; it’s your
                  indispensable partner in coding and code management. With its
                  unmatched intelligence, codeGenY Lite understands your project’s
                  unique requirements, communicates with you to clarify any
                  doubts, and adapts to your existing infrastructure. It can
                  write code in languages like VBA and Python, making it a
                  versatile tool for your development needs
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="feature-container" class="features-section pt-2" style="margin-top: -40px;">
    <div class="container">
      <div class="feature-header">
        <p class="feature-header-one">Key Features</p>
        <p class="feature-header-two">
          Single platform to enhance your code, create code document and your
          project document.
        </p>
      </div>
      <div class="box-info">
        <div class="card-info" *ngFor="let card of cardsData; index as i">
          <app-card
            [iconImage]="card.iconImage"
            [title]="card.title"
            [content]="card.content"
          ></app-card>
        </div>
      </div>
    </div>
  </div>
  <div class="black-section">
    <div class="container">
      <p>
        Whether you’re a seasoned developer or just starting, codeGenY Lite is your
        trusted partner from requirement gathering to code generation & beyond.
        Say hello to a new era of coding excellence with codeGenY Lite.
      </p>
    </div>
  </div>
  <div class="collaboration-section">
    <div class="collaboration">
      <div class="container">
        <p class="collab-head">In collaboration with:</p>
        <!-- <div class="row"> -->
          <!-- <div class="col-md-6"> -->
            <div class="collab-left">
              <img src="../../../assets/images/collaboration.png" alt="" />
              <!-- <p>
                LDT is the team that helps drive transformation for clients by leading the interplay of Lean Six Sigma,
              Digital and Data Analytics to deliver value through actionable insights.
              </p> -->
            </div>
          <!-- </div> -->
          <!-- <div class="col-md-6">
            <div class="collab-right">
              <img
                src="../../../assets/images/aiLab - Logo designs-Light@2x.png"
                alt=""
              />
              <p>
                LDT aiLab is a vibrant and dynamic group dedicated to exploring
                the cutting-edge realm of Artificial Intelligence. Our primary
                focus is on crafting innovative solutions that seamlessly embed
                AI & Analytics into operations. We are driven by the mission to
                make advanced technology accessible to all through user-friendly
                packages. At aiLab, continuous learning is at the heart of our
                endeavors, empowering us to thrive and push the boundaries of AI
                to new horizons
              </p>
            </div>
          </div> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
  <div class="home-footer">
    <div class="relentless">
      <div class="container">
        <div class="row">
          <div class="logo">
            <img
              src="../../../assets/images/genpact-vertical-logo.svg"
              alt=""
            />
          </div>
          <div class="relentless-font-style">
            <p>
              The relentless pursuit of a world that works
              <span>better for </span> <span>people</span>
            </p>
            <img
              src="../../../assets/images/underline-white.svg"
              alt=""
              style="width: 206px; margin-left: 32rem; margin-top: -4.7rem"
            /><img />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer *ngIf="userAccessStatus"></app-footer>


<div class="col-12 p-5 text-center" *ngIf="!userAccessStatus">
  <div class="row  mt-5">
    <div class="col-12">
      <h5 class="display-12 pl-5 headtext">
        User doesn't have permission to access codeGenYLite
      </h5>

    </div>
  </div>
</div>