import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class SopService {
  activeUserRole = new BehaviorSubject({});
  pageTitle = new BehaviorSubject({ 'title': 'Konverse AI' });

  uploadBYOD(currentFile: any, arg1: string, isImageExits: any) {
    throw new Error('Method not implemented.');
  }
  endpoint = environment.nodeEndpoint;
  apiURL = environment.cgyurl;
  //endpoint1=environment.apiURL;

  constructor(private http: HttpClient, private router: Router) { }

  sendBYODRequests(userquery: string) {
    let data = {
      "kbname": sessionStorage.getItem('kbname'),
      "userquery": userquery,
      "filename": localStorage.getItem('fileName')
    };
    return this.http.post(`${this.endpoint}byod-answer`, data).pipe(catchError(this.handleError))
  }


  sendFormRequests(userquery: string) {
    let data = {
      "kbname": sessionStorage.getItem('kbname'),
      "userquery": userquery
    };
    return this.http.post(`${this.endpoint}byod-answer`, data).pipe(catchError(this.handleError))
  }

  uploaddocx(file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(`${this.apiURL}/extracttext`, formData).pipe(catchError(this.handleError))
  }

  checkFileStatus() {

    return this.http.get(`${this.endpoint}file-status`).pipe(catchError(this.handleError))
  }

  downloadSop(file: File): Observable<ArrayBuffer> {
    const url = `${this.apiURL}/submitting?fileId=${file.name}`;
    return this.http.get(url, { responseType: 'arraybuffer' })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400) {
            return throwError('Bad Request: Please check your input.');
          } else if (error.status === 500) {
            return throwError('Internal Server Error: Something went wrong on the server.');
          } else {
            return throwError('An unexpected error occurred.');
          }
        })
      );
  }


  uploadSop(file: File) {
    console.log('api call ')
    const formData = new FormData();
    formData.append('file_new', file, file.name);
    //formData.append('with_image', imageExists ? 'true' : 'false');
    //with_image
    //if(imageExists) formData.append('filename file_image', file.name);
    //http://127.0.0.1:8010/submitting
    const req = new HttpRequest('POST', `${this.apiURL}/uploading`, formData, {
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }
  //file anageent sop-Admin
  filelist(payload: any) {

    const url = `${this.apiURL}/konverse/doc-list`;
    return this.http.post<any>(url, payload);
  }
  delfilelist(payload: any) {

    const url = `${this.apiURL}/konverse/del-doc-list`;
    return this.http.post<any>(url, payload);
  }
  uploadfilelist(payload: any) {

    const url = `${this.apiURL}/konverse/add-doc`;
    return this.http.post<any>(url, payload);
  }
  // complance services

  complanceChat(app: any, messages: any, source = '') {
    let payload: any = { message: messages };
    if (source) payload['source'] = source;

    return this.http.post(`${this.endpoint}pdf-search?app=${app}`, payload).pipe(catchError(this.handleError))
  }

  complanceChatStats() {
    const url = `${this.apiURL}/queryVS-status`;
    return this.http.get<any>(url);
  }




  createUserHistory(payload: any): Observable<any> {
    const url = `${this.endpoint}create-rag-user-history`;
    return this.http.post<any>(url, payload);
  }

  getBotHistory(payload: any): Observable<any> {
    const url = `${this.endpoint}functional_bot`;
    return this.http.post<any>(url, payload);
  }


  getUserHistory(): Observable<any> {
    const url = `${this.endpoint}rag-user-history`;
    return this.http.get<any>(url);
  }



  private handleError(error: HttpErrorResponse) {
    let errMessage = '';
    console.log('error', error);
    if (error?.status === 0) {
      console.error('An error occurred:', error.error);
    } else {

      if (error?.status === 409 || error?.status === 401) {
        errMessage = error.status.toString();
        return throwError(() => new Error(errMessage));
      }
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    errMessage = 'Please try again later';
    return throwError(() => new Error(errMessage));
  }

  getRagUserRoles(): Observable<string[]> {
    return this.http.get<any[]>(`${this.endpoint}get-raguser-role`);
  }

  getRagUserBots(): Observable<string[]> {
    return this.http.get<any[]>(`${this.endpoint}get-raguser-bots`);
  }
  //update user
  postUser(payload: any): Observable<any> {
    return this.http.post<any>(`${this.endpoint}update-raguser`, payload);

  }
  //create user
  createUser(payload: any): Observable<any> {
    return this.http.post<any>(`${this.endpoint}create-raguser`, payload);


  }
  userlist(Id: any, search = ''): Observable<string[]> {
    let query = `${this.endpoint}get-raguser?process_id=${Id}`;
    if (search) query = query + '&search=' + search;

    return this.http.get<any[]>(query);
  }

  deleteUser(payload: any): Observable<any> {
    return this.http.post<any>(`${this.endpoint}delete-raguser-role`, payload);

  }

  getQuestion(payload: any): Observable<any> {
    return this.http.post<any>(`${this.endpoint}function-type-question`, payload);
  }
  downloadzip(payload: any): Observable<any> {
    let token: any = localStorage.getItem('token');
    const zipurl = `${this.apiURL}/workspace-zip`;
    // return this.http.post<any>(`${this.endpoint}/workspace-zip`, payload);

    return this.http.post(zipurl, payload, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  }

  generatebrd(payload: any): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/generate_geny_brd`, payload);
  }

  brdstatus(payload: any): Observable<any> {
    return this.http.post(`${this.apiURL}/brd_document_status`, payload);
  }

  generatera(payload: any): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/generate_geny_ra_in`, payload);
  }
  generateraold(payload: any): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/generate_geny_ra`, payload);
  }

  rastatus(payload: any): Observable<any> {
    return this.http.post(`${this.apiURL}/ra_document_status_in`, payload);
  }
  rastatusold(payload: any): Observable<any> {
    return this.http.post(`${this.apiURL}/ra_document_status`, payload);
  }

  downloadbrd(payload: any): Observable<any> {
    return this.http.post(`${this.apiURL}/brd_document_status`, payload,
      {
        responseType: 'blob'
      });
  }

  downloadra(payload: any): Observable<any> {
    return this.http.post(`${this.apiURL}/ra_document_status_in`, payload,
      {
        responseType: 'blob'
      });
  }

  downloadraold(payload: any): Observable<any> {
    return this.http.post(`${this.apiURL}/ra_document_status`, payload,
      {
        responseType: 'blob'
      });
  }

  generatesdd(payload: any): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/generate_geny_sdd`, payload);
  }

  sddstatus(payload: any): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/sdd_document_status`, payload);
  }

  generateuserstory(payload: any): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/generate_user_story`, payload);
  }
  userstorystatus(payload: any): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/userstory_status`, payload);
  }

  downloadsdd(payload: any): Observable<any> {
    return this.http.post(`${this.apiURL}/sdd_document_status`, payload, {
      responseType: 'blob'
    });
  }

  downloaduserstory(payload: any): Observable<any> {
    return this.http.post(`${this.apiURL}/userstory_status`, payload, {
      responseType: 'blob'
    });
  }

  clarifyapistatus(payload: any): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/clarify_api_status`, payload);
  }
  generateinitialcodestatus(payload: any): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/generate_initial_code_status`, payload);
  }

  logsStorage(payload: any): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/store_logs`, payload);
  }
  // chatcode (payload: any): Observable<any> {
  //   return this.http.post<any>(`${this.apiURL}/get_gpt_answer`, payload);
  // }

  chatcode(payload: FormData): Observable<any> {
    return new Observable(observer => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${this.apiURL}/get_gpt_answer`, true);
      xhr.setRequestHeader('authorization', 'Bearer ' + localStorage.getItem('token'));
      // Remove or comment out the Content-Type header for FormData  
      // xhr.setRequestHeader('Content-Type', 'application/json');    

      let buffer = '';
      let lastProcessedIndex = 0;

      xhr.onprogress = () => {
        const newData = xhr.responseText.slice(lastProcessedIndex);
        buffer += newData;
        lastProcessedIndex = xhr.responseText.length;

        let newlineIndex;
        while ((newlineIndex = buffer.indexOf('\n')) !== -1) {
          const chunk = buffer.slice(0, newlineIndex).trim();

          buffer = buffer.slice(newlineIndex + 1);

          if (chunk) {
            try {
              const data = JSON.parse(chunk);
              observer.next(data);
            } catch (error) {
              console.error('Error parsing chunk:', chunk, error);
            }
          }
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          if (buffer.trim()) {
            try {
              const data = JSON.parse(buffer.trim());
              observer.next(data);
            } catch (error) {
              console.error('Error parsing final chunk:', buffer, error);
            }
          }
          observer.complete();
        } else {
          // Create a custom error object  
          const error: any = new Error(`HTTP error! status: ${xhr.status}`);
          error.status = xhr.status; // Add the HTTP status code  
          error.statusText = xhr.statusText; // Add the status text  

          // Attempt to parse the server's error response  
          try {
            error.error = JSON.parse(xhr.responseText); // Parse JSON error response from server  
          } catch (e) {
            // If parsing fails, provide a fallback error message  
            error.error = { message: xhr.statusText || 'An error occurred' };
          }

          // Pass the custom error object to the observer's error handler  
          observer.error(error);
        }
      };

      xhr.onerror = () => {
        observer.error(new Error('XHR error'));
      };

      // Send the FormData directly without stringifying  
      xhr.send(payload);

      return () => {
        xhr.abort();
      };
    });
  }


  crateHistory(payload: any) {
    return this.http.post(`${this.apiURL}/insert_assist_logs`, payload);
  }

  extract(payload: any) {
    return this.http.post(`${this.apiURL}/extract_file_content`, payload);
  }

  updateHistory(payload: any) {
    return this.http.post(`${this.apiURL}/update_assist_records`, payload);
  }

  getHistory() {
    return this.http.get(`${this.apiURL}/show_assist_details`);
  }

  deleteUserHistory(payload: any) {
    return this.http.post(`${this.apiURL}/delete_assist_records`, payload);
  }

  getDetailHistory(payload: any) {
    return this.http.post(`${this.apiURL}/old_chat`, payload);
  }

  getdocumentation(payload: any): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/get_documentation`, payload);
  }
  getdocumentationstatus(): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/get_documentation_status`, {});
  }
  downloadcodedocumentation(): Observable<any> {
    return this.http.post(`${this.apiURL}/get_documentation_download`, {}, {
      responseType: 'blob'
    });
  }
  getenhancement(payload: any): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/get_enhancement`, payload);
  }




  getenhancementstatus(): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/get_enhancement_status`, {});
  }

  getcomment(payload: any): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/code_analyze`, payload);
  }

  getcommenttstatus(): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/code_analyze_status`, {});
  }

  sanalysis(payload: any): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/code_security`, payload);
  }

  sanalysisstatus(): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/code_security_status`, {});
  }
  codeadjust(payload: any): Observable<any> {
    // return this.http.post<any>(`${this.apiURL}/code_adjust`, payload);
    //code_enh_adj
    return this.http.post<any>(`${this.apiURL}/code_enh_adj`, payload);

  }

  codeadjuststatus(): Observable<any> {
    // return this.http.post<any>(`${this.apiURL}/code_adjust_status`, {});
    return this.http.post<any>(`${this.apiURL}/code_enh_adj_status`, {});

  }

  getdomain(): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/check_domain`, {});
  }
  uploadtoSharepoint(payload: any): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/uploadfile_sharepoint_in`, payload);
  }

  //insert brd logs

  insertbrd(payload: any) {
    return this.http.post<any>(`${this.apiURL}/insert_brd_logs`, payload);

  }

  showbrd() {
    return this.http.get(`${this.apiURL}/show_brd_logs`);
  }

  //change_brd_status
  deletebrd(payload: any) {
    return this.http.post<any>(`${this.apiURL}/change_brd_status`, payload);

  }

  //insert ra logs

  insertra(payload: any) {
    return this.http.post<any>(`${this.apiURL}/insert_ra_logs`, payload);

  }

  showra() {
    return this.http.get(`${this.apiURL}/show_ra_logs`);
  }

  showralog() {
    return this.http.get(`${this.apiURL}/show_ra_logs_file`);
  }

  //change_ra_status
  deletera(payload: any) {
    return this.http.post<any>(`${this.apiURL}/change_ra_status`, payload);

  }


  getcoversion(payload: any): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/get_conversion`, payload);
  }
  getcoversionstatus(): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/get_conversion_status`, {});
  }
  //preview api
  brdpreview(): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/brd_document_preview`, {});
  }

  sddpreview(): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/sdd_document_preview`, {});
  }

  rapreview(): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/ra_document_preview`, {});
  }

  rainpreview(): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/ra_document_preview_in`, {});
  }

  uspreview(): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/userstory_preview`, {});
  }
  tcpreview(): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/testcase_preview`, {});
  }

  insertfeedback(payload: any) {
    return this.http.post<any>(`${this.apiURL}/insert_feedback_logs`, payload);

  }



}
