<!-- app.component.html -->
<div class="container-row">
  <div class="container-col">
    <div class="row">
      <div class="col-12 mt-2">
        <div class="header" *ngIf="!showInstructions">
          <h1 class="gradient-text" style="font-size: 23px;">Collaborative Research Agent</h1>
        </div>
      </div>

      <div class="container mt-5 text-align-center" *ngIf="showInstructions">
        <div style="display: flex; justify-content: center;">
          <div class="instructions-box">
            <h4 class="chat1-header" style="text-align: center;">
              <span class="greeting" style="font-size: 25px;">Collaborative Research Agent</span><br><br>
              <span class="question"><b>Hello! I am your dedicated research agent. Please share the topic you're interested in, and I will provide you with a comprehensive and detailed report. Let's get started!</b></span>
            </h4><br>
            <pre style="white-space: pre-wrap; text-align: left; position: center; background: white; color: black; font-family: georgia-pro; margin-left: 58px;">
<b>Planner's Role:</b>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The planner coordinates and assigns tasks to the specialized agents in the following sequence:
<b>1. Web Surfer Agent</b>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Responsibility: <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace;Gathers the latest and most relevant information from the internet based on the provided query.
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tasks:<br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace;Conducts online searches. Navigates relevant websites and databases. Collects and organizes pertinent data and resources.
<b>2. Research Writer Agent</b>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Responsibility: <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace;Compiles the gathered information into a structured and coherent research report.
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tasks:<br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace;Analyzes and synthesizes collected data. Formats and structures the content. Ensures the report is comprehensive and well-organized.
<b>3. Report Reviewer Agent</b>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Responsibility:<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace; Evaluates the research report to ensure it meets high-quality standards.
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tasks:<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace;&NonBreakingSpace; Reviews the report for completeness and accuracy. Checks for proper formatting and adherence to guidelines. Provides feedback and suggests additional queries or revisions if necessary.
            </pre>
          </div>
        </div>
      </div>

      <div class="chat-scroll smooth-scroll" #scrollChat style="background-color: #ffffff">
        <div class="col-12">
          <div class="row" style="gap: 20px;">
            <ng-container *ngFor="let chat of chatData; let i = index">
              <div class="col-12" *ngIf="chat.role == 'user'">
                <div class="user-chat float-right" markdown mermaid lineNumbers clipboard>
                  {{ chat.content.split(':')[0] }}
                </div>
              </div>
              <div class="col-12" *ngIf="chat.role != 'user'">
                <div class="bot-chat" *ngIf="chat?.section != 'question' && chat?.section != 'sources'" markdown mermaid lineNumbers clipboard>
                  {{ chat.content.split(':')[0] }}:
                  <div style="white-space: pre-line">{{ chat.content.split(':').slice(1).join(':') }}</div>
                </div>
                <div class="bot-chat" *ngIf="chat?.section == 'sources'">
                  <h6>{{ chat?.title }}</h6>
                  <div class="row prompts">
                    <div class="col-6 prompt" *ngFor="let item of chat?.content">
                      <div *ngIf="item.imageTag.startsWith('<img')" style="display: flex; gap: 5px;">
                        <div [innerHTML]="item.imageTag"></div>
                        <a class="ellipsis" href="{{ item?.url }}" target="_blank">{{ item.name }}</a>
                      </div>
                      <div *ngIf="item.imageTag.startsWith('<svg')">
                        <div style="display: flex; gap: 10px;">
                          <img src="../../../assets/images/icons/search web 1.svg" alt="search web" class="image-icon">
                          <a class="ellipsis" href="{{ item?.url }}" target="_blank">{{ item.name }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bot-chat" *ngIf="chat?.section == 'question'">
                  <div class="language-buttons">
                    <h6>{{ chat?.title }}</h6>
                    <div class="question-list" (click)="inputPrompt(question)" *ngFor="let question of chat?.content">
                      <span>{{ question }}</span>
                    </div>
                  </div>
                </div>
                <div class="user-action" *ngIf="chat?.section != 'question' && chat?.section != 'sources'">
                  <div class="btn btn-sm" (click)="copyToClipboard(chat?.content)" title="Copy to clipboard">
                    <img src="/assets/images/icons/copy.svg" alt="copy">
                  </div>
                  <div class="btn btn-sm" (click)="regenerate(i)">
                    <img src="/assets/images/icons/refresh.svg" alt="refresh" title="Regenerate">
                  </div>
                </div>
              </div>
            </ng-container>
            <br><br><br><br><br><br><br><br>
          </div>
        </div>
      </div>
    </div>

    <!-- text area user input -->
    <div *ngIf="selectedFiles">
      <br><br><br><br><br><br>
    </div>
    <div class="question-block">
      <div class="row">
        <div class="offset-lg-1 col-lg-10 col-12">
          <div class="row">
            <div class="col-12">
              <div class="chat-container">
                <div class="new-topic" (click)="newChat()">
                  <img class="w-100" placement="top" ngbPopover="New Topic" popoverClass="pop-block" triggers="mouseenter:mouseleave" src="assets/images/new_chat.png" alt="">
                </div>
                <div class="ask-question">
                  <div class="query-section">
                    <div class="upload_file">
                      <div class="file-upload-block pb-2" *ngFor="let file of selectedFiles">
                        <div class="file_icon_remove" (click)="uploadFile('reset')">
                          <img src="/assets/images/icons/cross.svg">
                        </div>
                        <div class="file_icon">
                          <img src="/assets/images/icons/file_upload.svg">
                        </div>
                        <div class="file-detail">
                          <div [title]="file?.name" class="file-lable ellipsis">{{ file?.name }}</div>
                          <div [title]="file?.type" class="file-type ellipsis">{{ file?.type }}</div>
                        </div>
                      </div>
                    </div>
                    <textarea maxlength="500" id="textArea" rows="1" class="form-control" placeholder="Ask your question" (input)="autoResize($event)" (keyup.enter)="handleEnter()" [(ngModel)]="prompt" (focus)="isPromptActive = true" (blur)="isPromptActive = false"></textarea>
                  </div>
                  <button class="btn" (click)="sentToInput()">
                    <img src="/assets/images/icons/send.svg" alt="">
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row px-4">
            <div class="col-12"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isLoading" class="overlay">
  <div class="loader"></div>
</div>
