import { Component } from '@angular/core';

@Component({
  selector: 'app-crewstudio',
  standalone: false,
  
  templateUrl: './crewstudio.component.html',
  styleUrl: './crewstudio.component.scss'
})
export class CrewstudioComponent {
  selectedTab: { label: string; value: string } | null = null;

  onTabSelected(tab: { label: string; value: string }) {
    this.selectedTab = tab;
  }
}

