import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
// import { FormControl, FormGroup } from '@angular/forms';
import { faRobot } from '@fortawesome/free-solid-svg-icons/faRobot';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
//import { faDocker } from '@fortawesome/free-brands-svg-icons/faDocker';
import { faCheck, faClose, faEdit, faPlay, faCode, faCoffee, faTasks, faDownload, faL } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from 'src/app/shared/Utils/CommonService';
import { Constants } from 'src/app/shared/Utils/Constants';
// import { faL } from '@fortawesome/free-solid-svg-icons';
// import '../../../assets/css/style.css';
// import { Document, Paragraph, Packer, TextRun, File } from 'docx';
// import { saveAs } from 'file-saver';
// import { HtmlParser } from '@angular/compiler';
// import { asBlob } from 'html-docx-js-typescript';
// import { nextLine } from 'pdf-lib';
import Swal from 'sweetalert2';

import { DomSanitizer } from '@angular/platform-browser';
// import { text } from '@fortawesome/fontawesome-svg-core';
import * as FileSaver from 'file-saver';
import { interval, Subscription } from 'rxjs';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { ToastMessageService } from 'src/app/shared/service/toast-message.service';
import  {SopService} from 'src/app/shared/service/sop.service';
import * as Docxtemplater from 'docxtemplater';
import { Document, Packer, Paragraph } from "docx";
import saveAs from 'file-saver';
import JSZip from 'jszip';
import * as xmljs from 'xml-js';

@Component({
    selector: 'app-apigenerate',
    templateUrl: './apigenerate.component.html',
    styleUrls: ['./apigenerate.component.scss'],
    standalone: false
})
export class ApigenerateComponent implements OnInit, OnDestroy {
  @ViewChild('messageContainer') messageContainer!: ElementRef;
  private apiPollingTimer!: Subscription;
  redditIcon = faRobot;
  odnoklassniki = faUser;
  spinner = faSpinner;
  faPaperPlane = faPaperPlane;
  faCoffee = faCoffee;
  faCheck = faCheck;
  faClose = faClose;
  faEdit = faEdit;
  faPlay = faPlay;
  faCode = faCode;
  faTask = faTasks;
  // faDocker = faDocker;
  faDownload = faDownload;
  UserDiv: any;
  UserQuesDiv: any;
  questionsArray: any[] = [];
  quesTableData: any;
  DevReqText: any = '';
  // DevReqData: string = '';
  DevUserReqData: any;
  userReq: boolean = false;
  // quesTable: boolean = false;
  userQuesReq: boolean = false;
  // DevReq: boolean = false;
  QuesIsDisable: boolean = false;
  isLoading: boolean = false;
  DevReqDataEnable: boolean = false;
  workLocation: any;
  preReq: any;
  uploadedContent: string = '';
  docname: any;
  docname1: any;
  temptext: any;
  savebtn: boolean = false;
  formData = {
    userPrompt: ''
  }
  userOHR: any;
  filepath1: any;
  fileData: any;
  filestatus: boolean = true;
  filestatus1: boolean = true;
  squery:any;
  stext:any;
  isspinner:boolean=false;
  processName: any;

  constructor(public commonService: CommonService, public _sanitizer: DomSanitizer,
    public loaderService: LoaderService, private toastService: ToastMessageService,
    private sopService: SopService) {
  }

  ngOnInit(): void {
    this.getOHR();
   this.squery= localStorage.getItem('userPrompt');
   this.stext= localStorage.getItem('userreq');
   // this.startApiPolling();

  }

  ngOnDestroy(): void {
    // Stop API polling when the component is destroyed
    this.stopApiPolling();
  }
  startApiPolling() {
    // Call the createWorkbench API every 15 seconds
    this.apiPollingTimer = interval(15000).subscribe(() => {
      this.CreateWorkbench1();
    });

    // Stop the API polling after 3 to 4 minutes (adjust as needed)
    setTimeout(() => {
      this.stopApiPolling();
      console.log('API polling stopped after 3 to 4 minutes.');
    }, 60000); // 1 minute in milliseconds
  }
  stopApiPolling() {
    if (this.apiPollingTimer) {
      this.apiPollingTimer.unsubscribe();
    }
  }

  scrollToBottom(): void {
    try {
      this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error(err);
    }
  }

  promptSearchHidden: boolean = false;
  promptError: boolean = false;
  SubmitClick() {
    if (this.formData.userPrompt == '') {
      this.promptError = true;
    } else {
      this.promptError = false;
      this.promptSearchHidden = true;
      this.userReq = true;
      this.UserDiv = this.formData.userPrompt;
      this.formData.userPrompt = '';
      // this.quesTable = true;
      this.isLoading = true;
      this.getUserPromptResponse(this.UserDiv);
      setTimeout(() => { this.scrollToBottom() }, 500);
    }
  }

  getUserPromptResponse(userPrompt: any) {
    let url = Constants.config.UserPromptUrl;
    let body = {
      userPrompt: userPrompt
    }

    this.commonService.postMethodAxios(url, body).then((res: any) => {
      console.log(res);
      this.questionsArray = res.data;
      if (this.questionsArray.length > 0) {
        this.isLoading = false;
      }
      setTimeout(() => { this.scrollToBottom() }, 500);

    }).catch((err: any) => {
      console.log(err);
      setTimeout(() => { this.scrollToBottom() }, 500);
    })

  }
  handleFileChange(event: any) {
    this.isLoading = true;
    const file = event.target.files[0];
    if (file) {
      this.docname = file.name;
      this.toastService.showmessage(file.name + ' is being uploaded');
      const extension = file.name.split('.').pop()?.toLowerCase();
      if (extension === 'txt') {
        this.readFile(file);
      } else if (extension === 'docx') {
        this.readDocxFile(file);
      } else {
        this.toastService.showError('Unsupported file format. Please upload only .txt, .docx files.');
      }
    }
  }

  readFile(file: File) {
   // this.filestatus=!this.filestatus;
   // this.filestatus1=!this.filestatus1;
    const reader = new FileReader();
    reader.onload = () => {
      this.uploadedContent = reader.result as string;
      this.stext = this.uploadedContent;
      this.toastService.showSuccess('File has been uploaded successfully');
      this.isLoading = false;
    };
    reader.readAsText(file);
  }

  // readDocxFile(file: File) {
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     const arrayBuffer = reader.result as ArrayBuffer;
  //     const byteArray = new Uint8Array(arrayBuffer);
  //     const content = this.extractTextFromDocx(byteArray);
  //     this.uploadedContent = content;
  //     this.stext=this.uploadedContent;
  //   };
  //   reader.readAsArrayBuffer(file);
  // }
  readDocxFile(file: File) {
    //this.loaderService.show();
    this.isspinner=true;
   this.sopService.uploaddocx(file).subscribe
    ((res: any) => {
      //this.loaderService.hide();
      this.isspinner=false;
      console.log(res);
      this.stext = res.data;
      this.toastService.showSuccess('File has been uploaded successfully');
      this.isLoading= false;
    },
    (error: any) => {
    //  this.loaderService.hide();
    this.isLoading=false;
    this.toastService.showError('Error in uploading file');
      console.log(error);
    }

    );



  }

//////////////////////
handleFileChange1(event: any) {
  this.isLoading= true;
  const file = event.target.files[0];
  if (file) {
    this.docname1 = file.name;
    this.toastService.showmessage(file.name + ' is being uploaded');
    const extension = file.name.split('.').pop()?.toLowerCase();
    if (extension === 'txt' ) {
      this.readFile1(file);
    } else if (extension === 'docx') {
      this.readDocxFile1(file);
    } else {
      alert('Unsupported file format. Please upload only .txt or .docx files.');
    }
  }
}

readFile1(file: File) {
 // this.filestatus=!this.filestatus;
 // this.filestatus1=!this.filestatus1;

  const reader = new FileReader();
  reader.onload = () => {
    this.uploadedContent = reader.result as string;
    this.squery = this.uploadedContent;
    this.toastService.showSuccess('File has been uploaded successfully');
    this.isLoading= false;
  };
  reader.readAsText(file);
}

// readDocxFile(file: File) {
//   const reader = new FileReader();
//   reader.onload = () => {
//     const arrayBuffer = reader.result as ArrayBuffer;
//     const byteArray = new Uint8Array(arrayBuffer);
//     const content = this.extractTextFromDocx(byteArray);
//     this.uploadedContent = content;
//     this.stext=this.uploadedContent;
//   };
//   reader.readAsArrayBuffer(file);
// }
readDocxFile1(file: File) {
  //this.loaderService.show();

 this.sopService.uploaddocx(file).subscribe
  ((res: any) => {
    //this.loaderService.hide();
    this.isspinner=false;
    console.log(res);
    this.squery = res.data;
    this.toastService.showSuccess('File has been uploaded successfully');
    this.isLoading= false;
  },
  (error: any) => {
  //  this.loaderService.hide();
  this.isspinner=false;
  this.toastService.showError('Error in uploading file');
    console.log(error);
  }

  );



}



  extractTextFromDocx(arrayBuffer: Uint8Array): string {
    const decoder = new TextDecoder('utf-8');
    const text = decoder.decode(arrayBuffer);
    return text;
  }

  QuesSubmit() {

    console.log(this.questionsArray);
    this.isLoading = true;
    this.QuesIsDisable = true;
    this.UserQuesDiv = this.questionsArray;
    this.userQuesReq = true;
    // this.DevReq = true;
    let url = Constants.config.DevReqUrl;
    let body = {
      userPrompt: this.UserDiv,
      detailedrequirements: this.questionsArray
    }

    this.commonService.postMethodAxios(url, body).then((res: any) => {
      console.log(res);

      // this.DevReqText.Prerequisites = (typeof res.data.development_requirements_detail["Prerequisites"] === "object") ? JSON.stringify(res.data.development_requirements_detail["Prerequisites"]) : res.data.development_requirements_detail["Prerequisites"];

      // this.DevReqText.detailedOperationSteps = (typeof res.data.development_requirements_detail["Detailed Operation Steps"] === "object") ? JSON.stringify(res.data.development_requirements_detail["Detailed Operation Steps"]) : res.data.development_requirements_detail["Detailed Operation Steps"];

      this.DevReqText = `Developement Requirement Overview:\n${res.data.development_requirements_overview}\n\nDevelopement Requirement Details-\nPrerequisites:\n${res.data.development_requirements_detail.Prerequisites}\n\nDetailed Operation Steps:\n${res.data.development_requirements_detail["Detailed Operation Steps"]}\n\nExpected Result:\n${res.data.development_requirements_detail["Expected Results"]}\n\nNotes:\n${res.data.development_requirements_detail["Other Explanatory Notes"]}`;

      // this.DevReqData = 'Prerequisites:<br/>' + res.data.development_requirements_detail.Prerequisites + '<br/>Detailed Operation Steps:<br/>' + res.data.development_requirements_detail["Detailed Operation Steps"];
      // this.DevReqData = res.data.development_requirements_detail.replace(/\n/g, '<br/>');
      if (this.DevReqText != '')
        this.isLoading = false;

      setTimeout(() => { this.scrollToBottom() }, 500);
    }).catch((err: any) => {
      console.log(err);
      setTimeout(() => { this.scrollToBottom() }, 500);
    })
    setTimeout(() => { this.scrollToBottom() }, 500);
  }

  quesAnsEdit(data: any, index: any) {
    console.log(data, index);
    this.questionsArray.filter((val: any, i: any) => {
      if (i == index)
        val.answer_sample = data;
    })

    console.log(this.questionsArray);

  }

  // reqType: string = 'DirectSubmit';
  preReqData: any = '';
  DevReqTextEdit: boolean = false;
  DevReqEdit() {
    // this.subType = 'DevReqSubmit';
    // this.reqType = 'Edited';
    this.DevReqTextEdit = true;
    // this.formData.userPrompt = this.DevReqText;
  }

  devReqbtn: boolean = false;



setquery(){
  localStorage.setItem('userPrompt', this.squery);
}









  DevReqSubmit() {
//handlefileupload
//if processname continue else throw error
//if processname is there then continue






this.filestatus1=!this.filestatus1;
this.filestatus=!this.filestatus;
// this.loaderService.loading.next(true);

//       if (event?.name) {
//         this.fileData = event;
//         console.log(this.fileData?.file, this.fileData?.name)

//       }
//       this.getOHR();

//       const formData = new FormData();
//       formData.append('ra_sheet', this.fileData?.file);
//this.loaderService.loading.next(true);
this.isspinner=true;
    this.devReqbtn = true;
    this.isLoading = true;
    this.isspinner = true;
    this.DevReqTextEdit = false;
    this.DevReqDataEnable = true;

    // this.DevReqText = document.getElementById('devReqTxt')?.innerText;
    this.DevReqText = this.stext;

    this.DevUserReqData = this.DevReqText.replaceAll('\n', '<br/>');

    //get userPrompt from localstorage
     this.UserDiv = localStorage.getItem('userPrompt') ? localStorage.getItem('userPrompt') : this.squery;

    // if (this.reqType == 'DirectSubmit') {
    //   this.DevUserReqData = this.DevReqData;
    // }
    // else {
    //   this.DevUserReqData = this.DevReqText;
    // }
    // this.formData.userPrompt = '';
    // this.DevUserReqData.replaceAll('<br/>', '\n');


    let req = `{"development_requirements_overview":'${this.UserDiv}',"development_requirements_detail":${this.DevReqText}}`;

    let obj = {
      "user_id":this.userOHR,
      development_requirements_detail: req,
      "process_name": this.processName
    }

    let url = Constants.config.clarifyApiUrl;
    console.log(obj);

    this.commonService.postMethodAxios(url, obj).then((res: any) => {
this.clarifyapistatus();
      console.log(res);
      this.preReqData = JSON.stringify(res.data, undefined, 4);
      console.log(this.preReqData);

      // this.preReq = `
      // <h5><b>The interface documentation has been generated, and once confirmed, the analysis of how to modify the program code will begin: </b></h5>
      // <br/>
      // <pre>${this.preReqData}</pre>
      // `;
      // this.isLoading = false;
      // this.loaderService.loading.next(false);
      // this.isspinner=false;

      setTimeout(() => { this.scrollToBottom() }, 500);

    }).catch((err: any) => {
      this.loaderService.loading.next(false);
      this.isLoading = false;
      this.isspinner=false;
      console.log(err);
      setTimeout(() => { this.scrollToBottom() }, 500);

    })
    setTimeout(() => {
      this.loaderService.loading.next(false);


      this.scrollToBottom() }, 500);
  }

  userDocCnfrm: boolean = false;
  gptCode: any[] = [];
  preReqTxtEdit: boolean = false;
  preReqBtn: boolean = false;

test(){
  this.toastService.showSuccess('changes saved successfully');
}

  DocCnfrm() {
    this.preReqData = document.getElementById('preReqTxt')?.innerText;
    this.preReqBtn = true;
    this.userDocCnfrm = true;
    this.isLoading = true;
    this.preReqTxtEdit = false;
    this.GenerateCode();

  }

  preReqEdit() {
    this.preReqTxtEdit = true;
    this.savebtn = true;
  }
  saveEdit(){
   this.temptext = document.getElementById('preReqTxt')?.innerText;
   // this.DevReqText = document.getElementById('preReqTxt')?.innerText;
    localStorage.setItem('api', this.temptext);
    this.savebtn = false;

  }
  GenerateCode() {
    let url = Constants.config.codeGenerationUrl;
    // let req = `
    // {'swagger_api':'${this.UserDiv}','development_requirements_detail':${this.DevUserReqData}}
    // `;
    let obj = {
      swagger_api: this.preReqData,
      development_requirements_detail: this.DevReqText,
      "process_name": this.processName
    }

    this.commonService.postMethodAxios(url, obj).then((res: any) => {
      console.log(res);
      this.filepath1=res.data.filepath;
      this.gptCode = res.data;
      if (this.gptCode.length > 0) {
        this.CreateWorkbench();
        setTimeout(() => { this.scrollToBottom() }, 500);
      }
    }).catch((err) => {
      console.log(err);
      setTimeout(() => { this.scrollToBottom() }, 500);
    })
    setTimeout(() => { this.scrollToBottom() }, 500);

  }


  // async DownloadUserRequirement() {

  //   console.log(this.DevReqText);
  //   this.DevReqText = document.getElementById('preReqTxt')?.innerText;
  //   // const text = "Your plain text goes here.";
  //   const filename = "API_design.doc";
  //   this.saveAsDoc(this.DevReqText, filename);

  //   // this.saveToFile(this.DevReqText, 'User_Requirements.docx');


  //   // const doc = new Document({
  //   //   sections: [
  //   //     {
  //   //       properties: {},
  //   //       children: [
  //   //         new Paragraph({
  //   //           text:this.DevReqText
  //   //         })
  //   //       ],
  //   //     },
  //   //   ],
  //   // });


  //   // Packer.toBlob(doc).then(blob => {
  //   //   console.log(blob);

  //   //   saveAs(blob, "User_Requirement.docx");
  //   //   console.log("Document created successfully");
  //   // });

  // }

  // saveAsDoc(text: string, filename: string) {
  //   const blob = new Blob([text], { type: 'application/msword' });
  //   FileSaver.saveAs(blob, filename);
  // }
  async DownloadUserRequirement() {

    console.log(this.DevReqText);
    this.DevReqText = document.getElementById('preReqTxt')?.innerText;

    const filename = "API_design.docx"; // Change the filename extension to .docx
    this.saveAsDocx(this.DevReqText, filename);

}

async saveAsDocx(text: string, filename: string) {
    // Create a new document
    const doc = new Document({
        sections: [
            {
                properties: {},
                children: [
                    new Paragraph({
                        text: text
                    })
                ],
            },
        ],
    });

    // Convert the document to a blob
    const blob = await Packer.toBlob(doc);

    // Save the blob as a file
    saveAs(blob, filename);
}

    // Download the file
    downloadFile(data: any): void {
  const payload = {
    "workspace_location": data
  }

      this.sopService.downloadzip(payload).subscribe(
        (response: any) => {
          // Create a Blob from the response
          const blob = new Blob([response], { type: 'application/zip' });

          // Create a link element, set its href, and simulate a click
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'code.zip'; // Set the desired file name
          document.body.appendChild(link);
          link.click();

          // Clean up resources
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);
        },
        (error) => {
          console.error('Error downloading file:', error);
          // Handle error as needed
        }
      );
    }

  // saveToFile(text: string, filename: string) {
  //   const element = document.createElement('a');
  //   const file = new Blob([text], { type: 'text/plain' });

  //   element.href = URL.createObjectURL(file);
  //   element.download = filename;

  //   document.body.appendChild(element);
  //   element.click();

  //   document.body.removeChild(element);
  // }
  getOHR() {
    let userData = localStorage.getItem('okta-token-storage');
    if (userData) {
      let ohr_id = JSON.parse(userData)?.accessToken?.claims?.OHR;
      if (ohr_id) {
        this.userOHR = ohr_id;
      }
    }
  }

  featureBranch: any;
  CreateWorkbench() {
    let url = Constants.config.createWorkbench;
const formData = new FormData();
    formData.append('user_id', this.userOHR);



    this.commonService.postMethodAxios1(url, formData).then((res: any) => {
      console.log(res);
      this.toastService.showSuccess(res.data.message);
      this.workLocation = res.data.workspace_location;
      // this.isLoading = false;
      this.featureBranch = res.data.feature_branch;
      if (this.workLocation != null || this.workLocation != undefined || this.workLocation != '') {

        let body = {
          filepath: this.filepath1,
          code: this.DevReqText
        }
        // this.saveCode(body);
        // this.Starting();
       this.startApiPolling();
       // this.CreateWorkbench1();
        // this.gptCode.forEach((ele: any) => {
        //   var rslt = this.checkCode(ele);
        //   console.log(rslt);

        //   var checklintrslt = this.checkLint(ele);
        //   console.log(checklintrslt);

        //   if (rslt != undefined || rslt != null || rslt != '' || checklintrslt != undefined || checklintrslt != null) {
        //     ele.selfCheck = rslt;
        //     ele.checklint = checklintrslt;
        //     ele.adjustCode = undefined;
        //   }
        //   // this.saveCode(ele);
        // });
        console.log(this.gptCode);

      }
    }).catch((err) => {
      console.log(err);

      this.isLoading = false;
    })
  }
  CreateWorkbench1() {
    this.isLoading = false;
    this.loaderService.loading.next(true);
    let url = Constants.config.createWorkbench1;
const formData = new FormData();
    formData.append('user_id', this.userOHR);



    this.commonService.postMethodAxios1(url, formData).then((res: any) => {
      console.log(res);
      this.workLocation = res.data.workspace_location;
      this.toastService.showSuccess(res.data.result !== true ?res.data.message:"Workspace has been created");
      this.featureBranch = res.data.feature_branch;
      //this.workLocation = res.data.reason;
      if(res.data.result == true){
        this.stopApiPolling();
      }
      this.isLoading = false;
      this.loaderService.loading.next(false);
      this.featureBranch = res.data.feature_branch;
      if (this.workLocation != null || this.workLocation != undefined || this.workLocation != '') {

        let body = {
          filepath: this.filepath1,
          code: this.DevReqText
        }
        this.saveCode(body);
        this.Starting();
        // this.saveCode(body);
        // this.Starting();
        // this.gptCode.forEach((ele: any) => {
        //   var rslt = this.checkCode(ele);
        //   console.log(rslt);

        //   var checklintrslt = this.checkLint(ele);
        //   console.log(checklintrslt);

        //   if (rslt != undefined || rslt != null || rslt != '' || checklintrslt != undefined || checklintrslt != null) {
        //     ele.selfCheck = rslt;
        //     ele.checklint = checklintrslt;
        //     ele.adjustCode = undefined;
        //   }
        //   // this.saveCode(ele);
        // });
        console.log(this.gptCode);

      }
    }).catch((err) => {
      console.log(err);

      this.isLoading = false;
    })
  }
  Starting(){
    this.gptCode.forEach((ele: any) => {
      var rslt = this.checkCode(ele);
      console.log(rslt);

      var checklintrslt = this.checkLint(ele);
      console.log(checklintrslt);

      if (rslt != undefined || rslt != null || rslt != '' || checklintrslt != undefined || checklintrslt != null) {
        ele.selfCheck = rslt;
        ele.checklint = checklintrslt;
        ele.IsRestartLoading = false;
        // ele.IsAdjusted = false;
        // ele.adjustCode = undefined;
      }
      // this.saveCode(ele);
    });
  }

  saveCode(data: any) {
    let url = Constants.config.saveCode;

    let body = {
      workspace_location: this.workLocation,
     // file_path: 'codes/' + data.filepath,
      file_path: data.filepath?data.filepath:'User_Requirement.doc',
      code: data.code
    }

    this.commonService.postMethodAxios(url, body).then((res: any) => {
      console.log(res);
    }).catch((err) => {
      console.log(err);
    })

  }

  pushCode() {
    let url = Constants.config.pushCode;

    let body = {
      workspace_location: this.workLocation,
      featureBranch: this.featureBranch
    }

    this.commonService.postMethodAxios(url, body).then((res: any) => {
      console.log(res);
      if (res.status == 200) {
        this.toastService.showSuccess(res.data.reason);
        Swal.fire('Thank you...', res.data.reason, 'success');
        this.downloadFile(this.workLocation);
      } else {
        Swal.fire('Sorry...', 'Something went wrong!', 'error');
      }
    }).catch((err) => {
      console.log(err);
      Swal.fire('Sorry...', 'Something went wrong!', 'error');
    })
  }

  editFileTaskPopup: boolean = false;
  editFileData: any;
  // editFileLoad: string = 'Loaded';
  adjustCodebtn(data: any) {
    this.editFileTaskPopup = true;
    const hbtn2 = document.getElementById('hbtn2') as HTMLButtonElement;
        if (hbtn2) {
            hbtn2.click(); // First click
            setTimeout(() => {
                hbtn2.click(); // Second click after a short delay
            }, 200); // Adjust the delay as needed
        }
    this.editFileData = data;
  }
  openModal() {
    this.editFileTaskPopup = true;
  }

  closeModal() {
    this.editFileTaskPopup = false;
    this.fileTaskError = false;
  }
  adjustCodeTableData: any;
  adjustCodeSubmit(path: any) {

    if (this.newFileTask == '' || this.newFileTask == undefined || this.newFileTask == null) {
      this.fileTaskError = true;
    }
    else {

      this.gptCode.filter((val: any) => {
        if (val.filepath == path) {
          val.adjustCode = this.newFileTask;
          val.IsAdjusted = true;
          // val.filepath = document.getElementById('filepathModified')?.innerText
        }
      })

      this.editFileTaskPopup = false;
    }

  }

  newFileTask: any;
  fileTaskError: boolean = false;
  EditFileTask(index:any) {

    this.gptCode.filter((v:any,i:any)=>{
      if(i == index){
        v.IsRestartLoading = true;
      }
    })

    // if (this.newFileTask == '' || this.newFileTask == undefined || this.newFileTask == null) {
    //   this.fileTaskError = true;
    // }
    // else {
    //   this.fileTaskError = false;
      // this.editFileLoad = 'Loading';
      let url = Constants.config.editFileTask;
      let data = {
        new_task: this.newFileTask, //document.getElementById("NewFileTask")?.innerText,
        new_code: this.editFileData.code,
        file_task: this.editFileData.code_interpreter
      }

      this.commonService.postMethodAxios(url, data).then((res: any) => {
        console.log(res);
        let datas = {
          code: res.data.code,
          code_interpreter: this.newFileTask, //document.getElementById("NewFileTask")?.innerText, //res.data.reasoning,
          filepath: this.editFileData.filepath
        }
        if (res.status == 200) {
          this.checkCode(datas, 'restart').then((res: any) => {
            if (res != null) {
              // this.editFileLoad = 'Loaded';
              // this.editFileTaskPopup = false;
              this.newFileTask = '';
              this.gptCode.filter((v:any,i:any)=>{
                if(i == index){
                  v.IsRestartLoading = false;
                }
              })
            }
          });
        }
      }).catch((err: any) => {
        console.log(err);
      })
      // this.saveCode(data);
    // }
  }

  codeEdit: boolean = false;
  codeData: any;
  EditConsoleEnable: boolean = false;
  editBtnHidden: boolean = false;
  updatedCode: any;
  reviewCode(data: any) {
    this.codeData = data;
    this.codeEdit = true;
    const hbtn3 = document.getElementById('hbtn3') as HTMLButtonElement;
    if (hbtn3) {
        hbtn3.click(); // First click
        setTimeout(() => {
            hbtn3.click(); // Second click after a short delay
        }, 200); // Adjust the delay as needed
    }
    this.EditConsoleEnable = false;
  }

  editClose() {
    this.codeEdit = false;
    this.editBtnHidden = false;
  }

  enableCodeEditor() {
    // this.codeEdit = false;
    this.EditConsoleEnable = true;
    this.editBtnHidden = true;
  }

  updateCode(data: any, type: any) {

    switch (type) {
      case "editCode":
        var editedCode = document.getElementById('code')?.innerText;
        console.log(editedCode);

        this.gptCode.filter((ele: any) => {
          if (ele.filepath == this.codeData.filepath) {
            ele.updatedCode = editedCode;
          }
        })

        this.editBtnHidden = false;
        this.codeEdit = false;
    this.editBtnHidden = false;
        break;

      case "normal":
        this.gptCode.filter((ele: any) => {
          if (ele.filepath == data.filepath) {
            ele.updatedCode = data.code;
            // ele.code_interpreter = data.code_interpreter
          }
        })
        break;
      case 'restart':
        this.gptCode.filter((ele: any) => {
          if (ele.filepath == data.filepath) {
            ele.code = data.code;
            // ele.code_interpreter = data.code_interpreter
          }
        });
        this.Starting();
        break;
    }
  }

  checkCode(data: any, type:string = 'normal') {
    let url = Constants.config.codeCheck;
    let body = {
      code: data.code,
      filetask: data.code_interpreter
    }

    var rslt = this.commonService.postMethodAxios(url, body).then((res: any) => {
      console.log(res);
      if (res.status == 200) {
        let values = {
          filepath: data.filepath,
          code: res.data.code,
          // code_interpreter: data.code_interpreter //res.data.reasoning
        }
        this.saveCode(values);
        this.updateCode(values, type);
        return res.data;
      }

    }).catch((err) => {
      console.log(err);
      return err;
    })

    return rslt;
  }

  selfCheckReason: any;
  selfCheckCode: any;
  selfCheckPopup: boolean = false;
  selfcheckFilepath: any;
  selfCheckBtn(data: any, path: any, type: any) {
    try {
      // Trigger the Bootstrap modal
      this.selfCheckPopup = true;
      const hbtn = document.getElementById('hbtn') as HTMLButtonElement;
        if (hbtn) {
            hbtn.click(); // First click
            setTimeout(() => {
                hbtn.click(); // Second click after a short delay
            }, 200); // Adjust the delay as needed
        }

      // Set modal content based on the type
      switch (type) {
        case 'SelfCheck':
          this.selfCheckReason = `<b>Review: ${data.reasoning}</b>`;
          this.selfCheckCode = data.code;
          this.selfcheckFilepath = path;
          break;
        case 'InitialCode':
          this.selfCheckReason = 'Initial Code';
          this.selfCheckCode = data.code;
          this.selfcheckFilepath = path;
          break;
        case 'StaticScan':
          this.selfCheckReason = data.reasoning;
          this.selfCheckCode = data.message;
          this.selfcheckFilepath = path;
          break;
        default:
          // Handle default case
          break;
      }
    } catch (error) {
      // Handle the error silently (without logging it to the console)
      console.error('An error occurred while triggering the Bootstrap modal:', error);
    }
  }


  selfCheckClose() {
    this.selfCheckPopup = false;
  }

  checkLint(data: any) {
    let url = Constants.config.checkLint;
    let body = {
      file_path: data.filepath,
      workspace_location: this.workLocation
    }

    var checkrslt = this.commonService.postMethodAxios(url, body).then((res: any) => {
      console.log(res);
      return res.data;
    }).catch((err) => {
      console.log(err);
      return err;
    })

    return checkrslt;

  }

  clarifyapistatus() {
    this.isLoading = true;
   const payload = new FormData();
   payload.append('user_id', this.userOHR);

    this.sopService.clarifyapistatus(payload).subscribe(
      (response: any) => {
        console.log(response);
        if (response.message !== "API interface is not created yet") {
          this.toastService.showSuccess(response.message);
          this.preReqData = JSON.stringify(JSON.parse(response.data), undefined, 4);
          localStorage.setItem('api', this.preReqData);
                    this.isLoading = false;
        } else {
          setTimeout(() => {
            this.toastService.showmessage(response.message);
            this.clarifyapistatus(); // Call itself after 15 seconds
          }, 15000);
        }
      },
      (error) => {
        this.isLoading = false;
        console.error('Error downloading file:', error);
        // Handle error as needed
      }
    );
  }
}

class Response {
  msgClass: any;
  msgIcon: any;
  msgIconClass: any;
  msgContent: any;
  SubmitBtnDisable: boolean = false;
  EditBtnDisable: boolean = false;
  submitmethod: any;
  editMethod: any;
  submitHidden: boolean = true;
  editHidden: boolean = true;
}

