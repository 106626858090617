import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { GenaiService } from '../shared/service/genai.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { ToastMessageService } from 'src/app/shared/service/toast-message.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MarkdownModule } from 'ngx-markdown';

@Component({
  selector: 'app-gen-ai',
  imports: [CommonModule, FormsModule, MarkdownModule],
  standalone: true,
  templateUrl: './gen-ai.component.html',
  styleUrls: ['./gen-ai.component.scss']
})
export class GenAiComponent implements OnInit, OnDestroy {
  @ViewChild('scrollChat') scrollChat!: ElementRef;
  chatData: any[] = [];
  selectedFiles: File[] = [];
  prompt: string = '';
  inputAccept: string = '.pdf,.doc,.docx,.png,.jpg,.jpeg,.csv,.xlsx,.ppt,.pptx';
  isLoading: boolean = false;
  isSupervisorModeEnabled: boolean = true;
  defaultAgentId: string = 'HMIW89PMZD';
  defaultAgentAliasId: string = 'C8IKDN44BW';
  agentId: string = this.defaultAgentId;
  agentAliasId: string = this.defaultAgentAliasId;

  private chatSubscriber!: Subscription;

  constructor(
    private toastMessage: ToastMessageService,
    private loaderService: LoaderService,
    private genaiService: GenaiService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.agentId = params['agentId'] || this.defaultAgentId;
      this.agentAliasId = params['agentAliasId'] || this.defaultAgentAliasId;
    });

    this.getWelcomeMessage();
  }

  ngOnDestroy(): void {
    if (this.chatSubscriber) {
      this.chatSubscriber.unsubscribe();
    }
  }

  toggleSupervisorMode(): void {
    this.isSupervisorModeEnabled = !this.isSupervisorModeEnabled;
  }

  getWelcomeMessage(): void {
    this.genaiService.getWelcome().subscribe(
      (res: any) => {
        if (res && res.message) {
          this.chatData.push({ role: 'bot', content: res.message });
          this.scrollToBottom();
        }
      },
      (error: any) => {
        this.toastMessage.showError('Failed to load welcome message');
      }
    );
  }

  sendMessage(): void {
    if (this.prompt.trim()) {
      this.isLoading = true;
      this.chatData.push({ role: 'user', content: this.prompt });

      const payloadAgentId = this.isSupervisorModeEnabled ? this.agentId : '';
      const payloadAgentAliasId = this.isSupervisorModeEnabled ? this.agentAliasId : '';

      this.genaiService.getChat(this.prompt, payloadAgentId, payloadAgentAliasId, this.isSupervisorModeEnabled).subscribe(
        (res: any) => {
          this.isLoading = false;
          if (res && res.data) {
            this.chatData.push({ role: 'bot', content: res.data });
            this.scrollToBottom();
          }
        },
        (error: any) => {
          this.isLoading = false;
          this.toastMessage.showError('Failed to get response');
        }
      );

      this.prompt = '';
    } else {
      this.toastMessage.showError('Please enter a message');
    }
  }

  scrollToBottom(): void {
    setTimeout(() => {
      this.scrollChat.nativeElement.scrollTop = this.scrollChat.nativeElement.scrollHeight;
    }, 0);
  }

  copyToClipboard(content: string): void {
    navigator.clipboard.writeText(content).then(() => {
      console.log('Copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }

  regenerate(index: number) {
    this.prompt = this.chatData[index - 1]?.content;
    this.sendMessage();
  }

  autoResize(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  handleEnter(): void {
    if (this.prompt.trim()) {
      this.sendMessage();
    }
  }

  inputPrompt(question: string): void {
    console.log(`Prompting with question: ${question}`);
  }

  newChat(): void {
    this.chatData = [];
    this.selectedFiles = [];
    this.prompt = '';
    console.log('Starting a new chat');
  }

  sentToInput(): void {
    this.sendMessage();
  }
}
