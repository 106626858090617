import { Component } from '@angular/core';

@Component({
    selector: 'app-home-main',
    templateUrl: './home-main.component.html',
    styleUrls: ['./home-main.component.scss'],
    standalone: false
})
export class HomeMainComponent {

}
