<div class="fine-tune-model-container" style="background-color: #ffffff; overflow-y: scroll;">

    <div class="code-box" style="background-color: white;">
        <div class="custom-container">
          <p class="code-enhance-header">SDD module generates solution design documents and provide step-by-step automation strategies based on BRD. Accelerate project timelines and reduce development costs with a clear roadmap and optimized automation strategies.</p>
          <p class="code-enhance-desc"></p>
            <div class="form-box">
                  <form>
                      <div class="row gx-2 gy-2">
                        <div class="csol">
                          <p class="upload-label" class="astreik">Project Name:</p>

                            <textarea [(ngModel)]="processName" [ngModelOptions]="{ standalone: true }"
                            style="resize:none;height:50%; width:100%;" rows="4"
                            placeholder="Please provide a concise and descriptive name for the process that accurately reflects its purpose and function."
                            name="processName" #processNameField="ngModel" required minlength="5"  maxlength="255" ></textarea>

                            <div class="text-danger">
                              <!-- <small *ngIf="processNameField.errors?.required">Process name is required.</small> -->
                              <small *ngIf="processNameField.errors?.['minlength']">Project Name cannot be less than 5 and cannot be greater than 255.</small>

                              <!-- if minlength is 0 -->
                              <small *ngIf="!processName">Project Name cannot be less than 5 and cannot be greater than 255.</small>

                              <small *ngIf="processNameField.errors?.['maxlength']">Project Name cannot be greater than 255.</small>
                        <!-- <small *ngIf="processName.length >= 0">
                          {{ 50 - processName.length }} characters left (max is 50).
                        </small> -->
                            </div>

                        </div>
                        <div class="csol">


                          <p class="upload-label" class="astreik">Preferred Tech Stack
:</p>
                          <div class="file-upload form-control" style="margin-bottom: 10px;" >

                          <mat-select style="background-color:#ffffff; height:50px;" [(ngModel)]="codelang" [ngModelOptions]="{ standalone: true }" placeholder="Please specify the Preferred Tech Stack
 for the project. This will help in identifying the most suitable resources and tools for the project." multiple>
                              <mat-option *ngFor="let language of codingLanguages" [value]="language">{{ language }}</mat-option>
                            </mat-select>
                         </div>
</div>
                          <div class="csol" *ngIf="codelang">
                              <p class="upload-label" class="astreik">Upload RA file:</p>
                              <div class="file-upload form-control" *ngIf="!docuploaded">
                                  <label for="fileInput">
                                      <span class="upload-icon">
                                          <img tooltip="only .docx is allowed" src="../../../../../assets/images/uploadBlack.png" alt="">
                                      </span>
                                      <span class="upload-text">Click to upload file</span>
                                  </label>
                                  <input type="file" id="fileInput" (change)="onupload($event)"  accept=".docx" #fileInput />
                              </div>
                              <small class="text-danger" *ngIf="invalidSize">File size too large! Max size: 1MB.</small>
                                  <small class="text-danger" *ngIf="invalidType">Invalid file type! Only .doc and .docx allowed.</small>

                              <div class="uploaded-file">
                                  <div *ngIf="docuploaded">
                                      <p class="uploaded-header">Uploaded file</p>
                                      <div class="uploaded-file-section">
                                          <div class="upload-file-name">
                                              <img src="../../../../../assets/images/codeDocument.png" alt="">
                                          <span class="file-font">{{ namefile }}</span>
                                          </div>
                                      <span class="action-buttons">

                                          <!-- <img src="../../../../../assets/images/refresh.png" alt="" (click)="triggerFileInputClick()"  > -->
                                          <img src="../../../../../assets/images/delete.png" alt="" (click)="removeFile(fileInput)">

                                        </span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <!-- <div class="col-lg-12">
                        <div class="uploaded-file">
                          <div >
                              <div class="uploaded-file-section">
                                  <div class="upload-file-name">
                                      <img src="../../../../../assets/images/codeDocument.png" alt="">
                                  <span class="file-font">{{ docname ? docname : 'Testcase doc' }}
                                  </span>
                                  </div>
                              <span class="action-buttons">
                                <input type="file" (change)="onFileSelected($event)" #fileInput style="display: none;" accept=".doc,.txt,.docx">
                                <img tooltip="only .txt,.doc,.docx are allowed" src="../../../../../assets/images/uploadBlack.png" alt="" (click)="fileInput.click()">
                                  </span>
                              </div>
                          </div>
                      </div>
                    </div> --><div class="btn-submit" *ngIf = "(docuploaded)">
                          <!-- <app-button [buttonClass]="'BRD-btn'" [buttonText]="'Generate SDD'" (buttonClick)="onButtonSubmit()"
                              ></app-button> -->
                              <!-- <a *ngIf="showGoToGenerateCode" class="goToLink" (click)="goToGenerateCode()">Go To Generate Code</a> -->

<div >
                              <button  *ngIf="processName.length>=5 " class="BRD-btn" (click)="onButtonSubmit(this.selectedFile)">Generate SDD</button>
                            </div>                           <!-- <button  class="btn-navigate" (click)="goToGenerateCode()">Next: Go to  Generate Code</button> -->
                      </div>

                      <div class="btn-submit" *ngIf = "downloadbtn">
                          <!-- <app-button [buttonClass]="'BRD-btn'" [buttonText]="'Generate SDD'" (buttonClick)="onButtonSubmit()"
                              ></app-button> -->
                              <!-- <a *ngIf="showGoToGenerateCode" class="goToLink" (click)="goToGenerateCode()">Go To Generate Code</a> -->
                              <button  class="BRD-btn" (click)="download()">Download SDD</button>
                              <!-- <button  class="btn-navigate" (click)="goToGenerateCode()">Next: Go to  Generate Code</button> -->
                      </div>
                  </form>
              </div>
          </div>
      </div>

</div>

<div *ngIf="isLoading" class="overlay">
  <div class="spinner"></div>
  <br>

</div>
