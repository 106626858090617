<div class="header">
  <div class="container" style="width: 80vw;">
      <div class="inner-header">
          <div class="left-sub-header" [ngStyle]="{'background-color': isHomePage() ? 'white' : 'transparent'}">
              <img src="../../../../assets/images/devflowLogo.png" alt="logo" (click)="goToHome()">
            </div>
            <div class="middle-sub-header" >
               <ul class="menu">
                <li class="sub-menu" [routerLink]="['/codeGenYLite/products']" routerLinkActive="active" (click) = "productPage()" >Products</li>
                <li class="sub-menu" (click)="navigateTo('feature-container')">Features</li>
                <!-- <li class="sub-menu" [routerLink]="['/home-main/features']" routerLinkActive="active">Features</li> -->
                <li class="sub-menu"  (click)="opencontactus()">Contact us</li>
               </ul>
            </div>
            <div class="right-sub-header">
              <!-- <div class="get-started">
                  <button class="btn get-started-btn">Get started</button>
              </div> -->
              <div class="user-setting">
                  <button class="user-setting-btn" [matMenuTriggerFor]="menu"><img class="userimage" src="../../../../assets/images/userProfile.png"> <p class="user-name">Hi {{username}}</p></button>
                  <mat-menu #menu="matMenu" class="background-color">
                      <!-- <li class="list-item item-first" (click)="openSetting()"><i class="fa-solid fa-gear"></i> Settings</li> -->
                      <li  class="list-item item-first"><a class="nav-link" routerLink="/konverse" routerLinkActive="active" ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door-fill" viewBox="0 0 16 16" style="margin-right: 10px;">
                        <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5"/>
                      </svg>   Home</a></li>
                      <!-- <li class="list-item item-first"><a class="nav-link" routerLink="/codeGenYLite" routerLinkActive="active" >codeGenYLite</a></li> -->
                      <li class="list-item item-first" (click)="openDialog()"><img class="img-icon" src="../../../../../assets/privacyicon.png">Privacy Policy</li>
                      <li class="list-item" (click)="exitApp()"><i class="fa-solid fa-right-from-bracket"></i>Exit application</li>
                  </mat-menu>
              </div>
          </div>
      </div>
  </div>
</div>