<div class="fine-tune-model-container" style="background-color: #EEF6F9; overflow-y: scroll;">

  <div class="fine-tune-model-container" style="background-color: #EEF6F9; overflow-y: scroll;">

    <div class="code-box">
        <div class="custom-container">
          <p class="code-enhance-header">
            Code enhancement boosts productivity by automating bug resolution and code refinement, saving development hours and reducing the costs associated with manual debugging and code improvement.
             </p>
          <p class="code-enhance-desc"></p>













            <div class="form-box">

                <div class="row gx-2 gy-2">

                  <div class="csol">
                    <p class="upload-label" class="astreik">Project name:</p>

                      <textarea [(ngModel)]="processName" [ngModelOptions]="{ standalone: true }"
                      style="resize:none;height:50%; width:100%;" rows="4"
                      placeholder="Please provide a concise and descriptive name for the process that accurately reflects its purpose and function."
                      name="processName" #processNameField="ngModel" required minlength="5"  maxlength="255" ></textarea>

                      <div class="text-danger">
                        <!-- <small *ngIf="processNameField.errors?.required">Process name is required.</small> -->
                        <small *ngIf="processNameField.errors?.['minlength']">Project name cannot be less than 5 and cannot be greater than 255.</small>
                        <small *ngIf="processNameField.errors?.['maxlength']">Project name cannot be greater than 255.</small>
                        <small *ngIf="!processName">Project name cannot be less than 5 and cannot be greater than 255.</small>

                        <!-- <small *ngIf="processName.length >= 5">
                          {{ 50 - processName.length }} characters left (max is 50).
                        </small> -->
                      </div>

                  </div>




                  <div class="csol">

                  <p class="upload-label" class="astreik">Preferred coding language:</p>
                  <div class="file-upload form-control" style="margin-bottom: 10px;" >

                  <mat-select style="background-color:#ffffff; height:50px;" [(ngModel)]="codelang" [ngModelOptions]="{ standalone: true }" (ngModelChange)="changes()" placeholder="Please specify the preferred coding language." >
                    <mat-option *ngFor="let language of codingLanguages" [value]="language">{{ language }}</mat-option>
                  </mat-select>
                  </div>
                  </div>
                </div>


                  <form >
                      <div class="row gx-2 gy-2" *ngIf="processName.length>=5">
                          <div *ngIf="codelang" class="csol">
                              <p class="upload-label" class="astreik">Upload your Code file:</p>
                              <div class="file-upload form-control" *ngIf="!docuploaded">
                                  <label for="fileInput">
                                      <span class="upload-icon">
                                          <img src="../../../../../assets/images/uploadBlack.png" alt="">
                                      </span>
                                      <span class="upload-text">Click to upload file</span>
                                      <span class="upload-text " style="color:#ff555f">Only {{placeholder}} are allowed</span>
                                      <span class="upload-text " style="color:#ff555f"> Maximum allowed limit for document content is 40k characters</span>

                                    </label>
                                  <input type="file" id="fileInput" (change)="onupload($event)"  [accept]="codes"  #fileInput />
                              </div>
                              <small class="text-danger" *ngIf="invalidSize">File size too large! Max size: 1MB.</small>
                                  <small class="text-danger" *ngIf="invalidType">Invalid file type! Only .doc and .docx allowed.</small>

                              <div class="uploaded-file">
                                  <div *ngIf="docuploaded">
                                      <p class="uploaded-header">Uploaded file</p>
                                      <div class="uploaded-file-section">
                                          <div class="upload-file-name">
                                              <img src="../../../../../assets/images/codeDocument.png" alt="">
                                          <span class="file-font">{{ namefile }}</span>
                                          </div>
                                      <span class="action-buttons">

                                          <!-- img src="../../../../../assets/images/refresh.png" alt="" data-toggle="modal" data-target="#myModal"  > -->
                                          <img src="../../../../../assets/images/delete.png" alt="" (click)="removeFile(fileInput);">

                                        </span>
                                      </div>

                                  </div>

                              </div>


                          </div>
                      </div>
                      <!-- <div class="col-lg-12">
                        <div class="uploaded-file">
                          <div >
                              <div class="uploaded-file-section">
                                  <div class="upload-file-name">
                                      <img src="../../../../../assets/images/codeDocument.png" alt="">
                                  <span class="file-font">{{ docname ? docname : 'Testcase doc' }}
                                  </span>
                                  </div>
                              <span class="action-buttons">
                                <input type="file" (change)="onFileSelected($event)" #fileInput style="display: none;" accept=".doc,.txt,.docx">
                                <img tooltip="only .txt,.doc,.docx are allowed" src="../../../../../assets/images/uploadBlack.png" alt="" (click)="fileInput.click()">
                                  </span>
                              </div>
                          </div>
                      </div>
                    </div> -->
















<div  *ngIf="codelang" class="text-box">
  <textarea [(ngModel)]="content" [ngModelOptions]="{ standalone: true }"
                      style="resize:none;height:350px; width:100%; overflow: auto;" rows="4"
                      placeholder="Please enter your code here"


                      name="code" #processNameField="ngModel" required minlength="25" maxlength="40000" ></textarea>

                      <div class="text-danger">
                        <!-- <small *ngIf="processNameField.errors?.required">Process name is required.</small> -->
                        <small *ngIf="processNameField.errors?.['minlength']">Code cannot be less than 25 and cannot be greater than 40000.</small>
                        <small *ngIf="processNameField.errors?.['maxlength']">code cannot be greater than 40000.</small>
                        <small *ngIf="!processName">code name cannot be less than 25 and cannot be greater than 40000.</small>

                        <!-- <small *ngIf="processName.length >= 5">
                          {{ 50 - processName.length }} characters left (max is 50).
                        </small> -->
                      </div>
  </div>

                    <div class="btn-submit" *ngIf="content && content.length >= 25 && processName && processName.length >= 5">
                          <!-- <app-button [buttonClass]="'BRD-btn'" [buttonText]="'Generate SDD'" (buttonClick)="onButtonSubmit()"
                              ></app-button> -->
                              <!-- <a *ngIf="showGoToGenerateCode" class="goToLink" (click)="goToGenerateCode()">Go To Generate Code</a> -->
                              <button  class="BRD-btn" (click)="onButtonSubmit(this.selectedFile)">Enhance Code </button>
                              <!-- <button  class="btn-navigate" (click)="goToGenerateCode()">Next: Go to  Generate Code</button> -->
                      </div>

                      <!-- <div *ngIf="docxUrl">
                        <iframe [src]="docxUrl" width="100%" height="600px"></iframe>
                      </div> -->
                      <div *ngIf="docuploaded || content">
                      <div class="enhanced-content" *ngIf="codedata">
                      <!-- <div class="copy-btn d-flex" Style="background-color:#073262">
                        <img src="../../../../../assets/images/copy.png" alt="" (click)="copyText()">
                        <p *ngIf="iscopied" style="color:#ffffff;">Copied!!!</p>
                      </div> -->
                      <!-- download btn -->
                      <!-- <div class="copy-btn d-flex" Style="background-color:#073262">
                        <img src="../../../../../assets/images/download.png" alt="" (click)="downloadCode()">
                        </div> -->
                    </div>
                    <div class="enhanced-content" *ngIf="codedata">

                      <div class="code-container">
                        <h2>Enhanced Code</h2>
                        <div class="icons">
                          <img
                            class="img-1"
                            src="../../../../assets/images/copy.png"
                            alt=""
                            (click)="copyText1()"
                            [matTooltip]="tooltipText"
                            [matTooltipPosition]="'above'"
                          />
                          <img
                            class="img-2"
                            src="../../../../assets/images/download.png"
                            alt=""
                            (click)="downloadCode()"
                            [matTooltip]="'Download'"
                            [matTooltipPosition]="'above'"
                          />
                          <!--<a [href]="fileUrl" download="downloadFilename"><i class="fa-solid fa-download" (click)="downloadFile()"></i></a>-->
                        </div>
                        <ngx-codemirror
                         id="codeSnippet"
                          [options]="codeMirrorOptions"
                          [(ngModel)]="codedata"
                          [ngModelOptions]="{ standalone: true }"
                        ></ngx-codemirror>

                        <!-- <monaco-editor
                        [(ngModel)]="codedata"
                        [ngModelOptions]="{ standalone: true }"
                         [options]="editorOptions">
                        </monaco-editor> -->


                      </div>
                    </div>

                    </div>
                    <div class="improvement-segment" *ngIf="codepoints">
                      <div class="code-container">
                        <h2> Code description and improvements</h2>

                    <div class="improvement-points-box">

                      <!-- <pre class="improvement-points">{{codeEnhance.improvement_points}}</pre> -->
                      <div
                        class="improvement-points"
                        lineNumbers
                        markdown


                        [data]="codepoints"
                      ></div>
                      <!--<ngx-codemirror [(ngModel)]="codeEnhance.improvement_points"
                                            [options]="codeMirrorOptions"></ngx-codemirror>-->
                    </div>
                  </div>
                  </div>

                      <!-- <div class="btn-submit" *ngIf = "downloadbtn"> -->
                          <!-- <app-button [buttonClass]="'BRD-btn'" [buttonText]="'Generate SDD'" (buttonClick)="onButtonSubmit()"
                              ></app-button> -->
                              <!-- <a *ngIf="showGoToGenerateCode" class="goToLink" (click)="goToGenerateCode()">Go To Generate Code</a> -->
                              <!-- <button  class="BRD-btn" (click)="downloadCode()">Download Code </button> -->
                              <!-- <button  class="btn-navigate" (click)="goToGenerateCode()">Next: Go to  Generate Code</button> -->
                      <!-- </div> -->
                  </form>


              </div>
          </div>
      </div>



  </div>

  <!-- modal -->
  <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="myModalLabel1">{{ namefile }} doc</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="input-group input-group-lg">
            <textarea disabled style="width:100%;    height: 300px;
            resize: none;
            overflow-y: scroll;" title="user req" >{{content}}</textarea>
          </div>
        </div>
        <div class="modal-footer">
          <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
          <!-- <button type="button" class="btn btn-primary" data-dismiss="modal" >Save</button> -->
        </div>
      </div>
    </div>
    </div>



<br><br>
</div>
<div *ngIf="isLoading" class="overlay">
  <div class="spinner"></div>
  <br>

</div>
