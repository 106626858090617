<div class="fine-tune-model-container" style="background-color: #ffffff; overflow-y: scroll;">
  <div class="container">
<br><br>
    <div class="form-box" *ngIf="filestatus">

      <div class="row gx-2 gy-2">
        <div class="csol">
          <p class="upload-label" class="astreik">Project Name:</p>

            <textarea [(ngModel)]="processName" [ngModelOptions]="{ standalone: true }"
            style="resize:none;height:50%; width:100%;" rows="4"
            placeholder="Please provide a concise and descriptive name for the process that accurately reflects its purpose and function."
            name="processName" #processNameField="ngModel" required minlength="5"  maxlength="255" ></textarea>

            <div class="text-danger">
              <!-- <small *ngIf="processNameField.errors?.required">Process name is required.</small> -->
              <small *ngIf="processNameField.errors?.['minlength']">Project Name cannot be less than 5 and cannot be greater than 255.</small>
              <small *ngIf="processNameField.errors?.['maxlength']">Project Name cannot be greater than 255.</small>
                        <!-- <small *ngIf="processName.length >= 5">
                          {{ 50 - processName.length }} characters left (max is 50).
                        </small> -->
            </div>

        </div>
        </div>
        </div>

    <div class="row mt-4" *ngIf="filestatus">

      <div class="col-lg-12">
        <!-- <h5>User Requirements</h5> -->
        <div class="uploaded-file">
          <div >
              <!-- <h5 class="uploaded-header">Uploaded file</h5> -->
              <div class="uploaded-file-section" >
                  <div class="upload-file-name">
                      <img src="../../../../../assets/images/codeDocument.png" alt="">
                  <span tooltip="user requirement doc" class="file-font">{{ docname1 ? docname1 : 'User doc' }}</span>
                  </div>
                  <span class="action-buttons">
                    <input type="file" (change)="handleFileChange1($event)" #fileInput1 style="display: none;" accept=".txt,.docx">

                    <img tooltip="only .txt,.docx are allowed" src="../../../../../assets/images/uploadBlack.png" alt="" (click)="fileInput1.click()">
    </span>
              <span class="action-buttons">
                  <img tooltip="edit document text" src="../../../../../assets/images/refresh.png" alt="" data-toggle="modal" data-target="#myModal" >
                  <!-- /<img src="../../../../../assets/images/delete.png" alt=""> -->

                </span>
              </div>
          </div>
      </div>

        <!-- <div class="input-group input-group-lg">
          <textarea placeholder="Enter user requirement" [(ngModel)]="squery" [ngModelOptions]="{ standalone: true }" (change)="setquery()" type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"></textarea>
        </div> -->
      </div>

    </div>

    <div id="text-d" class="row mt-4" *ngIf="filestatus1">
      <div class="col-lg-12">
        <div class="uploaded-file">
          <div >
              <!-- <h5 class="uploaded-header">Uploaded file</h5> -->
              <div class="uploaded-file-section" >
                  <div class="upload-file-name">
                      <img src="../../../../../assets/images/codeDocument.png" alt="">
                  <span class="file-font">{{ docname ? docname : 'BRD doc' }}
                  </span>
                  </div>
              <span class="action-buttons">
                <input type="file" (change)="handleFileChange($event)" #fileInput style="display: none;" accept=".txt,.docx">

                <img tooltip="only .txt,.docx are allowed" src="../../../../../assets/images/uploadBlack.png" alt="" (click)="fileInput.click()">
</span>
<span class="action-buttons">
                  <img tooltip="edit document text" src="../../../../../assets/images/refresh.png" alt="" data-toggle="modal" data-target="#myModal1" >
                  <!-- /<img src="../../../../../assets/images/delete.png" alt=""> -->


                </span>
              </div>
          </div>
      </div>
        <!-- <h5>Project description</h5>
        <textarea id="devReqTxt" [(ngModel)]="stext" style="width:100%" title="file content" rows="10" cols="50">{{ uploadedContent }}</textarea>
      </div> -->
    </div>

    <div class="row mt-4" >
      <div class="col-lg-12">
        <div style="display: flex; justify-content: space-evenly;">
          <div style="text-align:center;">
            <button style="border-radius:0px;" *ngIf="filestatus1" [disabled]="!stext || !squery || processName.length<5" class="BRD-btn" (click)="DevReqSubmit()">
              <span class="button-text">Generate API document</span>
            </button>
          </div>

          <!-- <div class="text-right" *ngIf="filestatus1">
            <input type="file" (change)="handleFileChange($event)" #fileInput style="display: none;" accept=".doc,.txt">
            <button tooltip="Upload files" style="border:none;" (click)="fileInput.click()" type="button" class="btn btn-primary">
              <span class="button-text">Upload BRD</span>
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </div>

    <div class="row mt-4">
      <div class="col-lg-12">
        <div class="user-message" *ngIf="DevReqDataEnable">
          <div class="message-content" [innerHTML]="DevUserReqData">Confirm interface document</div>
          <fa-icon class="odnoklassnikicss" [icon]="odnoklassniki" size="2x"></fa-icon>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-12">
        <div *ngIf="isLoading" class="LoadingDiv">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Generating answer, please wait....
        </div>
      </div>
    </div>
    <div *ngIf="isLoading" class="overlay">
      <div class="spinner"></div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-12">
        <div class="gpt-message" *ngIf="preReqData != ''">
          <fa-icon [icon]="redditIcon" class="redditcss" size="2x"></fa-icon>
          <div class="message-content">
            <h4>
              <b>The interface documentation has been generated, and once confirmed, the analysis of how to modify the program code will begin:</b>
            </h4>
            <br />
            <pre [innerHTML]="preReqData" lineNumbers markdown  [contentEditable]="preReqTxtEdit" id="preReqTxt"></pre>
            <br /><br />
            <button *ngIf="!savebtn" style="border-radius:0px" class="btn btn-success" (click)="preReqEdit()">Edit</button>
            <button *ngIf="savebtn"  style="border-radius:0px" class="btn btn-success" (click)="saveEdit()">save</button>
            <button style="border-radius:0px" class="btn btn-secondary ml-2" (click)="DownloadUserRequirement()">
              <fa-icon [icon]="faDownload"></fa-icon>
              Download Document
            </button>
          </div>
        </div>
      </div>
    </div>


</div>
<!-- modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel1">User Requirement</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="input-group input-group-lg">
          <textarea style="height:450px;" placeholder="Enter user requirement" [(ngModel)]="squery" [ngModelOptions]="{ standalone: true }" (change)="setquery()" type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="test()">Save</button>
      </div>
    </div>
  </div>
  </div>


<!-- brd -->
<div class="modal fade" id="myModal1" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel">BRD</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="input-group input-group-lg">
          <textarea id="devReqTxt" [(ngModel)]="stext" style="width:100%" title="file content" rows="10" cols="50">{{ uploadedContent }}</textarea>

        </div>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="test()">Save</button>
      </div>
    </div>
  </div>
  </div>
