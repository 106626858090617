import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {

  constructor(private toastr: ToastrService){

  }

  showWarning(message:string) {
    this.toastr.warning(message);
  }

  showSuccess(message:string) {
    this.toastr.success(message, 'Success!');
  }
  showSuccess1(message:string) {
    this.toastr.success(message);
  }
  showError(message:string) {
    this.toastr.error(message, 'Error!');
  }

  showmessage(message:string) {
    this.toastr.info(message);
  }

  clearAll() {
    this.toastr.clear();
  }

}
/* 
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {

  constructor(private toastr: ToastrService) {
      this.toastr.toastrConfig.toastClass = 'toast-custom'; // Default class, you can change this
  }

  showWarning(message: string) {
    this.toastr.warning(message, '', { toastClass: 'toast-warning' }); // Override for warning
  }

  showSuccess(message: string) {
    this.toastr.success(message, 'Success!', { toastClass: 'toast-success' }); // Override for success
  }

  showSuccess1(message: string) {
    this.toastr.success(message, '', { toastClass: 'toast-success' }); // Override for success
  }

  showError(message: string) {
    this.toastr.error(message, 'Error!', { toastClass: 'toast-error' }); // Override for error
  }

  showmessage(message: string) {
    this.toastr.info(message, '', { toastClass: 'toast-info' }); // Override for info
  }

  clearAll() {
    this.toastr.clear();
  }
}
*/