import { Document } from 'docx';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { InterAssistService } from '../service/inter-assist.service';
import { ToastMessageService } from 'src/app/shared/service/toast-message.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import {  Packer, Paragraph, TextRun } from 'docx';
import * as FileSaver from 'file-saver';
import * as HtmlDocx from 'html-docx-js-typescript';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';





interface CV {
  name: string;
  link: string;
  interviewer: string;
  status: string;
}

// Define the Item interface
interface Item {
  selected: boolean;
  rid: string;
  jd: string;
  jdLink: string;
  cv: CV[];
}


interface FileDetail {
  file: File;
  type: 'CV' | 'JD';
  format: string;
}
@Component({
  selector: 'app-ialogin',
  templateUrl: './ialogin.component.html',
  styleUrls: ['./ialogin.component.scss']
})
export class IaloginComponent implements OnInit {
  displayedColumns: string[] = ['id', 'requisition_id', 'candidate_name', 'position', 'interviewer_name', 'status', 'actions'];
  dataSource!: MatTableDataSource<any>;
  reqid: any;
  cvResults: any[] = [];
  position: any;
  interviewername: any;
  candidatename: any;
  existingId:any;
  tabid: any;
  modalRef?: BsModalRef;
 refsummary:any;
 isLoading: boolean = false;
  files: FileDetail[] = [];
  displayType: 'CV' | 'JD' | 'All' = 'All';

  isModalOpen = false;
  isSingleImageModalOpen = false;
  leftImageUrl: string | ArrayBuffer | null = null;
  rightImageUrl: string | ArrayBuffer | null = null;
  singleImageUrl: string | ArrayBuffer | null = null;
  verificationResult: any = null;
  selectedId: string | null = null;
  leftImage: File | null = null;
  rightImage: File | null = null;
  errorMessage: string | null = null;
  verificationResponse: any = null;


  userdata:boolean = true;
  fetchdata:boolean = false;
  current_id: string='';

  items:Item[] = [
    {
      selected: false,
      rid: 'BFS025794',
      jd: 'Associate, KYC/AML',
      jdLink: 'assets/New folder/BFS025794/BFS025794.docx',  // Link to the JD file in the assets folder
      cv: [
        { name: 'Chandana', link: 'assets/New folder/BFS025794/Chandana resume.pdf',interviewer:'Sahil',status:'inprogress' },  // Link to the CV file in the assets folder
        // { name: 'Divya', link: 'assets/New folder/BFS025794/Divya resume  (1).pdf',interviewer:'Sahil',status:'inprogress'  },
        { name: 'Krishna', link: 'assets/New folder/CPG039353/Krishna Kumar.pdf',interviewer:'Sahil',status:'inprogress'  }
      ]
    },
    {
      selected: false,
      rid: 'CPG039353',
      jd: 'Process Developer - OTC, Cash Application ',
      jdLink: 'assets/New folder/CPG039353/CPG039353.docx',
      cv: [
        { name: 'Krishna kumar', link: 'assets/New folder/CPG039353/Krishna Kumar.pdf',interviewer:'Shanmukha',status:'inprogress'  },
        { name: 'Sakshi Bahl', link: 'assets/New folder/CPG039353/Sakshi Bahl.docx',interviewer:'Shanmukha',status:'inprogress'  }
      ]
    },
    {
      selected: false,
      rid: 'INS017000',
      jd: 'PA, Reinsurance',
      jdLink: 'assets/New folder/INS017000/INS017000.docx',
      cv: [
        { name: 'Sonali', link: 'assets/New folder/INS017000/Sonali_Resume-1.pdf',interviewer:'Rahul',status:'inprogress'  },
        { name: 'Shubham', link: 'assets/New folder/INS017000/SUBHAM CHAKRABORTY_CV.docx',interviewer:'Rahul',status:'inprogress'  }
      ]
    },
    {
      selected: false,
      rid: 'LIF011001',
      jd: 'Process Associate - S2P',
      jdLink: 'assets/New folder/LIF011001/LIF011001.docx',
      cv: [
        { name: 'Deepak', link: 'assets/New folder/LIF011001/Deepak Resume.pdf',interviewer:'Rohit',status:'inprogress'  },
        { name: 'manoj', link: 'assets/New folder/LIF011001/manojresume4.5years (1) (1).docx',interviewer:'Rohit',status:'inprogress'  }
      ]
    },
    // Add more items as needed
  ];
  searchTerm: string = '';
  sortKey: string = 'rid';
  sortDirection: 'asc' | 'desc' = 'asc';
  itemsPerPage: number = 5;
  currentPage: number = 1;


  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  reportdata: any;

  jobDisplayedColumns: string[] = ['select', 'rid', 'jd', 'cv', 'action']; // Renamed to jobDisplayedColumns
  jobDataSource = new MatTableDataSource<Item>([]); // Renamed to jobDataSource

  @ViewChild('jobSort') jobSort!: MatSort; // Rename ViewChild reference
  @ViewChild('jobPaginator') jobPaginator!: MatPaginator; // Rename ViewChild reference
  comparisonResults: any;

  constructor(private interAssistService: InterAssistService,
              private toastservice : ToastMessageService,
              private loaderservice : LoaderService,
              private router: Router,
              private modalService: BsModalService,
              private http: HttpClient,

  ) {}

  ngOnInit() {
    this.getlist()
    this.jobDataSource.data = this.items;
  }
  ngAfterViewInit() {
    this.jobDataSource.sort = this.jobSort;
    this.jobDataSource.paginator = this.jobPaginator;
  }

getuserdata(){
  this.userdata = true;
  this.fetchdata = false;

}

getfetchdata(){
  this.userdata = false;
  this.fetchdata = true;
}

  getlist() {
    this.loaderservice.show();
    this.interAssistService.userList()
      .pipe(
        catchError(() => of(this.getDummyData())),
        finalize(() => this.loaderservice.hide(),
      
      )  // Ensures loader is hidden on both error and success
     
      )
      .subscribe(data => {
        this.loaderservice.hide();
        this.dataSource = new MatTableDataSource(data);
        this.tabid = this.dataSource.data.length + 1;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = (data, filter) => {
          const transformedFilter = filter.trim().toLowerCase();
          return data.id.toLowerCase().includes(transformedFilter) ||
                 data.requisition_id.toLowerCase().includes(transformedFilter) ||
                 data.candidate_name.toLowerCase().includes(transformedFilter) ||
                 data.position.toLowerCase().includes(transformedFilter) ||
                  data.interviewer_name.toLowerCase().includes(transformedFilter) ||
                  data.status.toLowerCase().includes(transformedFilter);
                  this.toastservice.showSuccess('User List Loaded Successfully');
        };
        //this.toastservice.showSuccess('User List Loaded Successfully');
      });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  private getDummyData() {
    this.toastservice.showError('User List Loading Failed');
    return [
      { id: 1, name: 'John Doe', email: 'john.doe@example.com', role: 'Admin', status: 'Active', actions: null },
      { id: 2, name: 'Jane Smith', email: 'jane.smith@example.com', role: 'User', status: 'Inactive', actions: null },
      { id: 3, name: 'Alice Johnson', email: 'alice.johnson@example.com', role: 'Moderator', status: 'Active', actions: null }
    ];
    
  }


createuser(){
  this.loaderservice.show();
this.toastservice.showmessage('Creating Record for'+ this.candidatename+' for the position of '+this.position+' under requisition id '+this.reqid+' by '+this.interviewername);
  const payload = {
    "requisition_id": this.reqid,
    "position": this.position,
    "interviewer_name": this.interviewername,
    "candidate_name": this.candidatename

  };
  this.interAssistService.createuser(payload).subscribe(
    (response:any) => {

      if(response){
    this.loaderservice.hide();
    this.toastservice.showSuccess(response?.message);
    this.uploadAllCVs()
    this.getlist();
      }
  },
  (error) => {
    this.loaderservice.hide();
    this.toastservice.showError('User Creation Failed');
  });
}

Navigate(id:any,status:any){

// if(status === 'Upload CV/JD'){
//   this.existingId = id;
//   document.getElementById('cvjd')?.click();
// }
// if(status === 'QnA'){
//   this.existingId = id;
//   this.router.navigate(['interview-assist/'+id+'/evaluate']);
// //(['bgc/' + id, 'upload']);
// }
// if(status === 'report analysis'){
//   this.existingId = id;
//   this.router.navigate(['interview-assist/'+id+'/transcript-manager']);

// }
// else{
  this.router.navigate(['interview-assist/'+status+'/'+id+'/IntelliQ&A']);
// }

}


downloadreport(reqid:any,id:any) {
this.loaderservice.show();
const formData = new FormData();
formData.append('id', id);

  this.interAssistService.getreport(formData).subscribe(
    (response: Blob) => {
     this.loaderservice.hide();
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      // Assuming process_name is a variable containing the process name
      const timestamp = new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
      // const fileNameParts = this.fileData?.name.split('_');
      const processName = reqid+'_'+id; // Assuming process name is the second part

      const filename = `Report_${processName}_${timestamp}.docx`;

      // Set the filename for the download
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      this.toastservice.showSuccess('File Downloaded Successfully');
      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

    }
  );
  }







generateAndDownloadReport(reportData: any) {
    // Construct HTML content for the report
     // Function to replace newlines with HTML line breaks

     const htmlContent = `
         <html>
             <head>
                 <style>
                     body { font-family: Arial, sans-serif; }
                     h2 { color: #00aecf; margin-top: 12px; }
                     p { margin: 4px; white-space: pre-wrap; } // Ensure spaces and formatting are retained
                 </style>
             </head>
             <body>
                 <h1 style="color: #00aecf; text-align: center;">Interview Evaluation Report</h1>
                 <h2>Candidate Score Card:</h2>
                 <p>${this.formatText(reportData["Candidate’s Score Card:"])}</p>
                 <h2>Feedback of the Candidate:</h2>
                 <p>${this.formatText(reportData["Feedback of the Candidate:"])}</p>
                 <h2>Feedback of the Interviewer:</h2>
                 <p>${this.formatText(reportData["Feedback of the Interviewer:"])}</p>
                 <h2>Overall Summary:</h2>
                 <p>${this.formatText(reportData["Overall Summary:"])}</p>
                 <h2>Review As Per the Job Profile or Requirement:</h2>
                 <p>${this.formatText(reportData["Review As Per the Job Profile or Requirement:"])}</p>
             </body>
         </html>
     `;
const content = htmlContent;
if (content) {
  HtmlDocx.asBlob(content).then((converted: Blob | Buffer) => { // Accept both Blob and Buffer
    if (converted instanceof Blob) {
      FileSaver.saveAs(converted, 'report.docx');
    } else {
      // Handle the case where converted is a Buffer
      // For example, you might need to convert it to a Blob first
      const blob = new Blob([converted], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
      FileSaver.saveAs(blob, 'report.docx');
    }
  });
}
}

formatText(text: string): string {
  // First, normalize newlines and replace them with <br> for initial split
  text = text.replace(/\n/g, '<br>');

  // Split the text into lines
  const lines = text.split('<br>');
  const transformedLines = [];
  let isListActive = false; // Track whether we're currently in a list

  lines.forEach(line => {
      // Check if the line should be a bullet point
      if (line.trim().startsWith('- ')) {
          if (!isListActive) {
              transformedLines.push('<ul>'); // Start a new list
              isListActive = true;
          }
          // Add the line as a list item, removing the "- " marker
          transformedLines.push(`<li>${line.trim().substring(2)}</li>`);
      } else {
          if (isListActive) {
              transformedLines.push('</ul>'); // Close the list
              isListActive = false;
          }
          // Add normal line
          transformedLines.push(`<p>${line}</p>`);
      }
  });

  // If the list was not closed, close it
  if (isListActive) {
      transformedLines.push('</ul>');
  }

  return transformedLines.join(''); // Join all transformed lines back into a single string
}





onFileSelected(event: any, fileType: 'CV' | 'JD'): void {
  const file = event.target.files[0];
  if (file) {
    this.files.push({
      file: file,
      type: fileType,
      format: file.name.split('.').pop()
    });
  }
}

uploadAllCVs(): void {
  this.loaderservice.show();
  const formData = new FormData();
  this.files.filter((f: { type: string; }) => f.type === 'CV').forEach((cv: { file: Blob; }) => {
    formData.append('cv_file', cv.file);
  });

  this.files.filter((f: { type: string; }) => f.type === 'JD').forEach((jd: { file: Blob; }) => {
    formData.append('jd_file', jd.file);
  });

  formData.append('id', this.current_id); // Append the unique id to the form data
  console.log(formData)

  this.interAssistService.uploadcvjd(formData).subscribe
  (
    (response:any) => {

      if(response.status ==  'success'){
    this.loaderservice.hide();
    this.toastservice.showSuccess(response.message);
      }
  },
  (error: { message: string; }) => {
    this.loaderservice.hide();
    this.toastservice.showError(error.message);
  }


  );
}

removeFile(index: number): void {
  this.files.splice(index, 1);
}

displayedFiles(): FileDetail[] {
  return this.displayType === 'All' ? this.files : this.files.filter(file => file.type === this.displayType);
}

get filteredItems(): Item[] {
  const filtered = this.items.filter(item => item.rid.toLowerCase().includes(this.searchTerm.toLowerCase()));
  return this.sortItems(filtered);
}



sortData(key: string): void {
  if (this.sortKey === key) {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
  } else {
    this.sortKey = key;
    this.sortDirection = 'asc';
  }
}

sortItems(items: Item[]): Item[] {
  return items.sort((a, b) => {
    const aValue = a[this.sortKey as keyof Item];
    const bValue = b[this.sortKey as keyof Item];

    if (aValue < bValue) return this.sortDirection === 'asc' ? -1 : 1;
    if (aValue > bValue) return this.sortDirection === 'asc' ? 1 : -1;
    return 0;
  });
}

// toggleAllCheckboxes(checked: boolean): void {
//   this.items.forEach(item => item.selected = checked);
// }
  get totalPages(): number {
    return Math.ceil(this.filteredItems.length / this.itemsPerPage);
  }

  

  get paginatedItems(): Item[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    return this.filteredItems.slice(startIndex, startIndex + this.itemsPerPage);
  }


  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }


  onItemsPerPageChange(event: Event): void {
    const input = event.target as HTMLSelectElement;
    this.itemsPerPage = Number(input.value);
    this.currentPage = 1; // Reset to first page on items per page change
  }


toggleAllCheckboxes(event: any) {
  const checked = event.target.checked;
  this.items.forEach(item => (item.selected = checked));
}

applyJobFilter(event: Event) { // Renamed the filter function
  const filterValue = (event.target as HTMLInputElement).value;
  this.jobDataSource.filter = filterValue.trim().toLowerCase();

  if (this.jobDataSource.paginator) {
    this.jobDataSource.paginator.firstPage();
  }
}

toggleAllJobCheckboxes(checked: boolean) { // Renamed the checkbox toggle function
  this.jobDataSource.data.forEach(item => (item.selected = checked));
}

logfun(rid:any,status:any){
  console.log(rid,status);
}
performAction(item: any) {
  // Define your action logic here
  console.log('Action triggered for:', item);
}

getlist1() {
  // Your save logic here
  console.log('Saving...');
}

analyzeFiles(item: any) {
  const formData = new FormData();
  this.isLoading = true;

  // Fetch the JD file and append it to formData
  fetch(item.jdLink)
    .then(res => res.blob())
    .then(blob => {
      const jdFile = new File([blob], this.getFileNameFromUrl(item.jdLink));
      formData.append('jd', jdFile);

      // Fetch and append CV files one by one
      Promise.all(item.cv.map((cv: { link: string; }) => this.fetchFile(cv.link)))
        .then((files: File[]) => {
          files.forEach(file => formData.append('cvs', file));

          // Call the new cvjdmatch API
          this.interAssistService.cvjdmatch(formData).subscribe(
            (response: any) => {
              if (response.status === 'success') {
                this.toastservice.showSuccess(response.message);
                // Handle and process the comparison data returned by the API
                this.processComparisonResults(response.data);
              } else {
                this.toastservice.showError(response.message);
              }
              this.isLoading = false;
            },
            (error: any) => {
              this.isLoading = false;
              this.toastservice.showError('Error: ' + error.message);
              console.error('Error:', error);
            }
          );
        })
        .catch(error => {
          this.isLoading = false;
          console.error('Error fetching CV files:', error);
        });
    })
    .catch(error => {
      this.isLoading = false;
      console.error('Error fetching JD file:', error);
    });
}

// Helper function to fetch individual CV files
fetchFile(link: string): Promise<File> {
  return fetch(link)
    .then(res => res.blob())
    .then(blob => {
      return new File([blob], this.getFileNameFromUrl(link));
    });
}

// Extract file name from URL
getFileNameFromUrl(url: string): string {
  return url.substring(url.lastIndexOf('/') + 1);
}

// Function to process the comparison results
processComparisonResults(data: any) {
  this.comparisonResults = data.map((result: any) => ({
    fileName: result.file_name, // Add file name here
    educationMatch: this.extractMatch(result.based_on_education),
    experienceMatch: this.extractMatch(result.based_on_experience),
    skillsMatch: this.extractMatch(result.based_on_skills),
    overallScore: result.matching_score,
  }));
}

// Helper function to clean and extract the match details and score
private extractMatch(field: string): { details: string; score: number } {
  // Remove unwanted markdown and formatting characters
  const cleanedField = field.replace(/\*\*(.*?)\*\*/g, '$1') // Remove **
                                .replace(/###/g, '') // Remove ##
                                .replace(/\n/g, '<br/>'); // Convert newlines to <br>
  
  // Extract score from the cleaned field
  const scoreMatch = cleanedField.match(/Score:\s*(\d+)/i); // Look for "Score: X/X"
  const score = scoreMatch ? parseInt(scoreMatch[1], 10) : 0; // Default to 0 if no score found

  // Remove score information from details for display
  const details = cleanedField.replace(/Score:\s*\d+/i, '').trim(); // Remove score line

  return { details, score };
}

getProgressBarClass(score: number): string {
  if (score >= 75) {
    return 'bg-success'; // Green for high scores
  } else if (score >= 50) {
    return 'bg-warning'; // Yellow for moderate scores
  } else {
    return 'bg-danger'; // Red for low scores
  }
}


// // Helper method to fetch a file from a URL
// fetchFile(url: string): Promise<File> {
//   return fetch(url)
//     .then(res => res.blob())
//     .then(blob => new File([blob], this.getFileNameFromUrl(url)));
// }

// // Helper method to extract the file name from a URL
// getFileNameFromUrl(url: string): string {
//   return url.substring(url.lastIndexOf('/') + 1);
// }

// Hafeez code

// analyzeFiles(item: any) {
//   const formData = new FormData();
// //  formData.append('requisitionId', item.rid);
// // this.loaderservice.show();
// this.isLoading = true;
//   // Fetch the JD file and append it to formData
//   fetch(item.jdLink)
//     .then(res => res.blob())
//     .then(blob => {
//       const file = new File([blob], this.getFileNameFromUrl(item.jdLink));
//       formData.append('files', file);

//       // Fetch and append CV files one by one
//       Promise.all(item.cv.map((cv: { link: string; }) => this.fetchFile(cv.link)))
//         .then((files: File[]) => {
//           files.forEach(file => formData.append('files', file));
//           // formData.append('id', this.tabid); // Append the unique id to the form data
          
//           // Make the API call after all files have been appended
//           this.interAssistService.uploadbyod(formData).subscribe(
//             (response: any) => {
//               // Handle the response
//               if (response.status == 'success') {
//                 this.toastservice.showSuccess(response.message);
//                 this.byodstatus();
//               }
//             },
//             (error: any) => {
//               // Handle the error
//               // this.loaderservice.hide();
//               this.isLoading = false;

//               console.error('Error:', error);
//             }
//           );
//         })
//         .catch(error => {
//           // this.loaderservice.hide();
//           this.isLoading = false;

//           console.error('Error fetching CV files:', error);
//         });
//     })
//     .catch(error => {
//       // this.loaderservice.hide();
//       this.isLoading = false;

//       console.error('Error fetching JD file:', error);
//     });
// }

// // Helper method to fetch a file from a URL
// fetchFile(url: string): Promise<File> {
//   return fetch(url)
//     .then(res => res.blob())
//     .then(blob => new File([blob], this.getFileNameFromUrl(url)));
// }

// // Helper method to extract the file name from a URL
// getFileNameFromUrl(url: string): string {
//   return url.substring(url.lastIndexOf('/') + 1);
// }



byodstatus() {
  // Your logic here
  // this.loaderservice.show();
  this.isLoading = true;
  const payload = {
    gcp_status: 'false',
  }
this.interAssistService.byodstatus(payload).subscribe(
  (response: any) => {
    // Handle the response
    if(response.status ==  'success'){
      this.toastservice.showSuccess(response.message);
      if(response.message ==+ '"Response is not generated yet'){
        
        //call status api after ten secs
        setTimeout(() => {
          this.byodstatus();
        }, 10000);
        return;
      }
      else{
        this.toastservice.showSuccess(response.message);
        this.getanswer();
      }
    }
    
  },
  (error: any) => {
    // this.loaderservice.hide();
    this.isLoading = false;

    // Handle the error
  }

);


}

getanswer() {
  const payload = {
    filename : 'multi',
    kbname : null,
    "userquery": 'Compare all the resumes with the job description or profile and tell me the matching score with each resume in detailed version',
    "messages": [{"role":"user","content":" Compare all the resumes with the job description or profile and tell me the matching score with each resume in detailed version"}],
  }
  // this.loaderservice.show();
  this.isLoading = true;
  this.interAssistService.getanswer(payload).subscribe(
    (response: any) => {
      // Handle the response
      if(response.status ==  'success'){
        this.toastservice.showSuccess(response.message);
        this.refsummary = response.data;
        document.getElementById('cvsumbtn')?.click();
        // this.loaderservice.hide();
        this.isLoading = false;

      }
      
    },
    (error: any) => {
      // this,this.loaderservice.hide();
      this.isLoading = false;

      // Handle the error
    }
  );

}
openModal(template: TemplateRef<void>, size='modal-md') {
  this.modalRef = this.modalService.show(template, {class:size, ignoreBackdropClick: true });
}

// onSave() {
//   const selectedItems = this.items.filter(item => item.selected);

//   if (selectedItems.length === 0) {
//     this.toastservice.showWarning('No items selected');
//     return;
//   }

//   selectedItems.forEach(item => {
//     this.createUser1(item); // This will handle user creation and file upload
//   });
// }


// createUser1(item: any) {
//   this.loaderservice.show();

//   const payload = {
//     "requisition_id": item.rid,
//     "position": item.jd,
//     "interviewer_name": item.cv.map((cv: any) => cv.interviewer).join(', '),
//     "candidate_name": item.cv.map((cv: any) => cv.name).join(', ')
//   };

//   this.interAssistService.createuser(payload).subscribe(
//     (response: any) => {
//       this.loaderservice.hide();
//       if (response) {
//         this.toastservice.showSuccess(response?.message);
//         this.uploadAllCVs1(item); // Call to upload all CVs after creating the user
//         this.getlist();
//       }
//     },
//     (error: any) => {
//       this.loaderservice.hide();
//       this.toastservice.showError('User Creation Failed');
//       console.error('Error creating user:', error);
//     }
//   );
// }

// uploadAllCVs1(item: any) {
//   const formData = new FormData();

//   const fetchFile = (url: string): Promise<File> => {
//     return fetch(url)
//       .then(res => res.blob())
//       .then(blob => {
//         const fileName = url.substring(url.lastIndexOf('/') + 1);
//         return new File([blob], fileName);
//       });
//   };

//   fetchFile(item.jdLink).then(jdFile => {
//     formData.append('jd_file', jdFile);

//     Promise.all(item.cv.map((cv: { link: string; }) => fetchFile(cv.link)))
//       .then((cvFiles: File[]) => {
//         cvFiles.forEach(file => formData.append('cv_file', file));

//         formData.append('id', this.tabid); // Append the unique id to the form data
//         this.interAssistService.uploadcvjd(formData).subscribe(
//           (response: any) => {
//             if (response.status === 'success') {
//               this.toastservice.showSuccess(response.message);
//             }
//           },
//           (error: any) => {
//             console.error('Error uploading files:', error);
//           }
//         );
//       })
//       .catch(error => {
//         console.error('Error fetching CV files:', error);
//       });
//   })
//   .catch(error => {
//     console.error('Error fetching JD file:', error);
//   });
// }

onSave() {
  const selectedItems = this.items.filter(item => item.selected);

  if (selectedItems.length === 0) {
    this.toastservice.showWarning('No items selected');
    return;
  }

  selectedItems.forEach(item => {
    item.cv.forEach(cv => {
      this.createUser1(item, cv); // Create user for each CV
    });
  });
}

createUser1(item: any, cv: any) {
  this.loaderservice.show();

  const payload = {
    "requisition_id": item.rid,
    "position": item.jd,
    "interviewer_name": cv.interviewer,
    "candidate_name": cv.name
  };

  this.interAssistService.createuser(payload).subscribe(
    (response: any) => {
      this.loaderservice.hide();
      if (response) {

        this.current_id = response.id;
        console.log(this.current_id)
        this.toastservice.showSuccess(response?.message);
        // this.uploadFiles(item, cv); 
        this.uploadFiles(item, cv, this.current_id); // Upload JD and CV files
        this.getlist();
      }
    },
    (error: any) => {
      this.loaderservice.hide();
      this.toastservice.showError('User Creation Failed');
      console.error('Error creating user:', error);
    }
  );
}


openModel() {
  this.isModalOpen = true;
}

closeModal() {
  this.isModalOpen = false;
}

onfileSelected(event: any, side: string) {
  const file = event.target.files[0];
  const reader = new FileReader();

  reader.onload = () => {
    if (side === 'left') {
      this.leftImage = file;
      this.leftImageUrl = reader.result as string;
    } else if (side === 'right') {
      this.rightImage = file;
      this.rightImageUrl = reader.result as string;
    }
  };

  if (file) {
    reader.readAsDataURL(file);
  }
}


openSingleImageModal(id: string) {
  this.selectedId = id; // Store the selected ID for use in API call
  this.isSingleImageModalOpen = true;
}

// Method to close the single image modal
closeSingleImageModal() {
  this.isSingleImageModalOpen = false;
  this.resetModal();
}

resetModal() {
  this.singleImageUrl = null;
  this.verificationResult = null;
  this.selectedId = null; // Reset selected ID
}

onSingleFileSelected(event: Event) {
  const fileInput = event.target as HTMLInputElement; // Cast event target to HTMLInputElement
  const file = fileInput.files?.[0]; // Get the first file

  if (file) {
    const reader = new FileReader();
    reader.onload = () => {
      this.singleImageUrl = reader.result; // Set the image URL for preview
    };
    reader.readAsDataURL(file); // Read the file as Data URL
  }
}

verifyImage() {
  const formData = new FormData();
  const fileInput = (document.querySelector('input[type=file]') as HTMLInputElement);
  const file = fileInput.files?.[0]; // Get the selected file

  if (file && this.selectedId) { // Ensure an ID is selected
    formData.append('id_card', file); // Append the file to FormData
    formData.append('id', this.selectedId); // Append the selected ID

    // Send the request to the verification API
    this.interAssistService.verifyid(formData)
      .subscribe(
        (response: any) => {
          this.verificationResult = response; // Handle successful response
        },
        (error) => {
          console.error('Verification error:', error);
          this.verificationResult = {
            status: 'failed',
            message: 'Verification failed. Please try again.'
          };
        }
      );
  } else {
    console.error('No file selected or ID not set.');
  }
}

imgverify(){
  if (this.leftImage && this.rightImage) {
      const formData = new FormData();
      formData.append('face_1', this.leftImage);
      formData.append('face_2', this.rightImage);

      this.errorMessage = null;
      this.interAssistService.verifyface(formData).subscribe({
      // this.http.post<any>('https://api.playground-dev.genpact.com/hackathon/verify-id', formData).subscribe({
        next: (response) => {
          this.verificationResponse = response;
          console.log(this.verificationResponse)
        },
        error: (error) => {
          this.errorMessage = 'There was an error verifying the faces.';
        }
      });
    } else {
      this.errorMessage = 'Please upload both images.';
    }
  }

  extractConfidence(data: string): string {
    const match = data.match(/confidence of ([\d.]+)/);
    return match ? match[1] : 'N/A';
  }


uploadFiles(item: any, cv: any,current_id: string) {
  const formData = new FormData();
  
  // Helper function to fetch files
  const fetchFile = (url: string): Promise<File> => {
    return fetch(url)
      .then(res => res.blob())
      .then(blob => {
        const fileName = url.substring(url.lastIndexOf('/') + 1);
        return new File([blob], fileName);
      });
  };
  formData.append('id', current_id);
  // Fetch JD file and append to FormData
  fetchFile(item.jdLink).then(jdFile => {
    formData.append('jd_file', jdFile);
    
    // Fetch CV file and append to FormData
    fetchFile(cv.link).then(cvFile => {
      formData.append('cv_file', cvFile);
      // formData.append('requisition_id', item.rid);
      // formData.append('candidate_name', cv.name);
      // formData.append('with_images', 'false');

      // Call the API to upload files
      this.interAssistService.uploadcvjd(formData).subscribe(
        (response: any) => {
          if (response.status === 'success') {
            this.toastservice.showSuccess(`JD and CV files for ${cv.name} uploaded successfully`);
          }
        },
        (error: any) => {
          console.error('Error uploading files:', error);
        }
      );
    })
    .catch(error => {
      console.error('Error fetching CV file:', error);
    });
  })
  .catch(error => {
    console.error('Error fetching JD file:', error);
  });
}

offerLetter(){
  console.log("Generate offer letter button clicked")
}
}
