import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/internal/operators/catchError';
import { Observable, from } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class TcgService {

  constructor(
    private http: HttpClient
  ) { }

  endpoint = environment.nodeEndpoint;
  apiURL = environment.apiURL;

  // fileupload(payload: any) {
  //   return this.http.post(`${this.apiURL}/test_case/upload-rasheet`, payload);
  // }


  //  getDataAsPromise(url:string,data:any={ method: 'GET'}): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     fetch(url,data)
  //       .then(response => response.json())
  //       .then(result => resolve(result))
  //       .catch(error => resolve(error));
  //   });
  // }


  getDataAsPromise(url:any, data:any = { method: 'GET' }) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        ...data,
        headers: {
          ...data.headers,
          'Authorization':  'Bearer ' + localStorage.getItem('token'), // Replace with your actual token
        },
      })
        .then(response => response.json())
        .then(result => resolve(result))
        .catch(error => resolve(error));
    });
  }



  fileupload(paylaod: any): Observable<any> {

    
    const promise = this.getDataAsPromise(`${this.apiURL}/test_case/upload-rasheet`,{ method: 'POST',body:paylaod}); // Calling your method that returns a Promise
    return from(promise);
  }

  sddupload(payload: any) {
    return this.http.post(`${this.apiURL}/test_case/upload-sdd`, payload);
  }
  fileDownload(payload: any): Observable<any> {
    // Add your bearer token to the request headers
    let token: any = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
      //'Content-Type': 'application/json'
    });

    // Make sure responseType is set to 'blob' to handle file download
    return this.http.post(`${this.apiURL}/test_case/testcase_status`, payload, {
    //  headers: headers,
      responseType: 'blob'
    });
  }

generatetestcase(payload: any) {
  return this.http.post(`${this.apiURL}/test_case/generate-testcase`, payload);
}

testcasestatus() {
  return this.http.post(`${this.apiURL}/test_case/testcase_status`, {});
}


  testcase() {
    return this.http.get(`${this.apiURL}/test_case/generate-rasummary`, {});
  }

  testcase1(payload: any) {
    return this.http.post(`${this.apiURL}/test_case/generate-rasummary`, payload);
  }

}
