 import { Component,Input, Output, EventEmitter, Inject, OnInit  } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageTypeSnack, StatusCode } from '../../../../data/global-vars';
import { SharedService } from '../../../../Services/shared.service';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    standalone: false
})
export class SettingsComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<SettingsComponent>, private sharedService: SharedService) { }
    ngOnInit(): void {
      //throw new Error('Method not implemented.');
      // this.getSubscriptionStatus();
    }


  onClose(): void {
    this.dialogRef.close();
  }

  // getSubscriptionStatus() {
  //   this.subsService.checkSubscription().subscribe({
  //     next: res => {
  //       if (res.status && res.status_code == StatusCode.Success) {
  //         if (res.result.is_subscription_valid) {
  //           //let ohr = res.result.ohr_id;
  //           //this.username = res.result.ohr_id;
  //           //this.subsService.setAuthStatus(true, false);
  //           this.subsValidTill = res.result.valid_till;
  //           this.subsCode = res.result.subs_code;
  //         }

  //       } else {
  //         this.sharedService.openSnackBar(res.message, MessageTypeSnack.Error);
  //       }

  //     }
  //   })
  // }

  // openDoc(){
  //   //this.dialogRef.close();
  //   //console.log('This will Open file');
  // }
  downloadDoc(){
    this.dialogRef.close(true);
    //console.log('This will downlaod file');

  }

}
