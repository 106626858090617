import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MultiagentService {

  endpoint = 'https://api.playground-dev.genpact.com/';
  endpoint1 = environment.apiURL;
  endpoint2 = '';

  constructor(private http: HttpClient, private router: Router) { }

  getThread(query: string): Observable<any> {
    return this.http.post(`${this.endpoint}agentapi/start`, { task: query })
      .pipe(catchError(this.handleError));
  }

  getChat(threadId: number): Observable<any> {
    return this.http.post(`${this.endpoint}agentapi/response`, { thread_id: threadId })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    console.error('An error occurred:', error.message);
    return throwError(() => new Error('An error occurred; please try again later.'));
  }
}
