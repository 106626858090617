import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OktaAuthModule } from '@okta/okta-angular';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConverseAiComponent } from './converse-ai/converse-ai.component';
import { HomeComponent } from './home/home.component';
import { LogoutComponent } from './logout/logout.component';
import { SopSidenavComponent } from './sop-sidenav/sop-sidenav.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';


import { OktaAuth } from '@okta/okta-auth-js';
import { AuthInterceptor } from './auth.interceptor';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ClipboardModule } from 'ngx-clipboard';
import { MarkdownModule } from 'ngx-markdown';

import { SecurityContext } from '@angular/core';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { ProfileDropdownComponent } from './shared/components/profile-dropdown/profile-dropdown.component';
import { StopProcessComponent } from './shared/components/stop-process/stop-process.component';
import { UploadFileComponent } from './shared/components/upload-file/upload-file.component';
import { UsermanageComponent } from './usermanage/usermanage.component';

import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SafePipe } from 'src/app/shared/Pipes/safe.pipe';
import { AutoResizeDirective } from './shared/directive/auto-resize.directive';

import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { ConfigService } from 'src/app/shared/service/config.service';
import { AdminPortalComponent } from './admin-portal/admin-portal.component';



// material module imported here...............
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';


import { HeaderComponent } from './codeGenYLite/components/includes/header/header.component';
import { FooterComponent } from './codeGenYLite/components/includes/footer/footer.component';
import { RoundLoaderComponent } from './codeGenYLite/components/utilities/roundloader/roundloader.component';
import { ProductsComponent } from './codeGenYLite/components/products/products.component';
import { FeaturesComponent } from './codeGenYLite/components/features/features.component';
import { CompanyComponent } from './codeGenYLite/components/company/company.component';
import { TabsComponent } from './codeGenYLite/components/includes/tabs/tabs.component';
import { CodeEnhancementComponent } from './codeGenYLite/components/products/code-enhancement/code-enhancement.component';
import { TextAreaComponent } from './codeGenYLite/components/includes/text-area/text-area.component';
import { CodeDocumentationComponent } from './codeGenYLite/components/products/code-documentation/code-documentation.component';
import { TimeouterrorComponent } from './codeGenYLite/components/includes/popup/timeouterror/timeouterror.component';

import { ButtonComponent } from './codeGenYLite/components/includes/button/button.component';
import { BuildYourProjectComponent } from './codeGenYLite/components/products/build-your-project/build-your-project.component';
import { BRDComponent } from './codeGenYLite/components/products/build-your-project/brd/brd.component';
import { SDDComponent } from './codeGenYLite/components/products/build-your-project/sdd/sdd.component';
import { GenerateCodeComponent } from './codeGenYLite/components/products/build-your-project/generate-code/generate-code.component';

import { RoundLoaderService } from './codeGenYLite/Services/roundloader.service';

import { LoaderInterceptor } from './codeGenYLite/Interceptors/loader.interceptor';

import { DevTalkComponent } from './codeGenYLite/components/products/dev-talk/dev-talk.component';
import { CompletionpopupComponent } from './codeGenYLite/components/includes/popup/completionpopup/completionpopup.component';
import { BRDcompleteComponent } from './codeGenYLite/components/includes/popup/brdcomplete/brdcomplete.component';
import { SettingsComponent } from './codeGenYLite/components/includes/popup/settings/settings.component';
import { HomeMainComponent } from './codeGenYLite/components/home-main/home-main.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { CardComponent } from './codeGenYLite/components/includes/card/card.component';
import { ErrorComponent } from './codeGenYLite/components/includes/popup/error/error.component';
import { NgxEditorModule } from 'ngx-editor';
import { ValidationDirective } from './codeGenYLite/directives/validation.directive';
import { CommonModule } from '@angular/common';
import { HomeComponent1 } from './codeGenYLite/components/home/home.component';
// import { DevhomeComponent } from './codeGenYLite/components/devhome/devhome.component';
import { MultipleUploadComponent } from './shared/components/multiple-upload/multiple-upload.component';
import { CodeGenYLiteAIComponent } from './codeGenYLiteAI/code-gen-ylite-ai.component';
import { DocgenerateComponent } from './codeGenYLiteAI/docgenerate/docgenerate.component';
import { ApigenerateComponent } from './codeGenYLiteAI/apigenerate/apigenerate.component';
import { CodegenerateComponent } from './codeGenYLiteAI/codegenerate/codegenerate.component';
import { SddaiComponent } from './codeGenYLiteAI/sddai/sddai.component';
import { EnhancecodeComponent } from './codeGenYLiteAI/enhancecode/enhancecode.component';
import { DocumentcodeComponent } from './codeGenYLiteAI/documentcode/documentcode.component';
import { ChatcodeComponent } from './codeGenYLiteAI/chatcode/chatcode.component';
import { TcgenerateComponent } from './codeGenYLiteAI/tcgenerate/tcgenerate.component';
import { InterviewAssistantComponent } from './interview-assistant/interview-assistant.component';
import { CvJdComponent } from './interview-assistant/cv-jd/cv-jd.component';
import { QandAComponent } from './interview-assistant/qand-a/qand-a.component';
import { Sidenav1Component } from './interview-assistant/sidenav1/sidenav1.component';
import { TranscriptComponent } from './interview-assistant/transcript/transcript.component';
import { IaloginComponent } from './interview-assistant/ialogin/ialogin.component';
import { ReportAnalysisComponent } from './interview-assistant/report-analysis/report-analysis.component';





import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

import { MatTabsModule } from '@angular/material/tabs';
import { IhomeComponent } from './interview-assistant/ihome/ihome.component';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { CodeadjustComponent } from './codeGenYLiteAI/codeadjust/codeadjust.component';
import { UserstoryComponent } from './codeGenYLiteAI/userstory/userstory.component';
// import { ImageVerifyComponent } from './interview-assistant/image-verify/image-verify.component';

import { DocxExportService } from './shared/service/docx-export.service';
import { CghomeComponent } from './cghome/cghome.component';

import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ColloborativeAgentComponent } from './colloborative-agent/colloborative-agent.component';

import { CglComponent } from './cgl/cgl.component';
import { CheaderComponent } from './cheader/cheader.component';

import { CarouselComponent } from './carousel/carousel.component';
// import { CodegenyliteAIComponent } from './codegenylite-ai/codegenylite-ai.component';


const oktaAuth = new OktaAuth(environment.oidc);
export function initializeApp(configService: ConfigService) {
  return () => configService.getConfig();
}
@NgModule(
  {
    declarations: [
      AppComponent,
      SafePipe,
      SopSidenavComponent,
      StopProcessComponent,
      LoaderComponent,
      UploadFileComponent,
      ProfileDropdownComponent,
      HomeComponent,
      UnauthorizedComponent,
      LogoutComponent,
      ConverseAiComponent,
      UsermanageComponent,
      AutoResizeDirective,
      AdminPortalComponent,
      HeaderComponent,
      FooterComponent,
      ProductsComponent,
      FeaturesComponent,
      CompanyComponent,
      TabsComponent,
      CodeEnhancementComponent,
      TextAreaComponent,
      CodeDocumentationComponent,
      ButtonComponent,
      BuildYourProjectComponent,
      BRDComponent,
      SDDComponent,
      GenerateCodeComponent,
      DevTalkComponent,
      CompletionpopupComponent,
      BRDcompleteComponent,
      SettingsComponent,
      TimeouterrorComponent,
      HomeMainComponent,
      CardComponent,
      ErrorComponent,
      ValidationDirective,
      HomeComponent1,
      // DevhomeComponent,
      MultipleUploadComponent,
      RoundLoaderComponent,
      CodeGenYLiteAIComponent,
      DocgenerateComponent,
      ApigenerateComponent,
      CodegenerateComponent,
      SddaiComponent,
      EnhancecodeComponent,
      DocumentcodeComponent,
      ChatcodeComponent,
      TcgenerateComponent,
      InterviewAssistantComponent,
      CvJdComponent,
      QandAComponent,
      Sidenav1Component,
      TranscriptComponent,
      IaloginComponent,
      ReportAnalysisComponent,
      IhomeComponent,
      CodeadjustComponent,
      UserstoryComponent,
      // ImageVerifyComponent,
      CghomeComponent,



    ],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
      AppRoutingModule,
      FormsModule,
      CheaderComponent,
      CarouselComponent,
      ColloborativeAgentComponent,

      MatCardModule,
      NgxDocViewerModule,
      MatDialogModule,
      MatToolbarModule,
      ReactiveFormsModule,
      FontAwesomeModule,
      CodemirrorModule,
      NgxEditorModule,
      CommonModule,
      MatSelectModule,
      MatFormFieldModule,
      MatMenuModule,
      MatButtonModule,
      MatProgressSpinnerModule,
      MatSnackBarModule,
      MatTooltipModule,
      MatTableModule,
      MatPaginatorModule,
      MatSortModule,
      MatTabsModule,
      MatInputModule,
      MatAutocompleteModule,
      ModalModule.forRoot(),
      OktaAuthModule,
      OktaAuthModule.forRoot({ oktaAuth }),
      ToastrModule.forRoot({
        timeOut: 5000,
        positionClass: 'toast-bottom-right',
        preventDuplicates: true,
      }), // ToastrModule added
      TooltipModule.forRoot(),
      ClipboardModule,
      MarkdownModule.forRoot({
        sanitize: SecurityContext.NONE
      }),
      NgMultiSelectDropDownModule.forRoot(),
      BrowserAnimationsModule, // required animations module
      MonacoEditorModule.forRoot()], providers: [
        RoundLoaderService,
        DocxExportService,
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        ConfigService,
        {
          provide: MatDialogRef,
          useValue: {}
        },
        {
          provide: APP_INITIALIZER,
          useFactory: initializeApp,
          deps: [ConfigService], multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
      ]
  })
export class AppModule { }
