import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
    selector: 'textarea[autoResize]',
    standalone: false
})
export class AutoResizeDirective {

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event.target'])
  onInput(textArea: HTMLTextAreaElement): void {
    this.adjustTextAreaHeight(textArea);
  }

  ngAfterViewInit(): void {
    this.adjustTextAreaHeight(this.elementRef.nativeElement);
  }

  private adjustTextAreaHeight(textArea: HTMLTextAreaElement): void {
    // Reset height to auto to properly calculate the scroll height
    this.renderer.setStyle(textArea, 'height', 'auto');

    // Set the maximum height for the textarea
    const maxHeight = 200; // Change this value as needed

    // Set the textarea height to the scroll height or the maximum height, whichever is smaller
    this.renderer.setStyle(textArea, 'height', `${Math.min(textArea.scrollHeight, maxHeight)}px`);
  }
}
