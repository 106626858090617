<div class="ai-agent-container container-fluid text-light" style="padding-top: 10px; font:FunnelSans-Regular; color: #000000;
    padding-left: 15px;">


    <!-- Header Section with Dropdown for Model Selection -->
    <header class="row">
        <!-- First line: Logo and Title -->
        <div class="col-12 text-center d-flex align-items-center">
            <img src="assets/latest_styling/Genpact Logo White.png" width="135px" height="65px"
                style="margin-left: 4px;" />
            <h1 class="gradient-text ms-4" style="margin-left: 420px !important;">AI Assistant</h1>
        </div>

        <!-- Second line: Toggle bar and model selection -->
        <div class="col-12 d-flex justify-content-between align-items-center"
            style="height: 55px !important;margin-left: 7px;">
            <!-- Toggle buttons group -->
            <div class="btn-group" role="group">
                <input type="radio" class="btn-check" name="searchMode" id="bingMode" autocomplete="off"
                    [checked]="isBingSelected" (change)="selectBing()">
                <label class="btn btn-outline-light" for="bingMode">Research Assistant</label>

                <input type="radio" class="btn-check" name="searchMode" id="sharepointMode" autocomplete="off"
                    [checked]="!isBingSelected" (change)="selectSharePoint()">
                <label class="btn btn-outline-light" for="sharepointMode">SharePoint Search</label>
            </div>

            <!-- Dropdown for Model Selection -->
            <div class="model-selector d-flex align-items-center" style="margin-right: 10px;">
                <label for="modelSelect" class="text-light me-2"
                    style="font-family: FunnelSans-Regular !important;">Select Model:</label>
                <select id="modelSelect"
                    style="border-radius: 40px !important;font-family: FunnelSans-Regular !important;    width: auto;"
                    class="form-select" [(ngModel)]="selectedModel" (change)="onModelChange()">
                    <option value="azure_openai">Azure OpenAI 4o</option>
                    <option value="aws_bedrock">AWS Bedrock Claude</option>
                    <option value="google_gemini">Google Gemini 2.0</option>
                </select>
            </div>
        </div>
    </header>






    <!-- Full height chat container -->
    <div class="chat-box p-3 bg-secondary border rounded shadow-sm mb-3" style="padding-bottom: 85px !important;"
        #scrollChat>
        <div class="chat-message" *ngFor="let chat of chatData; let i = index">
            <div class="user-message" *ngIf="chat.role === 'user'">
                <div class="message-bubble user-bubble">{{ chat.content }}</div>
            </div>

            <div class="bot-message" *ngIf="chat.role !== 'user'">
                <div class="message-bubble bot-bubble">
                    <div markdown lineNumbers>{{chat.content}}</div>
                </div>

                <!-- Suggested Questions -->
                <div class="suggested-questions" *ngIf="chat.section === 'question'">
                    <h6 class="text-light">{{ chat.title }}</h6>
                    <div class="question" *ngFor="let question of chat.content" (click)="inputPrompt(question)">
                        <span class="btn btn-sm btn-outline-info">{{ question }}</span>
                    </div>
                </div>

                <!-- Sources Display -->
                <div class="sources" *ngIf="chat.section === 'sources'">
                    <h6 class="text-light">{{ chat.title }}</h6>
                    <div class="row">
                        <div class="col-6 mb-2" *ngFor="let item of chat.content">
                            <div class="source-item d-flex align-items-center">
                                <div [innerHTML]="item.imageTag" class="me-2"></div>
                                <a href="{{item.url}}" target="_blank" class="text-info text-truncate">{{item.name}}</a>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Copy & Regenerate Buttons - Always Visible -->
                <div class="user-action mt-2" *ngIf="chat.section != 'question' && chat.section != 'sources'">
                    <button class="btn btn-sm btn-outline-light me-2" style="color: black !important;"
                        (click)="copyToClipboard(chat.content)">
                        📋 Copy
                    </button>
                    <button class="btn btn-sm btn-outline-light" style="color: black !important;"
                        (click)="regenerate(i)">
                        🔄 Regenerate
                    </button>
                </div>
            </div>
        </div>

        <!-- Loader (visible only if loading) -->
        <div class="chatgpt-loader mt-3" *ngIf="isLoading">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="ms-2 text-light"
                style="color: black !important; font-family: FunnelSans-Regular !important;">Generating
                response...</span>
        </div>
    </div>

    <!-- Input Bar (Fixed) -->
    <div class="fixed-input-bar bg-dark border-top p-3 d-flex align-items-center justify-content-between">
        <textarea class="form-control me-2" rows="1" [(ngModel)]="prompt" style=" border-radius: 20px !important;
    margin-left: 18px;" (keydown.enter)="handleEnter()" placeholder="Type your message here..."></textarea>

        <button class="btn btn-outline-light me-2" style="padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 4px;
        padding-right: 4px;" (click)="openGoalModal(goalModal)" *ngIf="isBingSelected">
            🎯 Goal
        </button>

        <button class="btn btn-outline-light me-2" style="padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 4px;
        padding-right: 4px;" *ngIf="isBingSelected" (click)="openBackstoryModal(backstoryModal)">
            📖 Backstory
        </button>

        <button class="btn btn-primary" (click)="sendMessage()" title="Send">
            <i class="fas fa-paper-plane"></i>
        </button>
    </div>


    <!-- Goal Modal -->
    <ng-template #goalModal let-modal>
        <div class="custom-modal">
            <div class="custom-modal-header">
                <h5 class="modal-title">🎯 Set Your Goal</h5>
                <button type="button" class="close-btn" (click)="modal.dismiss('cancel')" aria-label="Close">✖</button>
            </div>
            <div class="custom-modal-body">
                <p class="modal-description">Define your current goal to help the assistant tailor responses to your
                    needs.
                </p>
                <textarea class="form-control styled-textarea" [(ngModel)]="goal"
                    placeholder="What are you aiming to achieve?"></textarea>
            </div>
            <div class="custom-modal-footer">
                <button class="btn btn-secondary" (click)="modal.dismiss('cancel')">Cancel</button>
                <button class="btn btn-primary" (click)="modal.close('save')">Save Goal</button>
            </div>
        </div>
    </ng-template>

    <!-- Backstory Modal -->
    <ng-template #backstoryModal let-modal>
        <div class="custom-modal">
            <div class="custom-modal-header">
                <h5 class="modal-title">📖 Add Backstory</h5>
                <button type="button" class="close-btn" (click)="modal.dismiss('cancel')" aria-label="Close">✖</button>
            </div>
            <div class="custom-modal-body">
                <p class="modal-description">Provide some context or background to help the assistant understand your
                    situation better.</p>
                <textarea class="form-control styled-textarea" [(ngModel)]="backstory"
                    placeholder="What should the assistant know?"></textarea>
            </div>
            <div class="custom-modal-footer">
                <button class="btn btn-secondary" (click)="modal.dismiss('cancel')">Cancel</button>
                <button class="btn btn-primary" (click)="modal.close('save')">Save Backstory</button>
            </div>
        </div>
    </ng-template>