import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-stop-process',
    templateUrl: './stop-process.component.html',
    styleUrls: ['./stop-process.component.scss'],
    standalone: false
})
export class StopProcessComponent {
  @Output() stopProgress = new EventEmitter<any>();

  endProgress(){
   this.stopProgress.emit(true);
  }
}
