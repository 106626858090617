import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { SharedService } from '../../codeGenYLite/Services/shared.service';
import { ActivityAudit, FileModel } from '../../codeGenYLite/data/app-model';
import { RoundLoaderService } from '../../codeGenYLite/Services/roundloader.service';
import { SopService } from 'src/app/shared/service/sop.service';
import { ToastMessageService } from '../../shared/service/toast-message.service';

@Component({
  selector: 'app-userstory',
  templateUrl: './userstory.component.html',
  styleUrls: ['./userstory.component.scss']
})
export class UserstoryComponent {
  @Output() switchTab = new EventEmitter<number>(); // Event emitter to notify the parent component
  @ViewChild('fileInput') fileInput: any;
  showGoToGenerateCode:boolean = false;  //go to generate code btn hide here.........
  invalidSize: boolean = false;
  invalidType: boolean = false;
  isLoading: boolean = false;
  selFiles: any;
  downloadLink: string = '';
  downloadLink2: string = '';
  docName: string = '';
  activityAudit: ActivityAudit = {
    ohr_id: '',
    time_stamp: '',
    module: '',
  }
  // docAvailable!: Subscription;
  isDocAvailable: boolean = false;
  isDocAvailableHasError: boolean = false;
  fileData: any;
  sddDocName: any;
  downloadbtn: boolean =  false;
docname: any;
docuploaded: boolean = false;
selectedFile: File | null = null;
namefile: any;
processName: any;
codelang: string[] = []; // To hold the selected values
codingLanguages: string[] = ['Angular','Apex', 'AngularJS','AURA', 'C#', 'CSS','Dataweave', 'Dax','Django', 'HTML', 'Java', 'JavaScript','JSON','LWC', 'MongoDB', 'MySQL', 'Node.js','Oracle', 'PHP', 'PostgreSQL','powershell', 'Python', 'React', 'Ruby','ServiceNow','SQL','Terraform', 'TypeScript','Unix','Vue.js','XML','XML (Taleo Connect Client)','YAML'];
// dialogRef!: MatDialogRef<CompletionpopupComponent, any>;
  constructor(public dialog: MatDialog, private sopService: SopService, private loaderService: RoundLoaderService, private toastMessage : ToastMessageService) { }
  ngOnDestroy(): void {
    // this.docAvailable.unsubscribe();
    }

    ngOnInit(): void {
        //throw new Error('Method not implemented.');
    }
  // onButtonSubmit() {
  //   if (this.selFiles && this.selFiles.length > 0) {
  //     this.sopService.generatesdd(this.selFiles).subscribe({


  //          else {

  //         }
  //         // this.activityLogging();
  //       },

  //     )};
  //       (error: any) => {
  //        this.isLoading = false;
  //         // this.activityLogging();
  //       },
  //     });
  //   }
  //   else {
  //     // this.sharedService.openSnackBar("Please upload a file", MessageTypeSnack.Error);
  //     this.toastMessage.
  //   }
  // }
  onupload(event: any) {
    const file1 = event.target.files[0];
  
    // Function to check the file extension
    const checkFileExtension = (fileName: string) => {
      const allowedExtension = '.docx';
      return fileName.slice(-allowedExtension.length).toLowerCase() === allowedExtension;
    };
  
    // Check if the file is a .docx file by MIME type and extension
    if (file1  && checkFileExtension(file1.name)) {
      this.docuploaded = true;
      this.selectedFile = file1;
      this.namefile = file1.name;
      // Proceed with further processing
    } else {
      this.docuploaded = false;
      this.selectedFile = null;
      this.namefile = '';
      this.fileInput.nativeElement.value = ''; // Clear the file input field
      // Display an error message
      this.toastMessage.showmessage('Only .docx files are allowed. Please upload a valid file.');
    }
  }
  onButtonSubmit(event : any) {
// if(this.codelang.length == 0){
//   this.toastMessage.showError('Please select coding language');
//   return;
// }
    const file = event;
    if (this.selFiles && this.selFiles.length > 0)
    // const file = this.selFiles[0];
    this.isLoading = true;
      this.fileData = file;
      console.log(this.fileData?.file, this.fileData?.name)
    // this.getOHR();
    const formData = new FormData();
    formData.append('brd_file', this.fileData);
    formData.append('process_name', this.processName);
    //coding_language": this.codelang.join(','), // Convert the array to a comma-separated string
    // formData.append('language', this.codelang.join(',')); // Convert the array to a comma-separated string

    //formData.append('user_id', this.userOHR);
  //  formData.append('app-name', this.functionSelected);
    this.sopService.generateuserstory(formData).subscribe(
      (response: any) => {
      // this.isLoading = false;
        console.log(response)
        if( response?.message == 'User Story generation is in progress and will be generated shortly')
        {
          this.sddDocName = 'SDD';
          // this.generatetestcase();
          // this.testcase=response.data;
          // this.fileUploadedStatus = true;
          // this.filestatus1=false;
          this.toastMessage.showSuccess(response?.message+"\nFileName: "+this.fileData?.name);
          this.checkSDDStatus();
         }
         else
         {
          this.toastMessage.showError(response?.message);
         this.isLoading = false;
         }
      },
      (error:any) => {
        // Handle the error here
       this.isLoading = false;
        console.error(error);
        this.toastMessage.showError(error?.message);
      }
    );

    // else {
    //   this.toastMessage.showError("Please upload a file");
    // }
  }

  checkSDDStatus() {
    this.sopService.userstorystatus(this.sddDocName).subscribe(
      (response: any) => {
          if(response?.message == 'User story is not ready') {
            this.docuploaded = false;
            this.downloadbtn = true;
            this.isLoading = true;
                    // Document not available yet, continue checking after 15 seconds
                    setTimeout(() => {
                      this.toastMessage.showmessage('Document is being generated, please wait for a moment.')
                      this.checkSDDStatus();
                    }, 15000); // 15 seconds in milliseconds
                  }
          else { // Document is ready
           this.isLoading = false;
            this.downloadbtn = true;
            // this.showGoToGenerateCode = true;
        }
      },
      (error) => {
       this.isLoading = false;
        console.error('Error downloading file:', error);
        // Handle error as needed
      }
    );
  }

  triggerFileInputClick() {
    this.fileInput.nativeElement.click();
  }
  removeFile(fileInput: HTMLInputElement){
    this.docuploaded = false;
    this.selectedFile = null;
    //remove file from File location
    fileInput.value = '';


  }
  download() {
    this.sopService.downloaduserstory(this.sddDocName).subscribe(
      (response: Blob) => {
        this.isLoading = false;
        // Create a new Blob with the correct MIME type for CSV
        const blob = new Blob([response], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        // Assuming process_name is a variable containing the process name
        const timestamp = new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
        const fileNameParts = this.fileData?.name.split('_');
        const processName = fileNameParts && fileNameParts.length >= 3 ? fileNameParts[1] : ''; // Assuming process name is the second part

        const filename = `Userstory_${processName}_${timestamp}.csv`;

        // Set the filename for the download
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        this.toastMessage.showSuccess('File Downloaded Successfully');
        // Cleanup
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        this.downloadbtn = false;
      }
    );
}

  // checkDoc() {
  //   // console.log('check doc called');
  //   this.sharedService.checkDocumentStatus(this.downloadLink2).subscribe({
  //     next: (res: { status: any; status_code: any; result: { is_document_available: boolean; }; message: any; }) => {
  //       // console.log('res cond met->',res);
  //       if (res.status && res.status_code == StatusCode.Success) {
  //         this.isDocAvailable = res.result.is_document_available;
  //         if (this.isDocAvailable) {
  //           // console.log('res cond met true->');
  //           // this.docAvailable.unsubscribe();
  //           this.openSDDSuccessDialog();
  //           this.showGoToGenerateCode = true;
  //          this.isLoading = false;
  //         }
  //       } else {
  //         this.isDocAvailable = true;
  //         console.log('res cond met error->');
  //         // this.docAvailable.unsubscribe();
  //         this.isDocAvailableHasError = true;
  //        this.isLoading = false;
  //         this.sharedService.openSnackBar(res.message, MessageTypeSnack.Error);
  //       }
  //     },
  //     error: (err: any) => {
  //       console.log('res cond met error->');
  //       // this.docAvailable.unsubscribe();
  //       this.isDocAvailable = true;
  //       this.isDocAvailableHasError = true;
  //      this.isLoading = false;
  //       this.sharedService.openSnackBar(err, MessageTypeSnack.Error);
  //     },
  //   });
  //   //return response && response.someProperty === 'someValue';
  // }



  // activityLogging() {
  //   this.activityAudit.module = ApiEndpoints.GetSDDCustom;
  //   this.activityAudit.time_stamp = String(new Date());
  //   let userName = localStorage.getItem('okta-username');
  //   this.activityAudit.ohr_id = userName ? String(userName) : '';
  //   //this.sharedService.postActivityLog(this.activityAudit);
  //   this.sharedService.postActivityLog(this.activityAudit).subscribe({
  //     next: (res) => {
  //       if (res.status && res.status_code == StatusCode.Success) {

  //       } else {
  //         if (res.status_code == 500) {

  //         } else if (res.status_code == 400) {

  //         } else {

  //         }
  //       }
  //     },
  //     error:  => {
  //     },
  //   });
  // }



  // onFileSelected(event: any) {
  //   this.selectedFile = event.target.files[0];
  //   const files: File[] = event.target.files;
  //   this.selFiles = [];
  //   let name = 'file';
  //   if (files) {
  //     let selLength = this.selFiles.length;
  //     for (let i = 0; i < files.length; i++) {
  //       let newName = name + selLength++;
  //       let fileData: FileModel = { name: newName, file_data: files[i] };
  //       this.selFiles.push(fileData);
  //     }

  //   }
  //   else {
  //     this.sharedService.openSnackBar("No file chosen.", MessageTypeSnack.Error);
  //   }
  // }



  // new onFileSelected event with validation fire here ......................

  // onFileSelected(event: any) {
  //   const file = event.target.files[0];

  //   if (!file) {
  //     this.selectedFile = null; // Clear selectedFile if no file is chosen
  //     this.selFiles = [];
  //     this.sharedService.openSnackBar("No file chosen.", MessageTypeSnack.Error);
  //     return;
  //   }

  //   const fileSize = file.size / 1024 / 1024; // Convert bytes to MB
  //   const fileType = file.name.split('.').pop();

  //   if (fileSize > 1) {
  //     this.invalidSize = true;
  //     this.invalidType = false;
  //     return;
  //   }

  //   if (!['doc', 'docx'].includes(fileType)) {
  //     this.invalidSize = false;
  //     this.invalidType = true;
  //     return;
  //   }

  //   // If a valid file is selected, reset error flags and proceed
  //   this.invalidSize = false;
  //   this.invalidType = false;
  //   this.selectedFile = file;
  //   this.selFiles = []; // Clear any previous selected files

  //   let name = 'file';
  //   for (let i = 0; i < event.target.files.length; i++) {
  //     let newName = name + i;
  //     let fileData: FileModel = { name: newName, file_data: event.target.files[i] };
  //     this.selFiles.push(fileData);
  //   }
  // }


  // openSDDSuccessDialog() {
  //   const dialogRef = this.dialog.open(CompletionpopupComponent, {
  //     width: '440px', // Set the width of the modal
  //     data: {
  //       fileUrl: this.downloadLink,
  //       fileName: this.docName,
  //     },
  //   });

  //   dialogRef.afterClosed().subscribe((confirmed: boolean) => {
  //     if (confirmed) {
  //       // Switch to SDD tab after the dialog is closed
  //       this.goToGenerateCode();
  //     }
  //   });

    //dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    //  if (confirmed) {

    //    //const link = document.createElement('a');
    //    //link.href = this.brdDownloadLink;
    //    //link.download = this.brdDocName;
    //    //document.body.appendChild(link);
    //    //link.click();
    //    //document.body.removeChild(link);
    //  }

    //}
    //});
  // }

  // removeSelectedFile() {
  //   this.selectedFile = null;
  //   this.selFiles = [];
  //   // this.invalidSize = false;
  //   // this.invalidType = false;

  //   // Reset the file input field
  //   const fileInput = document.getElementById('fileInput') as HTMLInputElement;
  //   if (fileInput) {
  //     fileInput.value = '';
  //   }
  // }


  // refreshSelectedFile(){
  //   this.removeSelectedFile()

  // }

  // function to navigate to generate code tab ----------
  goToGenerateCode() {
    this.switchTab.emit(2); // Emit the event to notify the parent component to switch to the SDD tab
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];

    if (!file) {
      this.selectedFile = null; // Clear selectedFile if no file is chosen
      this.selFiles = [];
      this.toastMessage.showError("No file chosen.");
      return;
    }

    const fileSize = file.size / 1024 / 1024; // Convert bytes to MB
    const fileType = file.name.split('.').pop();

    if (fileSize > 1) {
      this.invalidSize = true;
      this.invalidType = false;
      return;
    }

    if (!['doc', 'docx'].includes(fileType)) {
      this.invalidSize = false;
      this.invalidType = true;
      return;
    }

    // If a valid file is selected, reset error flags and proceed
    this.invalidSize = false;
    this.invalidType = false;
    this.selectedFile = file;
    this.selFiles[0] = event.target.files[0]; // Clear any previous selected files
  }

  }

