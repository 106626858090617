import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingsComponent } from '../popup/settings/settings.component';
import { MessageTypeSnack, StatusCode } from '../../../data/global-vars';
import { SharedService } from '../../../Services/shared.service';
import { Router } from '@angular/router';
import { RoundLoaderService } from '../../../Services/roundloader.service';
import { ContactusComponent } from '../popup/contactus/contactus.component';
import { DisclaimerComponent } from '../popup/disclaimer/disclaimer.component';



import { ActivatedRoute} from '@angular/router';

import { Subscription } from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: false
})
export class HeaderComponent implements OnInit, OnChanges {

  username: any;
  isPageVisited: boolean = true;
  title:boolean = true;
  title1:boolean = false;
  domain:any;
  constructor(public dialog: MatDialog, private router: Router,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private loaderService: RoundLoaderService) { }
    
    ngOnInit(): void {
      this.getUserName();
      this.domain = localStorage.getItem('domain'); 
      this.route.params.subscribe(params => {
        const currentRoute = this.router.url;

        if (currentRoute === '/codeGenYLite') {
          this.title = true;
          this.title1 = true;
        } else if (currentRoute === '/codeGenYLiteAI') {
          this.title = true;
          this.title1 = false;
        }
        else {
          this.title = false;
        }

        // Add more else if conditions for other routes
      });
      // this.getSubscriptionStatus();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isSubscribed']) {
      // this.getSubscriptionStatus();
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DisclaimerComponent);
}

opencontactus(){
  this.dialog.open(ContactusComponent, {
    width: '500px', // Set the width of the modal
  });
}

  getUserName() {
    this.username = localStorage.getItem('okta-username') ? localStorage.getItem('okta-username') as string : 'Guest';
  }

  openSetting(){
     this.dialog.open(SettingsComponent, {
      width: '800px', // Set the width of the modal
    });
  }

  productPage()
  {
    this.router.navigate(['codeGenYLite/products']);
    localStorage.setItem('tab', '0');
  }

  exitApp() {//closeApplication
    localStorage.removeItem('okta-username');
    this.router.navigate(['logout']);
  }

  // open subscription popup ---------------------

  // openSubscriptionPopup() {
  //   if (!this.subsService.isAuthenticated) {
  //     const dialogRef = this.dialog.open(SubscriptionComponent, {
  //       width: '500px'
  //     })
  //     dialogRef.afterClosed().subscribe((data: any) => {
  //       if (data) {
  //         this.getSubscriptionStatus();
  //       }
  //     });
  //   }


  // }

  goToHome() {

    this.router.navigate(['codeGenYLite']);


  }
  // scrollToSection(): void {
  //   console.log('Scrolling to section...');
  //   this.router.navigate(['/']);
  //   const element = document.getElementById('features-section');
  // console.log('Element:', element);

  //   this.loaderService.scrollToElement('features-section'); // Replace with the actual ID of your section
  // }

  getDomain(): string | null {
    return localStorage.getItem('domain');
  }

  isHomePage(): boolean {
    return this.router.url === '/codeGenYLite';
  }

  scrollToSection(section: string) {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  navigateTo(section: string) {
    this.router.navigate(['/codeGenYLite']).then(() => {
      setTimeout(()=>{
        this.scrollToSection(section);
      }, 300);
    });
  }
}
