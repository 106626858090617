import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-cmain',
  standalone: false,
  
  
  templateUrl: './cmain.component.html',
  styleUrl: './cmain.component.scss'
})
export class CmainComponent {
  @Input() selectedTab: { label: string; value: string } | null = null;
}
