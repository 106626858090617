<div class="disclaimer">
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title pull-left" class="heading">Privacy Policy</h4>
        </div>
        <div class="highlight-line">Before continuing, please review the Use of Generative AI Policy. Compliance with
            the Policy is required.
        </div>
        <div class="conditions">
            <ul class="Conditionlist">
                <li class="list-item">AI isn’t perfect. While efforts have been made to provide accurate and relevant
                    information, there is the possibility of errors and inaccuracies. The response you receive should
                    not be considered professional or expert advice. You are required to check and verify the
                    information with reliable sources or consult a human expert as necessary and appropriate.</li>
                <li>Your prompts and use of this tool may be subject to review or audit for compliance with Genpact’s
                    policies and/or for refining the technology.</li>
                <li>No Client or Genpact names, references, IP addresses, usernames, access key, credentials, or any
                    other confidential or sensitive information may be shared or made available on any public or
                    third-party website or tool. </li>
                <li>No sensitive personal information (“SPI”) and/or personally identifiable information (“PII”) should
                    be processed by this tool. Please ensure any documents or materials submitted to this tool do not
                    contain any SPI or PII and also validate that no output from the toll contain any SPI or PII. </li>
            </ul>
        </div>
        <div class="declarations">
            <ul class="list">
                <li class="list-item">By entering input and using this tool, you acknowledge and agree you have read the
                    information above, understand it, and agree to comply with the Use of Generative AI Programs Polices
                    and other applicable Genpact policies.
                </li>
                <li class="list-item"><em><u> COMPLIANCE MANDATORY – IMPORTANT INSTRUCTIONS FOR ALL USERS.</u></em>
                    It is critical that all users protect Company intellectual property rights and the rights of others.
                    Before using any code generated by this tool, you MUST (mandatory obligation) verify the generated
                    code for intellectual property right infringements, including copyright violations in accordance
                    with the <a href="../../../../../../assets/sop.pdf" target="_blank" style="color: #FFFAF4;">SOPs</a>. It is all user’s
                    responsibility to ensure strict adherence to the SOPs and all Genpact Policies.
                </li>
            </ul>
        </div>
        <!-- <div>content</div> -->


        <div class="modal-footer d-flex" style="justify-content: space-between;">

            <div class="row gx-2 gy-2" class="d-flex">
                <input (change)="statuscheck()" type="checkbox" id="myCheckbox" name="myCheckbox" [(ngModel)]="checkstatus">
                <label style="    font-size: 15px !important;
                padding: 5px;
                margin-top: 8px;" for="myCheckbox">I Acknowledge and Agree to the above terms and conditions</label>
                  </div>

            <button class="btn btn-primary" [disabled]="!checkstatus" (click)="onCloseClick()" style="background-color: #51ACC5;">Submit</button>

            <!-- <button type="button" class="close" (click)="tryCloseModal()" [disabled]="!checkboxAccepted">&#215;</button> -->
        </div>
    </div>