import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { CodeDocumentationService } from '../../../Services/code-documentation.service';
import { CodeDocumentation } from '../../../data/code-docs-model';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivityAudit, FileModel } from '../../../data/app-model';
import {
    ApiEndpoints,
  MessageTypeSnack,
  StatusCode,
} from '../../../data/global-vars';
import { SharedService } from '../../../Services/shared.service';
import { Editor, Toolbar } from 'ngx-editor';
import { environment } from '../../../../../environments/environment';
import { marked } from 'marked';
import { Subscription, finalize, interval, takeUntil, takeWhile } from 'rxjs';
import { RoundLoaderService } from 'src/app/codeGenYLite/Services/roundloader.service';
import { SopService } from 'src/app/shared/service/sop.service';
import { MatDialog } from '@angular/material/dialog';
import { TimeouterrorComponent } from '../../includes/popup/timeouterror/timeouterror.component';

@Component({
    selector: 'app-code-documentation',
    templateUrl: './code-documentation.component.html',
    styleUrls: ['./code-documentation.component.scss'],
    standalone: false
})
export class CodeDocumentationComponent implements OnInit, OnDestroy {
  @ViewChild('enhancedWindow') enhancedWindow!: ElementRef;

      // validation error variables ------------
      isCodeLengthInvalid: boolean = false;
      isFileEmpty: boolean = false;
      isFileSizeExceeded: boolean = false;
      isInvalidFileType: boolean = false;
      docAvailable!: Subscription;
      isDocAvailable: boolean = false;
      isDocAvailableHasError: boolean = false;
      downloadLink2: string = '';
  tooltipText = 'Click to copy';
  activityAudit: ActivityAudit = {
    ohr_id: '',
    time_stamp: '',
    module: '',
  }
  unmarkedContent: any;
  isCodeDocumentated : boolean = false;
  constructor(
    private codeDocService: CodeDocumentationService,
    private sanitizer: DomSanitizer,
    private sharedService: SharedService,
    private loaderService: RoundLoaderService,
    private sopService: SopService,
    private dialog : MatDialog,
  ) {}

  codeMirrorOptions: any = {
    lint: true,
    //theme: 'material'
  };
  
  codeMirrorOptionsreadonly: any = {
    indentWithTabs: true,
    smartIndent: true,
    readOnly : false,
    //lineNumbers: true,
    lineWrapping: true,
    extraKeys: { "Ctrl-Space": "autocomplete" },
    gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true,
    //theme: 'material'
  };


  editor: any;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  selFiles: any;
  isCodeInputError: boolean = false;
  fileUrl: any;
  codeDocs: CodeDocumentation = {
    code_document: '',
    code: '',
    code_document_file: '',
  };

  ngOnInit(): void {
    //throw new Error('Method not implemented.');
    this.editor = new Editor();
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  //textAreaValue:string = '';
  isCodeEnhanced = false;

  onUploadButtonClick() {
  }

  onFileSelected(event: any) {
    const files: File[] = event.target.files;

    // Reset error flags
    this.isFileEmpty = false;
    this.isFileSizeExceeded = false;
    this.isInvalidFileType = false;

    if (!files || files.length === 0) {
      this.isFileEmpty = true;
      return;
    }

    const selectedFile = files[0];

    // Validate file size
    const maxSize = 1 * 1024 * 1024; // 1MB in bytes
    if (selectedFile.size > maxSize) {
      this.isFileSizeExceeded = true;
      return;
    }

    // Validate file type
    const allowedFileTypes = [".py", ".txt", ".vb", ".vba", ".doc", ".docx"];
    const fileType = selectedFile.name.substr(selectedFile.name.lastIndexOf(".")).toLowerCase();
    if (!allowedFileTypes.includes(fileType)) {
      this.isInvalidFileType = true;
      return;
    }

    // Read file content
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const content = fileReader.result?.toString();
      this.codeDocs.code = content as string;
    };

    fileReader.readAsText(selectedFile);
  }

  // onFileSelected(event: any) {
  //   const files: File[] = event.target.files;
  //   this.selFiles = [];
  //   let name = 'file';
  //   if (files) {
  //     let selLength = this.selFiles.length;
  //     for (let i = 0; i < files.length; i++) {
  //       let newName = name + selLength++;
  //       let fileData: FileModel = { name: newName, file_data: files[i] };
  //       this.selFiles.push(fileData);
  //     }

  //     let fileReader = new FileReader();
  //     fileReader.onload = (e) => {
  //       //console.log(fileReader.result);
  //       let content = fileReader.result?.toString();
  //       this.codeDocs.code = content as string;
  //     };
  //     fileReader.readAsText(files[0]);
  //   }

    //this.codeDocService.uploadFile(this.codeDocs, this.selFiles).subscribe({
    //  next: (res) => {
    //    debugger;
    //    if (res.status && res.status_code == StatusCode.Success) {
    //      this.codeDocs.code = res.result.file_txt;
    //    } else {
    //      if (res.statusCode == 500) {
    //        //
    //      } else if (res.status_code == 400) {
    //        //
    //      } else {
    //        //
    //      }
    //    }
    //  },
    //  error: (err) => {
    //    /*this.sharedService.openSnackBar(err, MessageTypeSnack.Error);*/
    //  },
    //});
  // }

onButtonClick() {
  this.isCodeDocumentated = true;
  this.storeLogs();
    this.isCodeInputError = false;
    this.codeDocs.code_document = '';
    if (this.codeDocs.code != null && this.codeDocs.code.trim() != '') {

                // Validate code length
    const minLength = 25;
    const maxLength = 40000;
    const codeLength = this.codeDocs.code.trim().length;

    if (codeLength < minLength || codeLength > maxLength) {
      this.isCodeLengthInvalid = true;
      return;
    }
      this.codeDocService.enhanceCode(this.codeDocs.code).subscribe({
        next: (res) => {
          console.log('checking response here->',res);
          if (res.status && res.status_code == StatusCode.Success) {
            this.downloadLink2 = res.result.code_document;
            this.isDocAvailable = false;
            this.docAvailable = interval(environment.reqPoolingInterval)
            .pipe(
              takeWhile(() => !this.conditionMetO()),
              takeUntil(interval(environment.reqPoolingTimeout)),
              finalize(() => this.considerComplete())
          )
            .subscribe(() => this.checkDoc());


          } else {
            if (res.status_code == 500) {
              this.loaderService.hideLoader();
              this.sharedService.openSnackBar(
                res.message,
                MessageTypeSnack.Error
              );
            } else if (res.status_code == 400) {
              this.loaderService.hideLoader();
              this.sharedService.openSnackBar(
                res.message,
                MessageTypeSnack.Error
              );
            } else {
              this.loaderService.hideLoader();
              this.sharedService.openSnackBar(
                res.message,
                MessageTypeSnack.Error
              );
            }
          }
          this.activityLogging();
        },
        error: (err) => {
          this.loaderService.hideLoader();
          this.sharedService.openSnackBar(err, MessageTypeSnack.Error);
          this.activityLogging();
        },
      });


    } else {
      this.isCodeInputError = true;
    }
  }

  checkDoc() {
    // console.log('check doc called', this.downloadLink2);
    this.sharedService.checkDocumentStatus(this.downloadLink2).subscribe({
      next: (res) => {
        // console.log('res cond met->',res);
        if (res.status && res.status_code == StatusCode.Success) {
          this.isDocAvailable = res.result.is_document_available;
          if (this.isDocAvailable) {
            // console.log('res cond met true->');
            // this.docAvailable.unsubscribe();
            this.unmarkedContent = res.result.data_response.code_document;
            const markedcontent = marked.parse(this.unmarkedContent);
            const finalContent = String(markedcontent);
            this.codeDocs.code_document = finalContent;
            let filename = res.result.data_response.code_document_file;
            if (this.downloadLink2.toLowerCase().includes('devflow')) {
              this.codeDocs.code_document_file =
              environment.apiURL+ '/konv' + '/temp/' + this.downloadLink2;
            }
            else {
              this.codeDocs.code_document_file = 
              environment.apiURL+ '/konv' + '/static/' + this.downloadLink2;
            }

            this.isCodeEnhanced = true;
            // Scroll to the bottom after setting isGenerateCode to true
            this.scrollToBottom(this.enhancedWindow);
            this.loaderService.hideLoader();

          }
        } else {
          this.isDocAvailable = true;
          console.log('res cond met error->');
          // this.docAvailable.unsubscribe();
          this.isDocAvailableHasError = true;
          this.loaderService.hideLoader();
          this.sharedService.openSnackBar(res.message, MessageTypeSnack.Error);


        }
      },
      error: (err) => {
        console.log('res cond met error->');
        // this.docAvailable.unsubscribe();
        this.isDocAvailable = true;
        this.isDocAvailableHasError = true;
        this.loaderService.hideLoader();
        this.sharedService.openSnackBar(err, MessageTypeSnack.Error);
      },
    });
    //return response && response.someProperty === 'someValue';
  }

  conditionMet(response: any): boolean {
    // Check if the response meets your condition
    return response && response.result.is_document_available;
  }

  conditionMetO(): boolean {
    console.log('condition met called');
    return this.isDocAvailable;
  }

  considerComplete() {
    console.log('consider complete');
    this.loaderService.hideLoader();
    if (!this.isDocAvailable) {
      // this.sharedService.openSnackBar("Something went wrong. Please try again.", MessageTypeSnack.Error);
      this.dialog.open(TimeouterrorComponent, {
        width: '500px'
      })

    }

  }

  storeLogs(){
    const payload = {
      'event_type' : 'codeGenYLite',
      'event_data' : {
        'feature':'code_documentation',
      } 
    }
    this.sopService.logsStorage(payload).subscribe((res)=>{
      // console.log(res);
    })
  }

  activityLogging() {
    this.activityAudit.module = ApiEndpoints.GetDocumentationCustom;
    this.activityAudit.time_stamp = String(new Date());
    let userName = localStorage.getItem('okta-username');
    this.activityAudit.ohr_id = userName ? String(userName) : '';
    //this.sharedService.postActivityLog(this.activityAudit);
    this.sharedService.postActivityLog(this.activityAudit).subscribe({
      next: (res) => {
        if (res.status && res.status_code == StatusCode.Success) {

        } else {
          if (res.status_code == 500) {

          } else if (res.status_code == 400) {

          } else {

          }
        }
      },
      error: (err) => {

      },
    });
  }

  bindValue(textAreaVal: string) {
    this.codeDocs.code = textAreaVal;
  }

  copyText(): void {
    navigator.clipboard
      .writeText(this.unmarkedContent)
      .then(() => {
        // Change tooltip text after successfully copying
        this.tooltipText = 'Copied!';
      })
      .catch(() => {
        this.sharedService.openSnackBar(
          'Unable to copy text',
          MessageTypeSnack.Error
        );
      });
  }

  // downloadFile(): void {
    
  //   let data = this.codeDocs.code_document;
  //   ////let encodedData = btoa(data)
  //   ////var json = atob(encodedData);
  //   ////const blob = this.base64toBlob(json);
  //   const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
  //   const url = window.URL.createObjectURL(blob);
  //   const link = document.createElement('a');
  //   // this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  //   link.href = url;
  //   link.download = this.codeDocs.code_document_file;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  //   window.URL.revokeObjectURL(url);
  //   //this.codeDocService.downloadDocument().subscribe({
  //   //    next: (res) => {
  //   //      debugger;
  //   //      if (res.status && res.status_code == StatusCode.Success) {
  //   //        this.codeDocs.file_path = res.result.file_path;
  //   //        this.codeDocs.filename = res.result.filename;
  //   //        const link = document.createElement('a');
  //   //        link.href = this.codeDocs.file_path;
  //   //        document.body.appendChild(link);
  //   //        link.click();
  //   //        document.body.removeChild(link);
  //   //      } else {
  //   //        if (res.status_code == 500) {
  //   //          this.sharedService.openSnackBar(res.message, MessageTypeSnack.Error);
  //   //        } else if (res.status_code == 400) {
  //   //          this.sharedService.openSnackBar(res.message, MessageTypeSnack.Error);
  //   //        } else {
  //   //          this.sharedService.openSnackBar(res.message, MessageTypeSnack.Error);
  //   //        }
  //   //      }
  //   //    },
  //   //    error: (err) => {
  //   //      this.sharedService.openSnackBar(err, MessageTypeSnack.Error);
  //   //    },
  //   //  });
  // }



  // scroll to box code goes here.............
  private scrollToBottom(enhancedWindow: ElementRef): void {
    if (enhancedWindow && enhancedWindow.nativeElement) {
      const enhancedWindowElement = enhancedWindow.nativeElement;
      enhancedWindowElement.scrollTop = enhancedWindowElement.scrollHeight;
    }
  }

  setEditorContent(event: any) {
    // console.log(event, typeof event);
    //console.log(this.content);
  }

}
