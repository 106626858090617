<div class="code-box">
  <div class="custom-container">
    <p class="code-enhance-header">Generate code module achieves unparalleled speed in code creation based on SDD and automated step strategies. Streamline development cycles, minimizing errors, and cutting down on development costs.</p>
    <div class="header-links">
      <a class="privacypopup" (click)="openDialog()">Privacy Policy</a>
      <span class="code-enhance-header">Generated Code Plagiarism Checker Tool: <a class="goToPlagCheck" href="https://www.duplichecker.com/" target="_blank"> Duplichecker Tool</a></span>
    </div>
    <p class="code-enhance-desc"></p>
      <div class="form-box">
            <form [formGroup]="genCodeForm" (ngSubmit)="onButtonSubmit()">
                <div class="row gx-2 gy-2">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleFormControlSelect1" class="astreik">Preferred coding language:</label>
                        <mat-select class="form-control" formControlName="coding_lang" style="padding: 0.375rem 10px 0.375rem 2px;">
                          <mat-option value="Python">Python</mat-option>
                        <mat-option value="VBA">VBA</mat-option>
                      </mat-select>
                        <div class="text-danger"
                             *ngIf="genCodeForm.get('coding_lang')?.invalid && (genCodeForm.get('coding_lang')?.dirty || genCodeForm.get('coding_lang')?.touched)">
                          <small *ngIf="genCodeForm.get('coding_lang')?.hasError('required')">
                            Preferred coding language is required.
                          </small>
                        </div>
                      </div>
                    </div>
                </div>

                <div class="row gx-2 gy-2">
                  <div class="col-md-6">
                    <p class="upload-label" class="astreik">Upload BRD document:</p>
                    <div class="file-upload form-control">
                      <label for="fileInputBRD" class="dragUploadLabel">
                        <span class="upload-icon">
                          <img src="../../../../../assets/images/uploadBlack.png" alt="">
                        </span>
                        <span class="upload-text" >Click to upload file</span>
                      </label>
                      <input type="file" id="fileInputBRD" (change)="onBRDFileSelected($event)" accept=".docx" />
                      <input type="text" formControlName="brd_doc" class="form-control" style="display:none">
                    </div>
                    <div class="text-danger"
                         *ngIf="genCodeForm.get('brd_doc')?.invalid && (genCodeForm.get('brd_doc')?.dirty || genCodeForm.get('brd_doc')?.touched)">
                      <small *ngIf="genCodeForm.get('brd_doc')?.hasError('required')">
                        BRD document is required.
                      </small>
                      <small *ngIf="genCodeForm.get('brd_doc')?.hasError('fileSize')">
                        File size too large! Max size: 1MB.
                      </small>
                      <small *ngIf="genCodeForm.get('brd_doc')?.hasError('pattern')">
                        Invalid file type! Only .doc and .docx allowed.
                      </small>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <p class="upload-label" class="astreik">Upload SDD Document:</p>
                    <div class="file-upload form-control">
                      <label for="fileInputSDD" class="dragUploadLabel">
                        <span class="upload-icon">
                          <img src="../../../../../assets/images/uploadBlack.png" alt="">
                        </span>
                        <span class="upload-text">Click to upload file</span>
                      </label>
                      <input type="file" id="fileInputSDD" (change)="onSDDFileSelected($event)" accept=".docx" />
                      <input type="text" formControlName="sdd_doc" class="form-control" style="display:none">
                    </div>
                    <div class="text-danger"
                         *ngIf="genCodeForm.get('sdd_doc')?.invalid && (genCodeForm.get('sdd_doc')?.dirty || genCodeForm.get('sdd_doc')?.touched)">
                      <small *ngIf="genCodeForm.get('sdd_doc')?.hasError('required')">
                        SDD document is required.
                      </small>
                      <small *ngIf="genCodeForm.get('sdd_doc')?.hasError('fileSize')">
                        File size too large! Max size: 1MB.
                      </small>
                      <small *ngIf="genCodeForm.get('sdd_doc')?.hasError('pattern')">
                        Invalid file type! Only .doc and .docx allowed.
                      </small>
                    </div>
                  </div>
                </div>
                <div class="row gy-2 gx-2">
                    <p class="uploaded-header" *ngIf="selectedBRDFile || selectedSDDFile">Uploaded File</p>
                    <div class="col">
                        <div class="uploaded-file">
                            <div *ngIf="selectedBRDFile">
                                <div class="uploaded-file-section">
                                    <div class="upload-file-name">
                                        <img src="../../../../../assets/images/codeDocument.png" alt="">
                                    <span class="file-font">{{ selectedBRDFile.name }}</span>
                                    </div>
                                <span class="action-buttons">
                                    <img src="../../../../../assets/images/refresh.png" alt="" (click)="refreshBRDSelectedFile()">
                                    <img src="../../../../../assets/images/delete.png" alt="" (click)="removeBRDSelectedFile()">
                                  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row gy-2 gx-2">
                    <div class="col">
                        <div class="uploaded-file mt-3">
                            <div *ngIf="selectedSDDFile">
                                <div class="uploaded-file-section">
                                    <div class="upload-file-name">
                                        <img src="../../../../../assets/images/codeDocument.png" alt="">
                                    <span class="file-font">{{ selectedSDDFile.name }}</span>
                                    </div>
                                <span class="action-buttons">
                                    <img src="../../../../../assets/images/refresh.png" alt="" (click)="refreshSDDSelectedFile()">
                                    <img src="../../../../../assets/images/delete.png" alt="" (click)="removeSDDSelectedFile()">
                                  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="btn-submit">
                  <app-button [buttonClass]="'BRD-btn'" [buttonText]="'Generate code'"></app-button>
                  <span *ngIf="isGenerateCode" class="spanclass">Generated Code Plagiarism Checker Tool: <a class="goToPlagCheck" href="https://www.duplichecker.com/" target="_blank"> Duplichecker Tool</a></span>
                </div>
            </form>

            <div *ngIf="isGenerateCode">

                <!-- <div class="enhanced-code-box" #enhancedWindow>
                    <div class="enhanced-header">
                        <p>Generated code</p>
                        <div class="icons">

                          <img class="img-1" src="../../../../assets/images/copy.png" alt="" (click)="copyText()" [matTooltip]="tooltipText" [matTooltipPosition]="'above'">
                        <img class="img-2" src="../../../../assets/images/download.png" alt="" (click)="downloadFile()">


                        </div>
                    </div>
                    <div class="enhanced-content">

                      <ngx-codemirror [(ngModel)]="genCode.generated_code"
                                      [options]="codeMirrorOptions"
                                      (ngModelChange)="setEditorContent($event)"></ngx-codemirror>
                    </div>
                </div></div> -->

                <div *ngIf="isGenerateCode">
                  <!-- enhanced code box  -->
                  <div class="enhanced-code-box" #enhancedWindow>
                    <div class="enhanced-header">
                      <p>Generated code</p>
                      <div class="icons">

                        <img class="img-1" src="../../../../assets/images/copy.png" alt="" (click)="copyText()"
                          [matTooltip]="tooltipText" [matTooltipPosition]="'above'">
                        <img class="img-2" src="../../../../assets/images/download.png" alt="" (click)="downloadFile()">

                        <!-- <i class="fa-regular fa-copy" (click)="copyText()"></i>
                                            <i class="fa-solid fa-download" (click)="downloadFile()"></i> -->
                      </div>
                    </div>
                    <div class="enhanced-content">
                      <!--<pre>{{genCode.generated_code}}</pre>-->
                      <div class="message-content">
                        <div *ngFor="let fileBlock of file_blocks" class="message">
                          <div class="">
                            <strong>File: {{fileBlock.file_name}}{{fileBlock.ext}}</strong>
                          </div>

                          <div *ngIf="fileBlock.blocks.length > 0">
                            <div *ngFor="let block of fileBlock.blocks; let i = index">
                              <div *ngIf="block.type === 'text' && block.content && block.content!=''">
                                <pre class="precontent" [innerHTML]="block.content"></pre>
                                <br *ngIf="i !== fileBlock.blocks.length - 1" />
                              </div>
                              <div class="codebox">
                                <div *ngIf="block.type === 'code' && block.content && block.content!=''">
                                  <div class="enhanced-header">
                                    <p>{{block.lng}}</p>
                                    <div class="icons">
                                      <img class="img-1" src="../../../../assets/images/copy.png" alt=""
                                           [matTooltip]="tooltipText" [matTooltipPosition]="'above'" (click)="copyCodeContent(block.content)">
                                      <img class="img-2" src="../../../../assets/images/download.png" alt="" (click)="downloadCodeFile(block.content,fileBlock.ext,fileBlock.file_name,i)" [matTooltip]="'Download'" [matTooltipPosition]="'above'">
                                    </div>
                                  </div>
                                  <div class="code-content">
                                    <ngx-codemirror [(ngModel)]="block.content" [options]="codeMirrorOptions"></ngx-codemirror>
                                  </div>
                                  <br *ngIf="i !== fileBlock.blocks.length - 1" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>





                    <!--<div class="message-text">{{message.message}}</div>-->
                  </div>
                </div>
        </div>
    </div>
</div>
