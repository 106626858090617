import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from '../../../../Services/shared.service';
import { BuildProjectService } from '../../../../Services/build-project.service';
import { GenCode } from '../../../../data/gen-code-model';
import { ApiEndpoints, MessageTypeSnack, StatusCode } from '../../../../data/global-vars';
import { ActivityAudit } from '../../../../data/app-model';
import JSZip from 'jszip';
import { DisclaimerComponent } from '../../../includes/popup/disclaimer/disclaimer.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, finalize, interval, switchMap, takeUntil, takeWhile } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { RoundLoaderService } from 'src/app/codeGenYLite/Services/roundloader.service';
import { SopService } from 'src/app/shared/service/sop.service';
import { TimeouterrorComponent } from '../../../includes/popup/timeouterror/timeouterror.component';

@Component({
    selector: 'app-generate-code',
    templateUrl: './generate-code.component.html',
    styleUrls: ['./generate-code.component.scss'],
    standalone: false
})
export class GenerateCodeComponent implements OnInit {
  @ViewChild('enhancedWindow') enhancedWindow!: ElementRef;
  tooltipText = 'Click to copy';
  isPageVisited: boolean = true;
  selectedBRDFile: File | null = null;
  selectedSDDFile: File | null = null;
  isGenerateCode = false;
  downloadFilename: string = '';
  docAvailable!: Subscription;
  isDocAvailable: boolean = false;
  isDocAvailableHasError: boolean = false;
  downloadLink: string = '';
  downloadLink2: string = '';
  genCodeForm: FormGroup;
  genCode: GenCode = {
    coding_lang: '',
    sdd_doc: '',
    brd_doc: '',
    generated_code: '',
    file_extension: '',
  }

  activityAudit: ActivityAudit = {
    ohr_id: '',
    time_stamp: '',
    module: '',
  }
  isCodeGenerated : boolean = false;

  constructor(private formBuilder: FormBuilder, private buildPrjService: BuildProjectService,
     private sharedService: SharedService, public dialog: MatDialog, private loaderService: RoundLoaderService,
     private sopService: SopService) {
    this.genCodeForm = this.formBuilder.group({
      brd_doc: ['', [Validators.required, Validators.pattern('^.+\.(doc|docx)$'), this.fileSizeValidator(1 * 1024 * 1024)]],
      sdd_doc: ['', [Validators.required, Validators.pattern('^.+\.(doc|docx)$'), this.fileSizeValidator(1 * 1024 * 1024)]],
      coding_lang: ['VBA', [Validators.required]],
    })
  }

  fileSizeValidator(maxSize: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const file = control.value as File;
      if (file && file.size > maxSize) {
        return { fileSize: true };
      }
      return null;
    };
  }

  codeMirrorOptions: any = {
    //mode: "python",
    indentWithTabs: true,
    smartIndent: true,
    readOnly: true,
    //lineNumbers: true,
    lineWrapping: true,
    extraKeys: { "Ctrl-Space": "autocomplete" },
    gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true,
    //theme: 'material'
  };

  ngOnInit(): void {

  }

  chatMessages: any[] = [];
  generated_code: string = '';
  blocks: { type: string; content: string; lng: string }[] = [];
  file_blocks: { file_name: string; blocks: any; ext: string }[] = [];

  openDialog(): void {

    const dialogRef = this.dialog.open(DisclaimerComponent);
}

  onButtonSubmit() {
    this.isCodeGenerated = true;
    this.storeLogs();
    if (this.genCodeForm.valid) {
      let formData = this.genCodeForm.value;
      this.genCode.coding_lang = this.genCodeForm.get('coding_lang')?.value;
      this.buildPrjService.generateCode(this.genCode).subscribe({
        next: (res) => {
          //  console.log('generateCode response:', res);
          if (res.status && res.status_code == StatusCode.Success) {
            // console.log('from submit->',res);
            this.downloadLink2= res.result.file_extension;
            this.isDocAvailable = false;
            // debugger;
        this.docAvailable = interval(environment.reqPoolingInterval)
        .pipe(
          takeWhile(() => !this.conditionMetO()),
          takeUntil(interval(environment.reqPoolingTimeout)),
          finalize(() => this.considerComplete())
      )
          .subscribe(() => this.checkDoc());
          } else {
            this.loaderService.hideLoader();
            this.sharedService.openSnackBar(res.message, MessageTypeSnack.Error);
          }

          this.activityLogging();
        },
        error: (err) => {
          this.loaderService.hideLoader();
          this.sharedService.openSnackBar(err, MessageTypeSnack.Error);
          this.activityLogging();
        },
      });
    } else {
      this.sharedService.openSnackBar("Please upload both files", MessageTypeSnack.Error);
    }
  }

  checkDoc() {
    // console.log('check doc called', this.downloadLink2);
    this.sharedService.checkDocumentStatus(this.downloadLink2).subscribe({
      next: (res) => {
        // console.log('res cond met->',res);
        if (res.status && res.status_code == StatusCode.Success) {
          this.isDocAvailable = res.result.is_document_available;
          if (this.isDocAvailable) {
            // console.log('res cond met true->');
            // this.docAvailable.unsubscribe();
            // this.openSDDSuccessDialog();
            // this.showGoToGenerateCode = true;
            this.genCode.generated_code = res.result.data_response.generated_code;
            this.splitCodeAndText();
            this.genCode.file_extension = res.result.data_response.file_extension;
            this.downloadFilename = 'generated_code_response.txt';
            this.isGenerateCode = true;
            this.scrollToBottom(this.enhancedWindow);
            this.loaderService.hideLoader();

          }
        } else {
          this.isDocAvailable = true;
          console.log('res cond met error->');
          // this.docAvailable.unsubscribe();
          this.isDocAvailableHasError = true;
          this.loaderService.hideLoader();
          this.sharedService.openSnackBar(res.message, MessageTypeSnack.Error);


        }
      },
      error: (err) => {
        console.log('res cond met error->');
        // this.docAvailable.unsubscribe();
        this.isDocAvailable = true;
        this.isDocAvailableHasError = true;
        this.loaderService.hideLoader();
        this.sharedService.openSnackBar(err, MessageTypeSnack.Error);
      },
    });
    //return response && response.someProperty === 'someValue';
  }

  conditionMet(response: any): boolean {
    // Check if the response meets your condition
    return response && response.result.is_document_available;
  }

  conditionMetO(): boolean {
    console.log('condition met called');
    return this.isDocAvailable;
  }

  considerComplete() {
    console.log('consider complete');
    this.loaderService.hideLoader();
    if (!this.isDocAvailable) {
      // this.sharedService.openSnackBar("Something went wrong. Please try again.", MessageTypeSnack.Error);
       this.dialog.open(TimeouterrorComponent, {
        width: '500px'
      })

    }

  }

  storeLogs(){
    const payload = {
      'event_type' : 'codeGenYLite',
      'event_data' : {
        'feature':'code_generation',
      } 
    }
    this.sopService.logsStorage(payload).subscribe((res)=>{
      // console.log(res);
    })
  }

  activityLogging() {
    this.activityAudit.module = ApiEndpoints.GenerateCodeCustom;
    this.activityAudit.time_stamp = String(new Date());
    let userName = localStorage.getItem('okta-username');
    this.activityAudit.ohr_id = userName ? String(userName) : '';
    //this.sharedService.postActivityLog(this.activityAudit);
    this.sharedService.postActivityLog(this.activityAudit).subscribe({
      next: (res) => {
        if (res.status && res.status_code == StatusCode.Success) {

        } else {
          if (res.status_code == 500) {

          } else if (res.status_code == 400) {

          } else {

          }
        }
      },
      error: (err) => {

      },
    });
  }

  onBRDFileSelected(event: any) {
    if (event.target.files) {
      this.genCode.brd_doc = event.target.files[0];
      const brdDocControl = this.genCodeForm?.get('brd_doc');

      if (brdDocControl) {
        brdDocControl.setValue(this.genCode.brd_doc.name);
        brdDocControl.updateValueAndValidity();

        if (brdDocControl.valid) {
          this.selectedBRDFile = event.target.files[0];
        } else {
          brdDocControl.markAsTouched();
        }
      }
    }
  }

  onSDDFileSelected(event: any) {
    if (event.target.files) {
      this.genCode.sdd_doc = event.target.files[0];
      const sddDocControl = this.genCodeForm?.get('sdd_doc');

      if (sddDocControl) {
        sddDocControl.setValue(this.genCode.sdd_doc.name);
        sddDocControl.updateValueAndValidity();

        if (sddDocControl.valid) {
          this.selectedSDDFile = event.target.files[0];
        } else {
          sddDocControl.markAsTouched();
        }
      }
    }
  }

  removeBRDSelectedFile() {
    this.selectedBRDFile = null;
    this.genCode.brd_doc = null;
    this.genCodeForm?.get('brd_doc')?.setValue('');

    // Reset the BRd input field
    const fileInputBRD = document.getElementById('fileInputBRD') as HTMLInputElement;
    if(fileInputBRD){
      fileInputBRD.value = '';
    }
  }
  removeSDDSelectedFile() {
    this.selectedSDDFile = null;
    this.genCode.sdd_doc = null;
    this.genCodeForm?.get('sdd_doc')?.setValue('');

    // reset the sdd input field
    const fileInputSDD = document.getElementById('fileInputSDD') as HTMLInputElement;
    if(fileInputSDD){
      fileInputSDD.value = '';
    }
  }
  refreshBRDSelectedFile(){
    this.removeBRDSelectedFile();

  }
  refreshSDDSelectedFile(){
    this.removeSDDSelectedFile();
  }

  copyText(): void {
    let completeContent: string = '';
    for (const key in this.genCode.generated_code) {
      completeContent = completeContent.concat(this.genCode.generated_code[key].file_code);
    }
    //let data = completeContent;
    navigator.clipboard.writeText(completeContent).then(()=>{
      // Change tooltip text after successfully copying
      this.tooltipText = 'Copied!';
    }).catch(() => {
      this.sharedService.openSnackBar("Unable to copy text", MessageTypeSnack.Error);
    });
  }

  copyCodeContent(content:string): void {
    navigator.clipboard.writeText(content).then(() => {
      // Change tooltip text after successfully copying
      this.tooltipText = 'Copied!';
    }).catch(() => {
      this.sharedService.openSnackBar("Unable to copy text", MessageTypeSnack.Error);
    });
  }

  splitCodeAndText(): void {
    this.file_blocks = [];
    //this.blocks = [];

    for (const key in this.genCode.generated_code) {
      this.blocks = [];
      let textToSPlit = this.genCode.generated_code[key];
      // Split the response based on the triple-single-quote delimiter
      const parts = textToSPlit.file_code.split('```');
      // Start from index 1 because the first part is empty
      for (let i = 0; i < parts.length; i += 2) {
        let language = '';
        const textBlock = parts[i] ? parts[i].trim() : '';
        const processedContent = textBlock.replace(
          /\*\*(.*?)\*\*/g,
          '<strong>$1</strong>'
        );
        let codeBlock = parts[i + 1] ? parts[i + 1].trim() : '';
        if (codeBlock) {
          const indexOfNewLine: number = codeBlock.indexOf('\n');
          if (indexOfNewLine != 1) {
            language = codeBlock.substring(0, indexOfNewLine);
            codeBlock = codeBlock.substring(indexOfNewLine + 1);
          }
        }

        this.blocks.push({ type: 'text', content: processedContent, lng: '' });
        this.blocks.push({ type: 'code', content: codeBlock, lng: language });
      }
      if (parts && parts.length > 0) {
        //this.chatMessages.push({
        //  // type: 'bot',
        //  message: this.blocks,
        //});
        this.file_blocks.push({
          file_name: key,
          blocks: this.blocks,
          ext: textToSPlit.ext_name
        });

        console.log(this.file_blocks);
      }
        

    }

    
  }
  downloadFile(): void {
    let completeContent: string = '';
    for (const key in this.genCode.generated_code) {
      completeContent=completeContent.concat(this.genCode.generated_code[key].file_code);
    }
    let data = completeContent;

    //const blob = new Blob([data], { type: 'application/octet-stream' });


    //const url = window.URL.createObjectURL(blob);
    //const link = document.createElement('a');
    //link.href = url;
    //link.download = this.downloadFilename;
    //document.body.appendChild(link);
    //link.click();
    //document.body.removeChild(link);
    //window.URL.revokeObjectURL(url);
    let files: { name: string; content:string }[] = [];
    this.file_blocks.forEach(function (fileb) {
      let codeContent = fileb.blocks.filter((item: { type: string }) => item.type === 'code').map((item: { content: any }) => item.content).join('\n\n');
      //let codeContent = fileb.blocks.map((x: { type: any }) => x.type=='code');
      files.push({ name: fileb.file_name + fileb.ext, content: codeContent });
    });
    files.push({ name: this.downloadFilename, content: data });
    this.downloadFilesAsZip(files);
  }

  downloadCodeFile(content: string, fileExt: string, fileName: string, idx: number): void {
    let data = content;

    const blob = new Blob([data], { type: 'application/octet-stream' });

    //this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName + idx.toString() + fileExt;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);


  }

  downloadFilesAsZip(files: { name: string, content: string }[]) {
    const zip = new JSZip();

    files.forEach(file => {
      zip.file(file.name, file.content);
    });

    zip.generateAsync({ type: 'blob' }).then((content) => {
      const zipFile = new Blob([content], { type: 'application/zip' });
      const zipUrl = URL.createObjectURL(zipFile);

      const link = document.createElement('a');
      link.href = zipUrl;
      link.download = 'Generated_code.zip';
      document.body.appendChild(link);
      link.click();

      setTimeout(() => {
        document.body.removeChild(link);
        URL.revokeObjectURL(zipUrl);
      }, 100);
    });
  }

  // scroll to box code goes here.............
  private scrollToBottom(enhancedWindow: ElementRef): void {
    if (enhancedWindow && enhancedWindow.nativeElement) {
      const enhancedWindowElement = enhancedWindow.nativeElement;
      enhancedWindowElement.scrollTop = enhancedWindowElement.scrollHeight;
    }
  }

  setEditorContent(event: any) {
    // console.log(event, typeof event);
    //console.log(this.content);
  }

}
