import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenaiService {

  private endpoint = 'https://gapiw.playground-poc.viren.in/agents/';

  constructor(private http: HttpClient) { }

  getWelcome(): Observable<any> {
    return this.http.get(`${this.endpoint}welcome`)
      .pipe(catchError(this.handleError));
  }

  getChat(query: string, agentId: string, agentAliasId: string, isSupervisorModeEnabled: boolean): Observable<any> {
    const payload: any = { query };
    if (isSupervisorModeEnabled) {
      payload.agentId = agentId;
      payload.agentAliasId = agentAliasId;
    }

    return this.http.post(`${this.endpoint}research/search`, payload)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    console.error('An error occurred:', error.message);
    return throwError(() => new Error('An error occurred; please try again later.'));
  }
}
