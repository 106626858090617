import { Component } from '@angular/core';

@Component({
    selector: 'app-report-analysis',
    templateUrl: './report-analysis.component.html',
    styleUrls: ['./report-analysis.component.scss'],
    standalone: false
})
export class ReportAnalysisComponent {

  selectedSatisfaction!: string;
  satisfactionText!: string;

  constructor() { }

  submitFeedback() {
    // Implement your logic to handle form submission
    console.log('Feedback submitted');
    console.log('Selected Satisfaction:', this.selectedSatisfaction);
    console.log('Satisfaction Text:', this.satisfactionText);
    // Optionally, you can reset the form after submission
    // this.feedbackForm.reset();
  }

  selectSatisfaction(icon: string) {
    this.selectedSatisfaction = icon;
    // Set text representation based on the selected emoji
    switch (icon) {
      case '😡':
        this.satisfactionText = 'Very Dissatisfied';
        break;
      case '😕':
        this.satisfactionText = 'Dissatisfied';
        break;
      case '😐':
        this.satisfactionText = 'Neutral';
        break;
      case '😊':
        this.satisfactionText = 'Satisfied';
        break;
      case '😄':
        this.satisfactionText = 'Very Satisfied';
        break;
      default:
        this.satisfactionText = '';
    }
  }
}
